import find from 'lodash/find';

import Routes from '@/src/constants/routes';
import { isomorphicRedirect } from '@/src/hoc/securedPageFunctions';
import { RootState } from '@/src/reducer/rootState';
import { MaiiaNextPageContext } from '@/src/types/MaiiaNextContext';
import { isConnectPathname } from '@/src/utils';

export function redirectToAgendaCenter(
  state: RootState,
  {
    practitionerId,
    ctx,
  }: {
    practitionerId: string | undefined;
    ctx: MaiiaNextPageContext;
  },
) {
  const { pathname, query } = ctx;
  const agendaSettings = state.agendaSettings.item;

  if (
    agendaSettings &&
    !isConnectPathname(pathname) &&
    !agendaSettings.isAgenda
  ) {
    const alternativeAgendaSettings = find(state.agendaSettingsDefault.items, {
      practitionerId,
      isAgenda: true,
    });

    if (alternativeAgendaSettings) {
      isomorphicRedirect(
        {
          pathname,
          query: {
            ...query,
            centerId: alternativeAgendaSettings.centerId,
          },
        },
        ctx,
      );
    } else {
      isomorphicRedirect(Routes.CHAT, ctx);
    }
  }
}
