import React, { ElementType, forwardRef, Ref } from 'react';
import get from 'lodash/get';
import { makeStyles } from 'tss-react/mui';

import { useDatacyName } from '@/src/hooks/datacy';

type CProps<T extends ElementType> = {
  className?: string;
  component?: T;
  datacy?: string;
  ellipsis?: boolean;
  inheritColor?: boolean;
  children: React.ReactNode | React.ReactNodeArray;
  style?: Object;
} & React.ComponentProps<T>;

type SProps = {
  color?: string;
  variant?: string;
  gridkey?: string;
  multiline?: boolean;
  maxRows?: number;
  onMouseDown?: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => void;
};

export type Props<T extends ElementType> = CProps<T> & SProps;

const useStyles = makeStyles<{
  color?: string;
  variant?: string;
  gridkey?: string;
  multiline?: boolean;
  maxRows?: number;
  onMouseDown?: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => void;
}>()((theme, { color: colorParam, variant }) => {
  let typography = variant && theme.typography[variant];
  if (typeof typography !== 'object') typography = {};

  let color = colorParam || '';
  // if we forgot to specify the inner color property (main.light, .dark...)
  if (color.split('.').length < 2) {
    color += '.main'; // use main as default
  }
  return {
    typo: {
      margin: 0,
      whiteSpace: 'pre-line',
      ...typography,
      color: get(
        theme.palette,
        color,
        get(theme.typography, `${variant}.color`), // fallback to theme.typography[variant].color, which is always set
      ),
    },
    ellipsis: {
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
    },
    inheritColor: {
      color: 'inherit !important',
      '@media print': {
        color: 'inherit !important',
      },
    },
  };
});

const Typography = forwardRef(
  <T extends ElementType = 'p'>(props: Props<T>, ref: Ref<T>) => {
    const {
      className,
      component: Component = 'p',
      children,
      ellipsis = false,
      inheritColor = false,
      datacy,
      color,
      variant,
      ...rest
    } = props;
    const { classes, cx } = useStyles({ color, variant });
    const datacyName = useDatacyName(datacy);
    return (
      <Component
        className={cx(
          classes.typo,
          { [classes.ellipsis]: ellipsis },
          { [classes.inheritColor]: inheritColor },
          className,
        )}
        datacy={datacyName}
        {...rest}
        ref={ref}
      >
        {children}
      </Component>
    );
  },
) as <T extends ElementType>(
  props: Props<T> & React.RefAttributes<T>,
) => React.ReactElement;

export default Typography;
