import { generateInitialSelectors } from '@docavenue/core';
import { ProAgendaSettingsDTO } from '@maiia/model/generated/model/api-pro/api-pro';

import {
  GenericSelector,
  InitialGenericSelector,
} from '@/src/selector/selectorsType';

const initialSelectors: InitialGenericSelector<ProAgendaSettingsDTO> = generateInitialSelectors(
  'agendaSettingsDefault',
);

const selectors: GenericSelector<ProAgendaSettingsDTO> = {
  name: 'agendaSettingsDefault',
  ...initialSelectors,
};

export default selectors;
