import React from 'react';
import { Divider } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(() => ({
  divider: {
    margin: '12px 0 8px',
  },
}));

const LineDivider = () => {
  const { classes } = useStyles();

  return <Divider className={classes.divider} />;
};

export default LineDivider;
