import React from 'react';
import { makeStyles } from 'tss-react/mui';

import Image from '../../atoms/Image';
import Typography from '../../atoms/Typography';

import { useDatacyName } from '@/src/hooks/datacy';

const useStyles = makeStyles()((theme: any) => ({
  emptyStateFallbackRoot: {
    fontSize: theme.fontSize('lg'),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    '& > img': {
      maxWidth: '40vmin',
      maxHeight: '40vmin',
    },
  },
  blackAndWhite: {
    filter: 'grayscale(1)',
  },
  info: {
    textAlign: 'center',
  },
  title: {
    marginTop: theme.spacing(5),
    fontFamily: theme.typography.title.fontFamily,
    fontWeight: theme.typography.title.fontWeight,
  },
}));

type Props = {
  datacy?: string;
  image: string;
  title?: string;
  message: string;
  className?: string;
  grey?: boolean;
};

const EmptyStateFallback = (props: Props) => {
  const {
    datacy = 'empty_state_fallback',
    image,
    title,
    message,
    className,
    grey = false,
    ...rest
  } = props;
  const { classes, cx } = useStyles();
  const datacyName = useDatacyName(datacy);
  return (
    <div
      className={cx(classes.emptyStateFallbackRoot, className, {
        [classes.blackAndWhite]: grey,
      })}
      datacy={datacyName}
      {...rest}
    >
      <Image src={image} fallbackSrc="/static/images/docs.svg" />
      <div className={classes.info}>
        {title && (
          <Typography className={classes.title} variant="title">
            {title}
          </Typography>
        )}
        <span>{message}</span>
      </div>
    </div>
  );
};

export default EmptyStateFallback;
