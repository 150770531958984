import {
  Practitioner,
  ProfessionalHcd,
  User,
  UserChatRoomDTO,
} from '@maiia/model/generated/model/api-pro/api-pro';

export const getInitials = (firstName?: string, lastName?: string) => {
  if (!firstName || !lastName) {
    return '';
  }
  return `${firstName?.trimStart()?.charAt(0)}${lastName
    ?.trimStart()
    ?.charAt(0)}`.toUpperCase();
};

export const getUserInitials = (
  user?: User | Practitioner | ProfessionalHcd | UserChatRoomDTO,
) => {
  if (!user) return '';
  const userProInformation = (user as User).userProInformation || {};
  const firstName =
    userProInformation.firstName?.trimStart() ||
    (user as Practitioner).firstName?.trimStart();
  const lastName =
    userProInformation.lastName?.trimStart() ||
    (user as Practitioner).lastName?.trimStart();

  return getInitials(firstName, lastName);
};

const avatarColors = [
  '#fde2f2',
  '#e1f9e9',
  '#e2f4fd',
  '#e2e7fd',
  '#fde2e2',
  '#fbe5cb',
];
const avatarTextColors = [
  '#7c1b55',
  '#00581e',
  '#1b5c7c',
  '#1b2b7c',
  '#8e0606',
  '#8e4c00',
];

const extractColorNumberFromId = (id?: string) => {
  const digitsInUserId = id?.match(/[0-9.]+/g)?.join('') ?? '10';
  return Math.floor(+digitsInUserId.slice(digitsInUserId.length - 2) / 10);
};

const getAvatarBackgroundColorById = (id?: string) => {
  const colorNumber = extractColorNumberFromId(id);
  return avatarColors[colorNumber] || avatarColors[0];
};

const getAvatarTextColorById = (id?: string) => {
  const colorNumber = extractColorNumberFromId(id);
  return avatarTextColors[colorNumber] || avatarTextColors[0];
};

type AvatarColors = {
  backgroundColor: string;
  textColor: string;
};

export function getAvatarColors(userId?: string): AvatarColors;
export function getAvatarColors(
  user?: User | Practitioner | ProfessionalHcd | UserChatRoomDTO,
): AvatarColors;
export function getAvatarColors(user: unknown): AvatarColors {
  const userId =
    typeof user === 'string'
      ? user
      : (user as User | Practitioner | UserChatRoomDTO)?.id ||
        (user as any)?.cardHcdId; // TODO TYPES may be a ProfessionalHcd ?

  return {
    backgroundColor: getAvatarBackgroundColorById(userId),
    textColor: getAvatarTextColorById(userId),
  };
}
