/* eslint-disable import/no-cycle */
/* eslint-disable no-param-reassign */
import find from 'lodash/find';
import uniq from 'lodash/uniq';

import {
  ACKNOWLEDGE,
  CREATE,
  CREATE_LOADING,
  CREATE_SUCCESS,
  CRUD_FAILURE,
  DELETE_SUCCESS,
  GET_LIST_SUCCESS,
  GET_ONE_SUCCESS,
  UPDATE,
  UPDATE_SUCCESS,
  UPLOAD,
  UPLOAD_LOADING,
  UPLOAD_MANY,
  UPLOAD_MANY_SUCCESS,
  UPLOAD_SUCCESS,
} from '@docavenue/core';
import { TLEPractitionerDTO } from '@maiia/model/generated/model/api-pro/api-pro';

import {
  ACKNOWLEDGE_SUCCESS,
  CLEAR_OLD_CHATROOM,
  DECREMENT_REPLY_NUMBER,
  GET_FIRST_UNREAD_MESSAGE_SUCCESS,
  GET_LATEST_SUCCESS,
  GET_UNREAD_COUNT_SUCCESS,
  INCREMENT_REPLY_NUMBER,
  RESET_MESSAGES,
  SAVE_EDIT_DRAFT,
  SET_CHAT_ROOM_INCOMING_CALL,
  SET_CHATMESSAGE_REDIRECTED_FROM_THREAD,
  SET_CURRENT_CHATROOM_ID,
  SET_IS_CHATROOM_VISIBLE,
  SET_IS_TOUCHING_BOTTOM,
  TOGGLE_DELETE,
  TOGGLE_EDIT,
  UNREAD_MESSAGE_SUCCESS,
  UPDATE_TELE_DATA_EXPERT,
} from '../actions';
import { ItemsDictMapAction } from '../actions/actionsTypes';
import {
  initialStateItemsDict,
  initIfEmptyForPrimaryKey,
  itemsDictMapReducerGenerator,
} from './itemsDictMap';
import { ItemsDictMapReducer, ItemsDictMapState } from './reducersTypes';

import { isMobile } from '@/src/utils/chat/chat.utils';
import {
  ChatMessage,
  ChatReplyMessage,
  ComputedChatMessage,
  Dictionary,
} from '@/types/chat.types';

const name = 'chatMessages';

type InitState = {
  currentChatRoomId: string;
  chatRoomIncomingCall: ChatMessage | null;
  latestUnreadMessage: ChatMessage[];
  totalUnreadMessagesCount: number;
  isTouchingBottom: boolean;
  isChatRoomVisible: boolean;
  chatMessageRedirectedFromThreadPage: string | null;
  query: Record<string, any>;
};

export type ChatMessageState = ItemsDictMapState<
  ChatMessage,
  InitState,
  InitProperties
>;

export const chatMessagesInitialState: ChatMessageState = {
  ...initialStateItemsDict,
  currentChatRoomId: null,
  chatRoomIncomingCall: null,
  latestUnreadMessage: [],
  totalUnreadMessagesCount: 0,
  isTouchingBottom: true,
  isChatRoomVisible: false,
  chatMessageRedirectedFromThreadPage: null,
} as any; // todo types provide defaults that satify the interface

const getPrimaryKey = ({
  chatRoomId,
}: {
  chatRoomId?: string;
}): string | undefined => chatRoomId;
const getSecondaryKey = ({
  creationDate,
}: {
  creationDate?: string;
}): string | undefined => {
  if (!creationDate) return;
  const date = new Date(creationDate);
  date.setHours(0, 0, 0, 0);
  return date.toISOString();
};

type InitProperties = {
  centerId: string | null;
  deleteMessage: ChatMessage | null;
  editMessage:
    | (ChatMessage | (ComputedChatMessage & { originalMessageContent: string }))
    | null;
  firstUnreadMessage: ChatMessage | null;
  latestChatMessage: ChatMessage | null;
  total: number;
  unreadMessagesCount: number;
  unreadThreadsCount: number;
};

const initialProperties: InitProperties = {
  centerId: null,
  deleteMessage: null,
  editMessage: null,
  firstUnreadMessage: null,
  latestChatMessage: null,
  total: 0,
  unreadMessagesCount: 0,
  unreadThreadsCount: 0,
};

const keysOptions = { getPrimaryKey, getSecondaryKey, initialProperties };

const surchargeReducer = (
  itemsDictMapReducerConfig: ItemsDictMapReducer<
    ChatMessage,
    InitState,
    InitProperties
  >,
) => {
  const initialCrudFailure = itemsDictMapReducerConfig[CRUD_FAILURE];
  const initialCrudCreateSuccess = itemsDictMapReducerConfig[CREATE_SUCCESS];
  const initialCrudGetOneSuccess = itemsDictMapReducerConfig[GET_ONE_SUCCESS];

  [GET_ONE_SUCCESS, `${GET_ONE_SUCCESS}/${name}`].forEach(actionType => {
    itemsDictMapReducerConfig[actionType] = (
      state: ItemsDictMapState<ChatMessage, InitState, InitProperties>,
      action: ItemsDictMapAction<ChatMessage>,
    ) => {
      const { currentChatRoomId, itemsDictMap } = state;
      const { payload } = action;
      const chatRoomId = getPrimaryKey(payload);
      const secondaryKey = getSecondaryKey(payload);
      initIfEmptyForPrimaryKey(itemsDictMap, initialProperties, chatRoomId);
      if (
        (currentChatRoomId && currentChatRoomId === chatRoomId) ||
        (chatRoomId &&
          secondaryKey &&
          itemsDictMap[chatRoomId].itemsDict[secondaryKey])
      ) {
        return initialCrudGetOneSuccess(state, action);
      }
      return state;
    };
  });

  [
    CREATE_LOADING,
    `${CREATE_LOADING}/${name}`,
    UPLOAD_LOADING,
    `${UPLOAD_LOADING}/${name}`,
  ].forEach(actionType => {
    itemsDictMapReducerConfig[actionType] = (
      state: ItemsDictMapState<ChatMessage, InitState, InitProperties>,
      action: ItemsDictMapAction<ChatMessage & { data: ChatMessage }>,
    ) => {
      const { itemsDictMap } = state;
      const {
        payload: { data: payloadData },
      } = action;
      const { centerId } = payloadData;

      const date = new Date().toISOString();
      const tempMessagePayload = {
        id: Math.random().toString(36),
        ...payloadData,
        creationDate: date,
        updateDate: date,
        isSending: true,
      };

      const primaryKey = getPrimaryKey(tempMessagePayload);
      const secondaryKey = getSecondaryKey(tempMessagePayload);

      if (!primaryKey || !secondaryKey) {
        return state;
      }

      if (!itemsDictMap[primaryKey]) {
        initIfEmptyForPrimaryKey(itemsDictMap, initialProperties, primaryKey);
      }
      if (!itemsDictMap[primaryKey].centerId)
        itemsDictMap[primaryKey].centerId =
          centerId || itemsDictMap[primaryKey].centerId;

      if (!itemsDictMap[primaryKey].itemsDict[secondaryKey]) {
        itemsDictMap[primaryKey].itemsDict[secondaryKey] = [];
      }

      itemsDictMap[primaryKey].itemsDict[secondaryKey] = [
        ...itemsDictMap[primaryKey].itemsDict[secondaryKey],
        tempMessagePayload,
      ];

      return {
        ...state,
        itemsDictMap: { ...itemsDictMap },
      };
    };
  });

  itemsDictMapReducerConfig[CRUD_FAILURE] = (
    state: ItemsDictMapState<
      ChatMessage & { isSending?: boolean },
      InitState,
      InitProperties
    >,
    action: ItemsDictMapAction<
      ChatMessage & {
        action: {
          type: string;
          payload: {
            id: string;
            params: Record<string, any>;
            data: Record<string, any>;
          };
        };
      }
    >,
  ) => {
    if (
      !action.payload?.action?.type ||
      ![CREATE, UPDATE, UPLOAD, UPLOAD_MANY].includes(
        action.payload?.action?.type,
      )
    ) {
      return initialCrudFailure(state, action);
    }

    const baseAction = {
      ...action.payload.action,
      query: { ...action.payload.action.payload.params },
      payload: {
        isError: true,
        users: [],
      },
    };
    const TYPES = {
      CREATE: CREATE_SUCCESS,
      UPLOAD: UPLOAD_SUCCESS,
      UPLOAD_MANY: UPLOAD_MANY_SUCCESS,
    };

    const newAction =
      action.payload.action.type === CREATE ||
      action.payload.action.type === UPLOAD ||
      action.payload.action.type === UPLOAD_MANY
        ? {
            ...baseAction,
            type: TYPES[action.payload.action.type],
            payload: {
              ...action.payload.action.payload.data,
              ...baseAction.payload,
              id: new Date().getTime(),
              creationDate: new Date().toISOString(),
              updateDate: new Date().toISOString(),
            },
          }
        : ({
            ...baseAction,
            type: UPDATE_SUCCESS,
            payload: {
              ...action.payload.action.payload.data,
              ...baseAction.payload,
              id: action.payload.action.payload.id,
              creationDate: action.payload.action.payload.params.creationDate,
              updateDate: action.payload.action.payload.params.creationDate,
            },
          } as any);

    if (newAction.payload.isError) {
      const { itemsDictMap } = state;

      const primaryKey = getPrimaryKey(newAction.payload) as string;

      // Looping over all the messages as there's NO secondary key
      // and removing that has the isSending
      for (const [, value] of Object.entries(
        itemsDictMap[primaryKey].itemsDict,
      )) {
        const messageIndex = value.findIndex(message => message.isSending);

        if (messageIndex > -1) {
          value.splice(messageIndex, 1);
        }
      }
    }
    return initialCrudCreateSuccess(state, newAction);
  };

  [GET_LIST_SUCCESS, `${GET_LIST_SUCCESS}/${name}`].forEach(actionType => {
    itemsDictMapReducerConfig[actionType] = (
      state: ItemsDictMapState<ChatMessage, InitState, InitProperties>,
      action: ItemsDictMapAction<{
        items: ChatMessage[];
        total: number;
      }> & { clearItemsDictMap?: boolean },
    ) => {
      let { itemsDictMap } = state;
      const {
        payload,
        userId,
        chunkResource,
        query,
        clearItemsDictMap,
      } = action;
      const { items, total } = payload;
      if (clearItemsDictMap) itemsDictMap = {};

      items.forEach(item => {
        const primaryKey = getPrimaryKey(item);
        const secondaryKey = getSecondaryKey(item);
        initIfEmptyForPrimaryKey<ChatMessage>(
          itemsDictMap,
          initialProperties,
          primaryKey,
        );

        if (!primaryKey) {
          return state;
        }

        itemsDictMap[primaryKey].total = total;
        const { firstUnreadMessage, latestChatMessage } = itemsDictMap[
          primaryKey
        ];
        if (
          !latestChatMessage ||
          new Date(item.creationDate as string).getTime() >
            new Date(latestChatMessage.creationDate as string).getTime()
        ) {
          itemsDictMap[primaryKey].latestChatMessage = item;
        }

        if (
          userId &&
          !find(item.users, { userId })?.isRead &&
          (!firstUnreadMessage ||
            new Date(item.creationDate as string).getTime() <
              new Date(firstUnreadMessage.creationDate as string).getTime())
        ) {
          itemsDictMap[primaryKey].firstUnreadMessage = item;
        }

        // need secondaryKey bellow
        if (!secondaryKey) return;

        if (!itemsDictMap[primaryKey].itemsDict[secondaryKey]) {
          itemsDictMap[primaryKey].itemsDict[secondaryKey] = [];
        }
        const index = itemsDictMap[primaryKey].itemsDict[
          secondaryKey
        ].findIndex(oldItem => oldItem.id === item.id);
        if (index === -1) {
          itemsDictMap[primaryKey].itemsDict[secondaryKey] = [
            item,
            ...itemsDictMap[primaryKey].itemsDict[secondaryKey],
          ];
        } else {
          itemsDictMap[primaryKey].itemsDict[secondaryKey][index] = item;
          itemsDictMap[primaryKey].itemsDict[secondaryKey] = [
            ...itemsDictMap[primaryKey].itemsDict[secondaryKey],
          ];
        }
      });

      const updatedObject = {
        itemsDictMap: { ...itemsDictMap },
        total,
        [`isLoading${chunkResource || ''}`]: false,
        isLoading: false,
        error: null,
        currentDate: state.currentDate,
        query,
      };
      return {
        ...state,
        ...updatedObject,
      };
    };
  });

  itemsDictMapReducerConfig[ACKNOWLEDGE] = (
    state: ItemsDictMapState<ChatMessage, InitState, InitProperties>,
    action: ItemsDictMapAction<ChatMessage>,
  ) => {
    const { itemsDictMap } = state;
    const { payload } = action;
    const primaryKey = getPrimaryKey(payload);

    if (!primaryKey) {
      return state;
    }

    initIfEmptyForPrimaryKey(itemsDictMap, initialProperties, primaryKey);
    // below are some casting for date - it is OK for now - either way, value will end up as NaN (falsy) which is checked after and used for comparaison
    const { firstUnreadMessage } = itemsDictMap[primaryKey];
    const firstUnreadMessageSecondaryKeyTimestamp =
      firstUnreadMessage &&
      new Date(getSecondaryKey(firstUnreadMessage) as string).getTime();
    const firstUnreadMessageCreationDateTimestamp =
      firstUnreadMessage &&
      new Date(firstUnreadMessage.creationDate as string).getTime();
    const lastAcknowledgedMessageSecondaryKeyTimestamp = new Date(
      getSecondaryKey(payload) as string,
    ).getTime();
    const lastAcknowledgedMessageCreationDateTimestamp = new Date(
      payload.creationDate as string,
    ).getTime();
    Object.keys(itemsDictMap[primaryKey]?.itemsDict).forEach(
      (secondaryKey: string) => {
        const secondaryKeyTimestamp = new Date(secondaryKey).getTime();
        if (
          (!firstUnreadMessageSecondaryKeyTimestamp ||
            firstUnreadMessageSecondaryKeyTimestamp <= secondaryKeyTimestamp) &&
          secondaryKeyTimestamp <= lastAcknowledgedMessageSecondaryKeyTimestamp
        ) {
          itemsDictMap[primaryKey].itemsDict[secondaryKey] = (
            itemsDictMap[primaryKey].itemsDict[secondaryKey] || []
          ).map(chatMessage => {
            const { creationDate } = chatMessage;
            const creationDateTimestamp = new Date(
              creationDate as string,
            ).getTime();
            if (
              (!firstUnreadMessageCreationDateTimestamp ||
                firstUnreadMessageCreationDateTimestamp <=
                  creationDateTimestamp) &&
              creationDateTimestamp <=
                lastAcknowledgedMessageCreationDateTimestamp
            ) {
              return {
                ...chatMessage,
                users: payload.users,
              };
            }
            return chatMessage;
          });
        }
      },
    );

    return {
      ...state,
      itemsDictMap: { ...itemsDictMap },
    };
  };

  itemsDictMapReducerConfig[ACKNOWLEDGE_SUCCESS] = (
    state: ItemsDictMapState<ChatMessage, InitState, InitProperties>,
    action: ItemsDictMapAction<ChatMessage>,
  ) => {
    const {
      itemsDictMap,
      totalUnreadMessagesCount: totalUnreadMessagesCountState,
      latestUnreadMessage,
    } = state;
    const { payload, userId } = action;
    const { chatRoomId } = payload;
    let totalUnreadMessagesCount = totalUnreadMessagesCountState;
    const primaryKey = getPrimaryKey(payload);
    initIfEmptyForPrimaryKey(itemsDictMap, initialProperties, primaryKey);

    if (!primaryKey) {
      return state;
    }

    Object.keys(itemsDictMap[primaryKey].itemsDict).forEach(
      (secondaryKey: string) => {
        itemsDictMap[primaryKey].itemsDict[secondaryKey] = (
          itemsDictMap[primaryKey].itemsDict[secondaryKey] || []
        ).map(chatMessage => ({
          ...chatMessage,
          users: chatMessage.users?.map(user => {
            if (user.userId === userId && !user.isRead) {
              return { ...user, isRead: true };
            }
            return user;
          }),
        }));
      },
    );

    totalUnreadMessagesCount -= itemsDictMap[primaryKey].unreadMessagesCount;
    itemsDictMap[primaryKey].unreadMessagesCount = 0;

    return {
      ...state,
      itemsDictMap: { ...itemsDictMap },
      totalUnreadMessagesCount,
      latestUnreadMessage: latestUnreadMessage.filter(
        m => m.chatRoomId !== chatRoomId,
      ),
    };
  };

  itemsDictMapReducerConfig[SET_CURRENT_CHATROOM_ID] = (
    state: ItemsDictMapState<ChatMessage, InitState, InitProperties>,
    action: ItemsDictMapAction<ChatMessage>,
  ) => {
    const {
      payload: { chatRoomId },
    } = action;

    return {
      ...state,
      currentChatRoomId: chatRoomId,
    };
  };

  itemsDictMapReducerConfig[SET_CHAT_ROOM_INCOMING_CALL] = (
    state: ItemsDictMapState<ChatMessage, InitState, InitProperties>,
    action: ItemsDictMapAction<ChatMessage>,
  ) => {
    const { payload } = action;

    return {
      ...state,
      chatRoomIncomingCall: payload,
    };
  };

  itemsDictMapReducerConfig[SET_IS_TOUCHING_BOTTOM] = (
    state: ItemsDictMapState<ChatMessage, InitState, InitProperties>,
    action: ItemsDictMapAction<ChatMessage>,
  ) => {
    const { payload: isTouchingBottom } = action;

    return {
      ...state,
      isTouchingBottom,
    };
  };

  itemsDictMapReducerConfig[SET_IS_CHATROOM_VISIBLE] = (
    state: ItemsDictMapState<ChatMessage, InitState, InitProperties>,
    action: ItemsDictMapAction<ChatMessage>,
  ) => {
    const { payload: isChatRoomVisible } = action;

    return {
      ...state,
      isChatRoomVisible,
    };
  };

  itemsDictMapReducerConfig[SET_CHATMESSAGE_REDIRECTED_FROM_THREAD] = (
    state: ItemsDictMapState<ChatMessage, InitState, InitProperties>,
    action: ItemsDictMapAction<ChatMessage>,
  ) => {
    const { payload: chatMessageRedirectedFromThreadPage } = action;

    return {
      ...state,
      chatMessageRedirectedFromThreadPage,
    };
  };

  itemsDictMapReducerConfig[TOGGLE_DELETE] = (
    state: ItemsDictMapState<ChatMessage, InitState, InitProperties>,
    action: ItemsDictMapAction<ChatMessage>,
  ) => {
    const { itemsDictMap } = state;
    const { payload } = action;

    const primaryKey = getPrimaryKey(payload);

    if (!primaryKey) {
      return state;
    }

    initIfEmptyForPrimaryKey(itemsDictMap, initialProperties, primaryKey);

    const { deleteMessage } = itemsDictMap[primaryKey];
    if (!deleteMessage || deleteMessage.id !== payload.id) {
      itemsDictMap[primaryKey].deleteMessage = payload;
    } else {
      itemsDictMap[primaryKey].deleteMessage = null;
    }
    return {
      ...state,
      itemsDictMap: { ...itemsDictMap },
    };
  };

  itemsDictMapReducerConfig[SAVE_EDIT_DRAFT] = (
    state: ItemsDictMapState<ChatMessage, InitState, InitProperties>,
    action: ItemsDictMapAction<ChatMessage>,
  ) => {
    const { itemsDictMap } = state;
    const { payload } = action;

    const primaryKey = getPrimaryKey(payload);

    if (!primaryKey) {
      return state;
    }

    initIfEmptyForPrimaryKey(itemsDictMap, initialProperties, primaryKey);

    if (itemsDictMap[primaryKey].editMessage?.id === payload.id) {
      itemsDictMap[primaryKey].editMessage = payload;
    } else {
      return state;
    }

    return {
      ...state,
      itemsDictMap: { ...itemsDictMap },
    };
  };

  itemsDictMapReducerConfig[TOGGLE_EDIT] = (
    state: ItemsDictMapState<ChatMessage, InitState, InitProperties>,
    action: ItemsDictMapAction<ChatMessage>,
  ) => {
    const { itemsDictMap } = state;
    const { payload } = action;

    const primaryKey = getPrimaryKey(payload);

    if (!primaryKey) {
      return state;
    }

    initIfEmptyForPrimaryKey(itemsDictMap, initialProperties, primaryKey);

    const { editMessage } = itemsDictMap[primaryKey];

    // disabled by JBC the 01/11/2022 for mobile side only
    // because of this condition, we can only update a message on the first time
    // maybe you will be able to remove it if you
    // don't need this on web side too ;-)
    if (!isMobile() && editMessage && editMessage?.id !== payload.id) {
      return state;
    }

    if (itemsDictMap[primaryKey].editMessage?.id === payload.id) {
      itemsDictMap[primaryKey].editMessage = null;
    } else {
      itemsDictMap[primaryKey].editMessage = {
        ...payload,
        originalMessageContent: payload.messageContent,
      };
    }
    return {
      ...state,
      itemsDictMap: { ...itemsDictMap },
    };
  };

  itemsDictMapReducerConfig[GET_FIRST_UNREAD_MESSAGE_SUCCESS] = (
    state: ItemsDictMapState<ChatMessage, InitState, InitProperties>,
    action: ItemsDictMapAction<
      ChatMessage & { firstUnreadMessage: ChatMessage | null }
    >,
  ) => {
    const { itemsDictMap } = state;
    const { payload } = action;
    const { firstUnreadMessage } = payload;
    const primaryKey = getPrimaryKey(payload);

    if (!primaryKey) {
      return state;
    }

    initIfEmptyForPrimaryKey(itemsDictMap, initialProperties, primaryKey);
    itemsDictMap[primaryKey].firstUnreadMessage = firstUnreadMessage;

    return {
      ...state,
      itemsDictMap: { ...itemsDictMap },
    };
  };

  itemsDictMapReducerConfig[GET_LATEST_SUCCESS] = (
    state: ItemsDictMapState<ChatMessage, InitState, InitProperties>,
    action: ItemsDictMapAction<{ items: ChatMessage[] }>,
  ) => {
    const {
      payload: { items },
    } = action;
    const { itemsDictMap } = state;

    items.forEach(item => {
      const primaryKey = getPrimaryKey(item);
      if (primaryKey) {
        initIfEmptyForPrimaryKey(itemsDictMap, initialProperties, primaryKey);
        itemsDictMap[primaryKey].latestChatMessage = item;
      }
    });

    return {
      ...state,
      itemsDictMap: { ...itemsDictMap },
    };
  };

  itemsDictMapReducerConfig[GET_UNREAD_COUNT_SUCCESS] = (
    state: ItemsDictMapState<ChatMessage, InitState, InitProperties>,
    action: ItemsDictMapAction<{
      items: (ChatMessage & {
        unreadMessageCount: number;
        unreadThreadCount: number;
      })[];
    }>,
  ) => {
    const {
      payload: { items },
    } = action;
    const { itemsDictMap } = state;
    let totalUnreadMessagesCount = 0;

    items.forEach(item => {
      const { centerId, unreadMessageCount, unreadThreadCount } = item;
      const primaryKey = getPrimaryKey(item);
      if (primaryKey) {
        initIfEmptyForPrimaryKey(itemsDictMap, initialProperties, primaryKey);
        itemsDictMap[primaryKey].unreadMessagesCount = unreadMessageCount;
        itemsDictMap[primaryKey].unreadThreadsCount = unreadThreadCount;
        itemsDictMap[primaryKey].centerId = centerId ?? null;
        totalUnreadMessagesCount += unreadMessageCount + unreadThreadCount;
      }
    });

    return {
      ...state,
      itemsDictMap: { ...itemsDictMap },
      totalUnreadMessagesCount,
    };
  };

  [INCREMENT_REPLY_NUMBER, DECREMENT_REPLY_NUMBER].forEach(actionType => {
    itemsDictMapReducerConfig[actionType] = (
      state: ItemsDictMapState<ChatMessage, InitState, InitProperties>,
      action: ItemsDictMapAction<{
        message: ChatMessage;
        reply: ChatReplyMessage;
      }>,
    ) => {
      const {
        payload: { message, reply },
      } = action;
      const { itemsDictMap } = state;
      const incrementStep = action.type === INCREMENT_REPLY_NUMBER ? 1 : -1;

      const primaryKey = getPrimaryKey(message);
      const secondaryKey = getSecondaryKey(message);
      if (!(primaryKey && secondaryKey)) {
        return state;
      }
      const index = state.itemsDictMap[primaryKey].itemsDict[
        secondaryKey
      ].findIndex(item => item.id === message.id);
      const item = itemsDictMap[primaryKey].itemsDict[secondaryKey][index];
      item.replyData = {
        count: item.replyData ? (item.replyData.count ?? 0) + incrementStep : 1,
        userIds: uniq([...(item.replyData?.userIds || []), reply.authorId]),
        lastReplyDate: new Date().toISOString(),
      };

      itemsDictMap[primaryKey].itemsDict[secondaryKey][index] = { ...item };
      itemsDictMap[primaryKey].itemsDict[secondaryKey] = [
        ...itemsDictMap[primaryKey].itemsDict[secondaryKey],
      ];
      itemsDictMap[primaryKey].itemsDict = {
        ...itemsDictMap[primaryKey].itemsDict,
      };

      return {
        ...state,
        itemsDictMap: { ...itemsDictMap },
      };
    };
  });

  itemsDictMapReducerConfig[CLEAR_OLD_CHATROOM] = (
    state: ItemsDictMapState<ChatMessage, InitState, InitProperties>,
    action: ItemsDictMapAction<{
      chatRoomId: string;
      messagesToKeep: number;
    }>,
  ) => {
    const { itemsDictMap } = state;
    const { chatRoomId } = action.payload;

    if (!itemsDictMap[chatRoomId]) {
      return state;
    }

    itemsDictMap[chatRoomId].itemsDict = {};

    return {
      ...state,
      itemsDictMap: {
        ...itemsDictMap,
      },
    };
  };
  itemsDictMapReducerConfig[RESET_MESSAGES] = (
    state: ItemsDictMapState<ChatMessage, InitState, InitProperties>,
  ) => {
    return {
      ...state,
      ...((chatMessagesInitialState as unknown) as ChatMessageState),
    };
  };

  itemsDictMapReducerConfig[UPDATE_TELE_DATA_EXPERT] = (
    state: ItemsDictMapState<ChatMessage, InitState, InitProperties>,
    action: ItemsDictMapAction<{
      message: ChatMessage;
      expert: TLEPractitionerDTO;
    }>,
  ) => {
    const {
      payload: { message, expert },
    } = action;
    const { itemsDictMap } = state;

    const primaryKey = getPrimaryKey(message);
    const secondaryKey = getSecondaryKey(message);
    if (!(primaryKey && secondaryKey)) {
      return state;
    }
    const index = state.itemsDictMap[primaryKey].itemsDict[
      secondaryKey
    ].findIndex(item => item.id === message.id);
    const item = itemsDictMap[primaryKey].itemsDict[secondaryKey][index];
    const { teleExpertiseData } = item;
    item.teleExpertiseData = {
      ...teleExpertiseData,
      expert,
    };

    itemsDictMap[primaryKey].itemsDict[secondaryKey][index] = { ...item };
    itemsDictMap[primaryKey].itemsDict[secondaryKey] = [
      ...itemsDictMap[primaryKey].itemsDict[secondaryKey],
    ];
    itemsDictMap[primaryKey].itemsDict = {
      ...itemsDictMap[primaryKey].itemsDict,
    };

    return {
      ...state,
      itemsDictMap: { ...itemsDictMap },
    };
  };
  itemsDictMapReducerConfig[UNREAD_MESSAGE_SUCCESS] = (
    state: ItemsDictMapState<ChatMessage, InitState, InitProperties>,
    action: ItemsDictMapAction<{
      message?: ChatMessage;
      userId?: string;
    }>,
  ) => {
    const {
      payload: { message, userId },
    } = action;
    const { itemsDictMap } = state;

    if (!message || !userId) {
      return state;
    }

    const primaryKey = getPrimaryKey(message);

    if (!primaryKey) {
      return state;
    }

    const unReadChatMessage = message.parentId
      ? Object.values(itemsDictMap[primaryKey].itemsDict)
          .flat()
          .find(item => item.id === message.parentId)!
      : message;

    const secondaryKey = getSecondaryKey(unReadChatMessage);

    if (!secondaryKey) {
      return state;
    }

    const firstUnreadMessage = itemsDictMap[primaryKey]?.firstUnreadMessage;

    const secondaryKeyForFirstUnreadMessage = firstUnreadMessage
      ? getSecondaryKey(firstUnreadMessage)
      : null;

    const updatedFirstUnreadMessage = itemsDictMap[primaryKey]?.itemsDict?.[
      secondaryKeyForFirstUnreadMessage || ''
    ]?.find(item => item.id === firstUnreadMessage?.id);

    const isFirstUnreadMessageRead = find(
      updatedFirstUnreadMessage?.users || [],
      {
        userId,
      },
    )?.isRead;

    const firstUnreadMessageCreationTime = firstUnreadMessage?.creationDate
      ? new Date(firstUnreadMessage?.creationDate as string).getTime()
      : Number.MAX_SAFE_INTEGER;

    const payloadCreationTime = new Date(
      unReadChatMessage?.creationDate as string,
    ).getTime();

    if (
      payloadCreationTime < firstUnreadMessageCreationTime ||
      isFirstUnreadMessageRead
    )
      itemsDictMap[primaryKey].firstUnreadMessage = unReadChatMessage;

    return {
      ...state,
      itemsDictMap: { ...itemsDictMap },
    };
  };

  return itemsDictMapReducerConfig;
};

const extendReducer: Dictionary<(
  state: ItemsDictMapState<ChatMessage, InitState, InitProperties>,
  action: ItemsDictMapAction<any, any>,
) => ItemsDictMapState<ChatMessage>> = {};

[
  CREATE_SUCCESS,
  `${CREATE_SUCCESS}/${name}`,
  UPLOAD_SUCCESS,
  `${UPLOAD_SUCCESS}/${name}`,
  UPLOAD_MANY_SUCCESS,
  `${UPLOAD_MANY_SUCCESS}/${name}`,
  GET_ONE_SUCCESS,
  `${GET_ONE_SUCCESS}/${name}`,
  UPDATE_SUCCESS,
  `${UPDATE_SUCCESS}/${name}`,
].forEach(type => {
  extendReducer[type] = (
    state: ItemsDictMapState<
      ChatMessage & { isSending?: boolean },
      InitState,
      InitProperties
    >,
    action: ItemsDictMapAction<ChatMessage & { isError: boolean }>,
  ) => {
    const {
      currentChatRoomId,
      itemsDictMap,
      totalUnreadMessagesCount: totalUnreadMessagesCountState,
      latestUnreadMessage,
    } = state;
    let totalUnreadMessagesCount = totalUnreadMessagesCountState;
    const { payload, userId } = action;
    const { chatRoomId, users, authorId, centerId } = payload;
    const primaryKey = getPrimaryKey(payload);

    const secondaryKey = getSecondaryKey(payload) as string;
    if (!primaryKey) {
      return state;
    }

    if (!itemsDictMap[primaryKey]) {
      initIfEmptyForPrimaryKey(itemsDictMap, initialProperties, primaryKey);
    }
    if (!itemsDictMap[primaryKey].centerId)
      itemsDictMap[primaryKey].centerId =
        centerId || itemsDictMap[primaryKey].centerId;
    if (action.type !== UPDATE_SUCCESS) {
      const user = payload.users?.find(item => item.userId === userId);
      const firstUnreadMessage =
        itemsDictMap[primaryKey]?.firstUnreadMessage ||
        userId === authorId ||
        user?.isRead
          ? itemsDictMap[primaryKey]?.firstUnreadMessage
          : payload;
      itemsDictMap[primaryKey].firstUnreadMessage = firstUnreadMessage;
      if (!payload.isError) {
        // eslint-disable-next-line no-plusplus
        itemsDictMap[primaryKey].total++;
      }
    }

    if (
      [
        CREATE_SUCCESS,
        `${CREATE_SUCCESS}/${name}`,
        UPLOAD_SUCCESS,
        `${UPLOAD_SUCCESS}/${name}`,
        UPLOAD_MANY_SUCCESS,
        `${UPLOAD_MANY_SUCCESS}/${name}`,
      ].includes(action.type)
    ) {
      const items = itemsDictMap[primaryKey].itemsDict[secondaryKey];

      const messageIndex = items.findIndex(message => message.isSending);

      if (messageIndex > -1) {
        items.splice(messageIndex, 1);
      }

      itemsDictMap[primaryKey].itemsDict[secondaryKey] = [...items];
    }

    const { latestChatMessage } = itemsDictMap[primaryKey];
    if (
      !payload.parentId &&
      !payload.isError &&
      (!latestChatMessage ||
        latestChatMessage.id === payload.id ||
        new Date(payload.creationDate as string).getTime() >
          new Date(latestChatMessage.creationDate as string).getTime())
    ) {
      itemsDictMap[primaryKey].latestChatMessage = payload;
    }
    const user = find(users, { userId });
    const isTouchingBottomInCurrentRoom =
      currentChatRoomId === chatRoomId && state.isTouchingBottom === true;
    const { isChatRoomVisible } = state;
    if (
      (user && !user.isRead && !isTouchingBottomInCurrentRoom) ||
      // CHANGE FOR MOBILE
      (isMobile() && user && !user.isRead && !isChatRoomVisible)
    ) {
      // eslint-disable-next-line no-plusplus
      itemsDictMap[primaryKey].unreadMessagesCount++;
      // eslint-disable-next-line no-plusplus
      totalUnreadMessagesCount++;
      return {
        ...state,
        itemsDictMap: { ...itemsDictMap },
        totalUnreadMessagesCount,
        latestUnreadMessage: [
          ...latestUnreadMessage.filter(m => m.id !== payload?.id),
          payload,
        ],
      };
    }
    return { ...state, itemsDictMap: { ...itemsDictMap } };
  };
});

extendReducer[DELETE_SUCCESS] = (
  state: ItemsDictMapState<ChatMessage, InitState, InitProperties>,
  action: ItemsDictMapAction<ChatMessage, { params: ChatMessage }>,
) => {
  const {
    itemsDictMap,
    totalUnreadMessagesCount: totalUnreadMessagesCountState,
    latestDeleted,
  } = state;
  const { userId, skipExtendedReducer, query } = action;
  const { params: deletedMessage } = query;
  if (skipExtendedReducer) return state;
  let totalUnreadMessagesCount = totalUnreadMessagesCountState;
  const users = latestDeleted?.users;
  const primaryKey = latestDeleted ? getPrimaryKey(latestDeleted) : false;

  if (!primaryKey) {
    return state;
  }

  const user = find(users, { userId });
  // eslint-disable-next-line no-plusplus
  itemsDictMap[primaryKey].total--;
  const { latestChatMessage, itemsDict } = itemsDictMap[primaryKey];
  if (deletedMessage.id === latestChatMessage?.id) {
    const secondaryKey = Object.keys(itemsDict).reduce(
      (newKey: string | null, key: string) =>
        !newKey || new Date(key).getTime() > new Date(newKey).getTime()
          ? key
          : newKey,
      null,
    );
    if (secondaryKey) {
      itemsDictMap[primaryKey].latestChatMessage = itemsDict[
        secondaryKey
      ].reduce(
        (latest: ChatMessage | null, item: ChatMessage) =>
          !latest ||
          new Date(item.creationDate as string).getTime() >
            new Date(latest.creationDate as string).getTime()
            ? item
            : latest,
        null,
      );
    }
  }

  state.total -= 1;

  if (user && !user.isRead) {
    // eslint-disable-next-line no-plusplus
    itemsDictMap[primaryKey].unreadMessagesCount--;
    // eslint-disable-next-line no-plusplus
    totalUnreadMessagesCount--;
    return {
      ...state,
      itemsDictMap: { ...itemsDictMap },
      totalUnreadMessagesCount,
    };
  }
  return state;
};

export const chatMessages = itemsDictMapReducerGenerator<
  ChatMessage,
  ChatMessage,
  InitState,
  InitProperties
>({
  name,
  keysOptions,
  surchargeReducer,
  extendReducer,
});
