import { createContext, RefObject } from 'react';

type SearchContextValue = {
  searchFormRef: RefObject<any>;
  chatRoomsFilterRef: RefObject<any>;
  submitButtonRef: RefObject<any>;
  showSearchHistoryList: boolean;
  setShowSearchHistoryList: (flag: boolean) => void;
  searchTerm: string | null;
  setSearchTerm: (value: string | null) => void;
  clickedRecentSearchHTMLMessage: string | null;
  setClickedRecentSearchHTMLMessage: (value: string | null) => void;
  isFetchingNewSearchResults: boolean;
  setIsFetchingNewSearchResults: (flag: boolean) => void;
  clearSearchForm: () => void;
};

// @ts-ignore
const SearchContext = createContext<SearchContextValue>({});

export default SearchContext;
