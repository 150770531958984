import { actionsGeneratorV2 } from '@docavenue/core';
import { AppointmentGetDTO } from '@maiia/model/generated/model/api-pro/api-pro';

const appointmentsConflictsActions = actionsGeneratorV2({
  resource: 'appointmentsConflicts',
  chunkUrlResource: 'appointments',
  disableConcurrency: {
    isGetListDisableConcurrency: true,
    isGetOneDisableConcurrency: true,
  },
});

const {
  updateManyPost: _updateManyPost,
  deleteManyPost: _deleteManyPost,
  getList: _getList,
} = appointmentsConflictsActions;

const getList = (params: Object = {}, options: Object = {}) =>
  _getList(params, {
    chunkUrlResource: 'v2/appointments',
    ...options,
  });

const getRecurrenceList = (params: Object = {}, options: Object = {}) =>
  _getList(params, {
    chunkResource: 'recurrence',
    ...options,
  });

const updateManyPost = (items: AppointmentGetDTO[], options: Object = {}) =>
  _updateManyPost(items, {
    chunkUrlResource: 'update-appointments',
    ...options,
  });

const deleteManyPost = (appointmentIds: string[], options: Object = {}) =>
  _deleteManyPost(appointmentIds, {
    chunkUrlResource: 'delete-appointments',
    ...options,
  });

export default {
  ...appointmentsConflictsActions,
  getList,
  getRecurrenceList,
  updateManyPost,
  deleteManyPost,
};
