import React from 'react';
import { useSelector } from 'react-redux';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@kiiwi/ui';
import { Tooltip } from '@mui/material';
import orderBy from 'lodash/orderBy';
import { makeStyles } from 'tss-react/mui';

import { Patient } from '@maiia/model/generated/model/api-pro/api-pro';

import { useTranslation } from '../../../i18n';
import { noopEvent, openProxy } from '../../../src/utils';
import ButtonBase from '../../atoms/ButtonBase';

import { SYNCHRONIZED } from '@/src/constants';

const useStyles = makeStyles<{ color?: string }>()((theme: any, { color }) => ({
  lgcConnectRoot: {
    color: `${color}` || 'inherit',
  },
  mutliLgc: {
    color: `${color}` || 'inherit',
  },

  buttonSyncSecondary: {
    display: 'block',
    background: '#EEEC',
    margin: theme.spacing(1),
    width: '150px',
    fontWeight: 'bold',
    fontSize: theme.size.font.md,
    padding: 20,
  },

  buttonSyncContainer: {
    background: 'white',
    position: 'relative',
  },

  buttonPrimary: {
    textTransform: 'initial',
    fontWeight: 'bold',
    color: theme.palette.text.white,
    background: theme.palette.main.main,
    padding: theme.spacing(2),
    borderRadius: '16px',
  },
}));

export type LgcConnectDropdownMenuProps = {
  patient: Patient;
  className?: string;
  color?: string;
  gridkey?: string;
  primary?: boolean;
  appointmentId?: string;
};

const LgcConnectDropdownMenu = (props: LgcConnectDropdownMenuProps) => {
  const { patient, className, color, gridkey, primary, appointmentId } = props;
  const { t } = useTranslation();
  const { classes, cx } = useStyles({ color });
  const centerExternalSyncList = useSelector(
    state => state.centers.item?.centerExternalSyncList || [],
  );

  // We consider information from the last updated syncronization
  const centerExternalSyncOrderedList = orderBy(
    centerExternalSyncList,
    [centerExternalSync => centerExternalSync?.updateDate],
    ['desc'],
  );

  const syncStatus = centerExternalSyncOrderedList?.[0]?.syncStatus;

  if (!patient || !patient?.syncData || syncStatus !== SYNCHRONIZED) {
    return null;
  }

  const syncDataReal = patient?.syncData.filter(
    sync => sync?.externalId && sync?.externalUrl && sync?.softwareDisplayName,
  );

  if (syncDataReal.length === 1) {
    return (
      <Tooltip
        title={t('medical_folder_id_ipp', {
          externalId: patient?.syncData?.[0]?.externalId || '',
        })}
        placement="top"
      >
        <ButtonBase
          gridkey={gridkey}
          className={cx(
            classes.lgcConnectRoot,
            primary && classes.buttonPrimary,
            className,
          )}
          onClick={e => {
            noopEvent(e);
            const url = `${syncDataReal?.[0]?.externalUrl}${
              appointmentId ? `&externalidrdv=${appointmentId}` : ''
            }`;
            openProxy(url, 'lgc');
          }}
        >
          <span>{syncDataReal?.[0]?.softwareDisplayName}</span>
        </ButtonBase>
      </Tooltip>
    );
  }
  if (syncDataReal.length > 1) {
    return (
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <ButtonBase
            gridkey={gridkey}
            className={cx(classes.mutliLgc, className)}
          >
            {t('medical_folder')}
          </ButtonBase>
        </DropdownMenuTrigger>
        <DropdownMenuContent className="min-w-0">
          {syncDataReal.map(data => (
            <Tooltip
              key={data.externalId}
              title={t('medical_folder_id_ipp', {
                externalId: data.externalId || '',
              })}
              disableInteractive
            >
              <DropdownMenuItem
                key={data.externalId}
                className={classes.buttonSyncSecondary}
                onClick={e => {
                  noopEvent(e);
                  const url = data.externalUrl;
                  openProxy(url, 'lgc');
                }}
              >
                {data?.softwareDisplayName}
              </DropdownMenuItem>
            </Tooltip>
          ))}
        </DropdownMenuContent>
      </DropdownMenu>
    );
  }
  return null;
};

export default LgcConnectDropdownMenu;
