import React from 'react';
import { makeStyles } from 'tss-react/mui';

import { useTranslation } from '../../../i18n';
import { useIsTelesecretary } from '../../../src/hooks/selectors';
import Highlight from '../../atoms/Highlight';
import ClickToCallDeprecated from '../../molecules/ClickToCallDeprecated/ClickToCallDeprecated';
import { replaceFranceCallingCode } from '../../utils/tools';

import { maskSecurityNumber } from '@/src/utils';

const useStyles = makeStyles()({
  headerPatientSubLabel: {
    '&.tls': {
      display: 'flex',
      alignItems: 'center',
    },

    '& .italic': {
      fontStyle: 'italic',
    },
  },
});

type Props = {
  searchTerms: Array<string>;
  mobilePhoneNumber?: string;
  healthId?: string;
};

const findPhoneNumberChunk: any = ({ searchWords, textToHighlight = '' }) => {
  const wordToFormat = searchWords[0];
  if (wordToFormat?.length > 0) {
    const formattedWord = replaceFranceCallingCode(wordToFormat.toString());

    const start = textToHighlight.indexOf(formattedWord);
    if (start >= 0 && formattedWord.length > 0) {
      const end = start + formattedWord.length;
      return [{ start, end }];
    }
  }
  return [];
};

const HeaderPatientSubLabel = (props: Props) => {
  const { classes, cx } = useStyles();
  const { t } = useTranslation();
  const isTls = useIsTelesecretary();
  const { searchTerms, mobilePhoneNumber, healthId } = props;

  return (
    <div
      className={cx(
        classes.headerPatientSubLabel,
        'headerPatientSubLabel',
        isTls && 'tls',
      )}
    >
      {isTls && (
        <ClickToCallDeprecated phoneNumber={mobilePhoneNumber} isMobile />
      )}
      {mobilePhoneNumber ? (
        <Highlight
          searchTerm={searchTerms}
          text={mobilePhoneNumber}
          findChunks={findPhoneNumberChunk}
        />
      ) : (
        <span className="italic">
          {t('common:headersearch__patient_missing_phone_number')}
        </span>
      )}
      {/* eslint-disable-next-line react/jsx-no-literals */}
      {healthId ? <span> - </span> : null}
      {healthId && (
        <Highlight
          searchTerm={searchTerms}
          text={maskSecurityNumber(healthId)}
        />
      )}
    </div>
  );
};

HeaderPatientSubLabel.displayName = 'HeaderPatientSubLabel';

export default HeaderPatientSubLabel;
