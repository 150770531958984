import { AppointmentGetDTO } from '@maiia/model/generated/model/api-pro/api-pro';

import {
  initialStateItemsDict,
  itemsDictMapReducerGenerator,
  keysOptionsDefault,
} from '@/src/reducer/itemsDictMap';

const name = 'appointments';

export const initialState = initialStateItemsDict;

const appointments = itemsDictMapReducerGenerator<AppointmentGetDTO>({
  name,
  keysOptions: keysOptionsDefault,
});

export default appointments;
