import { crudInitialDefaultState, reducersGenerator } from '@docavenue/core';
// it's ok to disable the line below because it's only type import - todo find a way to export types in built packages
// eslint-disable-next-line no-restricted-imports
import { CrudInitialDefaultState } from '@docavenue/core/src/types';
import {
  OnfidoVerification,
  ProUserDTO,
} from '@maiia/model/generated/model/api-pro/api-pro';

import { SET_ONFIDO_VERIFICATION } from '../actions/users';

export const usersInitialState = crudInitialDefaultState;

const surchargeReducer = () => ({
  [SET_ONFIDO_VERIFICATION]: (
    state: CrudInitialDefaultState<ProUserDTO>,
    action: { type: string; payload: OnfidoVerification },
  ) => {
    const users = { ...state };
    if (!users.item) {
      return users;
    }

    return {
      ...users,
      item: {
        ...users.item,
        onfidoVerification: action.payload,
      },
    };
  },
});

export const users = reducersGenerator({
  name: 'users',
  surchargeReducer,
});
