import { GET_LIST_SUCCESS } from '@docavenue/core';
import { TimeSlotGetDTO } from '@maiia/model/generated/model/api-pro/api-pro';

import { ItemsDictMapAction } from '@/src/actions/actionsTypes';
import {
  initialStateItemsDict,
  initIfEmptyForPrimaryKey,
  itemsDictMapReducerGenerator,
  keysOptionsDefault,
} from '@/src/reducer/itemsDictMap';
import {
  ItemsDictMapReducer,
  ItemsDictMapState,
} from '@/src/reducer/reducersTypes';

const name = 'timeSlotResourcesCalendar';
const { getSecondaryKey, initialProperties } = keysOptionsDefault;

export const initialState = initialStateItemsDict;

const surchargeReducer = (
  initialReducer: ItemsDictMapReducer<TimeSlotGetDTO>,
) => {
  const extendReducer: ItemsDictMapReducer<TimeSlotGetDTO> = {};
  [GET_LIST_SUCCESS, `${GET_LIST_SUCCESS}/${name}`].forEach(actionType => {
    extendReducer[actionType] = (
      state: ItemsDictMapState<TimeSlotGetDTO>,
      action: ItemsDictMapAction<{ items: TimeSlotGetDTO[] }>,
    ) => {
      const { itemsDictMap } = state;
      const { payload, chunkResource, query } = action;

      query['time-slot-resources-ids'].forEach(timeSlotResourceId => {
        payload[timeSlotResourceId]?.items.forEach((item: TimeSlotGetDTO) => {
          const primaryKey = timeSlotResourceId;
          const secondaryKey = getSecondaryKey(item);
          initIfEmptyForPrimaryKey<TimeSlotGetDTO>(
            itemsDictMap,
            initialProperties,
            primaryKey,
          );

          if (!itemsDictMap[primaryKey].itemsDict[secondaryKey]) {
            itemsDictMap[primaryKey].itemsDict[secondaryKey] = [];
          }
          const index = itemsDictMap[primaryKey].itemsDict[
            secondaryKey
          ].findIndex(oldItem => oldItem.id === item.id);
          if (index === -1) {
            itemsDictMap[primaryKey].itemsDict[secondaryKey] = [
              item,
              ...itemsDictMap[primaryKey].itemsDict[secondaryKey],
            ];
          } else {
            itemsDictMap[primaryKey].itemsDict[secondaryKey][index] = item;
            itemsDictMap[primaryKey].itemsDict[secondaryKey] = [
              ...itemsDictMap[primaryKey].itemsDict[secondaryKey],
            ];
          }
        });
      });

      const updatedObject = {
        itemsDictMap: { ...itemsDictMap },
        [`isLoading${chunkResource || ''}`]: false,
        isLoading: false,
        error: null,
        currentDate: state.currentDate,
        query,
      };
      return {
        ...state,
        ...updatedObject,
      };
    };
  });
  return { ...initialReducer, ...extendReducer };
};

const timeSlotResourcesCalendar = itemsDictMapReducerGenerator<TimeSlotGetDTO>({
  name,
  keysOptions: keysOptionsDefault,
  surchargeReducer,
});

export default timeSlotResourcesCalendar;
