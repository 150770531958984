import { actionsGeneratorV2, UPDATE } from '@docavenue/core';

const agendaSettingsDefaultActions = actionsGeneratorV2({
  resource: 'agendaSettingsDefault',
  chunkUrlResource: 'agendaSettings',
  disableConcurrency: {
    isGetListDisableConcurrency: true,
    isGetOneDisableConcurrency: true,
  },
});

const {
  getList: _getList,
  updateOne,
  getOneWithCompositeKey,
} = agendaSettingsDefaultActions;

const getList = (
  params: { practitionerId: string; aggregateWith: string },
  options: Object = {},
) => {
  const { practitionerId, ...restParams } = params;
  return _getList(restParams, {
    chunkUrlResource: 'practitioners',
    chunkId: practitionerId,
    chunkResource: 'agendaSettings',
    ...options,
  });
};

const getSubstitutedList = (
  params: { userId: string; aggregateWith: string },
  options: Object = {},
) =>
  _getList(params, {
    chunkUrlResource: 'agendaSettings',
    chunkResource: 'substituted',
    ...options,
  });

const isValidInsuranceId = (
  params: { healthInsuranceId: string },
  options: Object = {},
) =>
  getOneWithCompositeKey(
    {},
    {
      chunkUrlResource: 'agendaSettings',
      chunkResource: `isValidInsuranceId/${params.healthInsuranceId}`,
      ...(options || {}),
    },
  );

const updateAmNumber = (
  data: Object,
  options: { params: { agendaSettingsId: string } },
) => {
  const { params } = options || {};
  const { agendaSettingsId, ...restParams } = params;
  return updateOne(data, {
    chunkUrlResource: 'agendaSettings',
    type: UPDATE,
    ...{
      ...options,
      params: { ...restParams },
      chunkResource: 'health-insurance-id',
    },
  });
};

export default {
  ...agendaSettingsDefaultActions,
  getList,
  getSubstitutedList,
  isValidInsuranceId,
  updateAmNumber,
};
