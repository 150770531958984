/* eslint-disable no-nested-ternary */
import React from 'react';
import { TextField as MuiTextField, TextFieldProps } from '@mui/material';
import merge from 'lodash/merge';
import omit from 'lodash/omit';
import { makeStyles } from 'tss-react/mui';

import { useTranslation } from '../../../../i18n';
import { uuidv4 } from '../../../utils/tools';
import FontAwesomeIcon from '../../FontawesomeIcon/FontawesomeIcon';
import Typography from '../../Typography';

import { useDatacyName } from '@/src/hooks/datacy';

const FaExpandMoreIcon = () => (
  <FontAwesomeIcon
    name="angle-down:regular"
    className="pointer-events-none absolute right-2 top-2.5 inline-block h-4 w-4 select-none"
  />
);

type SProps = {
  suggested?: boolean;
  darkUnderline?: boolean;
  isUnderline?: boolean;
  isWarningUnderline?: boolean;
  disabled?: boolean;
};

const useStyles = makeStyles<{
  isWarningUnderline: boolean;
  isUnderline: boolean | undefined;
  suggested: boolean | undefined;
  darkUnderline: boolean;
  disabled: boolean;
}>()(
  (
    theme: any,
    { isWarningUnderline, isUnderline, suggested, darkUnderline, disabled },
  ) => ({
    underline: {
      '& input': {
        fontSize: theme.size.font.md,
        cursor: disabled ? 'not-allowed' : 'pointer',
      },
      '&:before': {
        borderBottom: isWarningUnderline
          ? `2px solid ${theme.palette.warning.main}`
          : suggested || isUnderline
          ? `2px solid ${theme.palette.main.main}`
          : darkUnderline
          ? `2px solid ${theme.palette.main.dark}`
          : '0px solid transparent',
      },
      '&:after': {
        borderBottom: isWarningUnderline
          ? `2px solid ${theme.palette.warning.main}`
          : `2px solid ${theme.palette.main.dark}`,
      },
      '&:hover:before': {
        borderBottom: isWarningUnderline
          ? `2px solid ${theme.palette.warning.main}`
          : suggested || isUnderline
          ? `2px solid ${theme.palette.main.main}`
          : darkUnderline
          ? `2px solid ${theme.palette.main.dark}`
          : 'transparent',
      },
      '&.Mui-error:after': {
        borderBottomColor: theme.palette.error.main,
      },
    },
    textfieldSelectMenuPaper: {
      borderRadius: 0,
    },
    textfieldSelectMenuListProps: {
      padding: 0,
    },
    warningText: {
      display: 'flex',
      marginTop: theme.spacing(1),
      color: theme.palette.warning.main,
      '& p': {
        color: 'inherit',
      },
      '& svg': {
        height: theme.spacing(4),
      },
    },
  }),
);

type Props = {
  datacy?: string;
  autocomplete?: string;
  hideError?: boolean;
  warningText?: string;
  // inputProps?: Object;
  // SelectProps?: Object;
  // InputProps?: IProps;
  // onChange: Function;
  // onKeyDown?: Function;
} & Omit<TextFieldProps, 'autoComplete'> &
  SProps;

const TextField = React.forwardRef(
  (props: Props, ref: React.ForwardedRef<any>) => {
    const {
      SelectProps = {},
      InputProps = {},
      inputProps = {},
      autocomplete = 'off',
      datacy,
      placeholder,
      hideError,
      isUnderline,
      suggested,
      warningText,
      ...rest
    } = props;
    const { classes, cx } = useStyles({
      ...(props as any),
    });
    const { t } = useTranslation();
    const datacyName = useDatacyName(datacy);
    const newProps = merge(
      {
        IconComponent: FaExpandMoreIcon,
        MenuProps: {
          classes: {
            paper: classes.textfieldSelectMenuPaper,
          },
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'center',
          },
          MenuListProps: {
            classes: {
              root: classes.textfieldSelectMenuListProps,
            },
          },
        },
        SelectDisplayProps: {
          datacy: SelectProps?.datacy
            ? `${SelectProps.datacy}_toggle`
            : `${datacyName}_toggle`,
        },
      },
      SelectProps,
    );

    return (
      <>
        <MuiTextField
          variant="filled"
          SelectProps={newProps}
          ref={ref}
          InputProps={{
            ...InputProps,
            className: cx(classes.underline, InputProps.className),
            // classes: { ...InputProps?.classes, underline: classes.underline },
          }}
          // eslint-disable-next-line react/jsx-no-duplicate-props
          inputProps={{
            id: uuidv4(),
            name: uuidv4(),
            ...inputProps,
            /**
             * hack: avoid chrome autofill
             * chrome seems to recognize placeholders, so we put
             * some zero-width spaces between characters
             */
            placeholder: placeholder?.split('').join('\u200b'),

            datacy: inputProps?.datacy
              ? `${inputProps.datacy}_text_field`
              : datacy
              ? `${datacyName}_text_field`
              : 'text_field',
          }}
          autoComplete={autocomplete} // hack to disable autocomplete from browsers and password managers
          {...omit(rest, 'isWarningUnderline')}
        />
        {warningText && (
          <div className={classes.warningText} gridkey="warning">
            <FontAwesomeIcon name="circle-info:regular" className="h-5 w-5" />
            <Typography variant="bodySmall">
              {typeof warningText === 'string' ? t(warningText) : warningText}
            </Typography>
          </div>
        )}
      </>
    );
  },
);

export default TextField;
