import { actionsGeneratorV2 } from '@docavenue/core';

export const SET_ROOT_CENTER_ID = 'SET_ROOT_CENTER_ID';

const centersActions = actionsGeneratorV2({
  resource: 'centers',
});

const { getList: _getList, create } = centersActions;

const setRootCenterId = rootCenterId => ({
  resource: 'centers',
  type: SET_ROOT_CENTER_ID,
  payload: rootCenterId,
});

const getCenter = (params: { centerId: string }) =>
  centersActions.getOneWithCompositeKey(
    {},
    {
      chunkResource: params.centerId,
    },
  );

const getList = (params?: { onlyWithAgenda?: boolean }) =>
  _getList(
    {
      onlyWithAgenda: true, // Filter connect only centers by default
      ...(params || {}),
    },
    {
      version: '2.0.0',
    },
  );

const importPatient = (
  params: { centerId: string; patientId: string },
  options: Object = {},
) =>
  create(
    {},
    {
      chunkId: params.centerId,
      chunkResource: `import-patient/${params.patientId}`,
      ...options,
    },
  );

export default {
  ...centersActions,
  setRootCenterId,
  getCenter,
  getList,
  importPatient,
};
