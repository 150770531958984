import { Dispatch } from 'react';
import getConfig from 'next/config';
import {
  Hooks,
  PropertyRecord,
  ScreebObject,
  ScreebOptions,
} from '@screeb/sdk-browser'; // Do not import anything expect type from this sdk, it will cause ssr crash
// eslint-disable-next-line import/no-extraneous-dependencies
import { KeycloakInstance } from 'keycloak-js';

import { asyncActions } from '@docavenue/core';
import { TrackingProfileGetDTO } from '@maiia/model/generated/model/api-pro/api-pro';

import { trackingProfileActions } from '@/src/actions';
import { Actions } from '@/src/actions/actionsTypes';

type Screeb = {
  load: (options?: ScreebOptions) => Promise<undefined>;
  init: (
    websiteId: string,
    userId?: string,
    userProperties?: PropertyRecord,
    hooks?: Hooks,
  ) => void | Promise<unknown>;
  callScreebCommand: (..._: unknown[]) => void | Promise<unknown>;
  eventTrack: (
    eventName: string,
    eventProperties?: PropertyRecord,
  ) => void | Promise<unknown>;
  identity: (
    userId: string,
    userProperties?: PropertyRecord,
  ) => void | Promise<unknown>;
  identityReset: () => void | Promise<unknown>;
};

declare const window: Window & {
  $screeb?: ScreebObject;
  $screebInitialized?: boolean;
};

export const SCREEB_EVENT = {
  // PRO Events
  RECURRING_APPOINTMENT_VALIDATION: 'RECURRING_APPOINTMENT_VALIDATION',
  RESOURCE_CREATION: 'RESOURCE_CREATION',
  SIMPLE_APPOINTMENT_CREATION: 'SIMPLE_APPOINTMENT_CREATION',
  ADVANCED_APPOINTMENT_CREATION: 'ADVANCED_APPOINTMENT_CREATION',
  END_TLC: 'END_TLC',
  ADD_PRACTITIONER_TO_TIMESLOT_RESOURCES:
    'ADD_PRACTITIONER_TO_TIMESLOT_RESOURCES',
  SECRETARY_INSTRUCTION_CREATION: 'SECRETARY_INSTRUCTION_CREATION',
  CALENDAR_COPY_PASTE: 'CALENDAR_COPY_PASTE',
  WAITINGLIST_PATIENT_ON: 'WAITINGLIST_PATIENT_ON',
  // Connect Events
  TASK_VALIDATION: 'TASK_VALIDATION',
  CONNECT_INVITATION_SENT: 'CONNECT_INVITATION_SENT',
  END_TLE: 'END_TLE',
};

const props = [
  'load',
  'init',
  'callScreebCommand',
  'eventTrack',
  'identity',
  'identityReset',
];
const screeb = (new Proxy(
  {},
  {
    get: (_obj, prop: string) => {
      if (props.includes(prop)) {
        return async (...args: any[]) => {
          try {
            const _screeb = await import('@screeb/sdk-browser');
            return _screeb[prop](...args);
          } catch (e) {
            if (process.env.NODE_ENV !== 'production') {
              console.error(e);
            }
          }
        };
      }
      return _obj[prop];
    },
  },
) as unknown) as Screeb;

type ScreebDispatch = Dispatch<Actions<TrackingProfileGetDTO>>;

const isScreebLoadedAndInitialized = () =>
  Boolean(window.$screeb) &&
  typeof window.$screeb === 'function' &&
  window.$screebInitialized;

const getUserIdentity = async (dispatch: ScreebDispatch) => {
  const { id, ...properties } = await asyncActions(
    dispatch,
    trackingProfileActions.getOne('me'),
  );

  return { id, properties };
};

export const setScreebIdentity = async (
  dispatch: ScreebDispatch,
  keycloakSubject?: KeycloakInstance['subject'],
) => {
  if (isScreebLoadedAndInitialized()) {
    try {
      const { id, properties } = await getUserIdentity(dispatch);
      const _properties: PropertyRecord = { ...properties };

      if (keycloakSubject) _properties.keycloakSubject = keycloakSubject;

      if (id) {
        screeb.identity(id, _properties);
      }
    } catch (error) {
      console.error(error);
    }
  }
};

function defaultGetWebsiteId() {
  return getConfig()?.publicRuntimeConfig?.SCREEB_PRO_WEBSITE_ID;
}

export const screebInit = async (
  dispatch: ScreebDispatch,
  getWebsiteId: () => string = defaultGetWebsiteId,
) => {
  if (!isScreebLoadedAndInitialized()) {
    try {
      // '$screeb' is the screeb instance that allows us to make user profiling
      // this instance is created & handled by tarteaucitron
      const { id, properties } = await getUserIdentity(dispatch);
      const websiteId = getWebsiteId();

      if (id && websiteId) {
        screeb.init(websiteId, id, properties, {
          version: '1.0.0',
          onReady: () => {
            window.$screebInitialized = true;
          },
        });
      }
    } catch (error) {
      console.error(error);
    }
  }
};

export const screebIdentityReset = () => {
  screeb.identityReset();
};

export const screebTrackEvent = (
  eventName: string,
  eventProperties?: PropertyRecord,
) => {
  screeb.eventTrack(eventName, eventProperties);
};

export default screeb;
