/* eslint-disable import/no-cycle */
import { createContext } from 'react';

import { ConsultationReasonDTO } from '@maiia/model/generated/model/api-pro/api-pro';

import { EventCalendar } from '../../types/pro.types';

interface BigCalendarInterface {
  events: EventCalendar[];
  timeSlots?: EventCalendar[];
  getStyleEvent: (
    event: EventCalendar,
  ) => {
    style: React.CSSProperties;
  };
  consultationReasons?: ConsultationReasonDTO[];
}

const BigCalendarContext = createContext<BigCalendarInterface | null>(null);

export default BigCalendarContext;
