import React, { Component } from 'react';
import find from 'lodash/find';
import isEmpty from 'lodash/isEmpty';

import { asyncActions, parseJwt } from '@docavenue/core';

import {
  agendaSettingsDefaultActions,
  authenticationActions,
  centersActions,
  chatMessagesActions,
  offsetActions,
  usersActions,
} from '../actions';
import { AuthData } from '../actions/authentification';
import Routes from '../constants/routes';
import { MaiiaNextPageContext } from '../types/MaiiaNextContext';
import { getOffset, isNextRouteSafe, isSynchronizationNeeded } from '../utils';
import { Auth } from '../utils/AuthUtils';
import {
  buildUrlString,
  isPathAllowedToChat,
  isPathAllowedToFreemiumUsers,
  isPathAllowedToRegulator,
  isPathWhiteListed,
} from '../utils/RoutesUtils';
import { isTabAllowedToFreemiumUsers } from '../utils/TabsUtils';
import {
  handleWrongTSToken,
  isConnectOnboardingUser,
  isFromConnectOnboarding,
  isLGCOnboardingUser,
  isomorphicRedirect,
  isRegulatorUser,
} from './securedPageFunctions';

const getDisplayName = ComponentElement =>
  ComponentElement.displayName || ComponentElement.name || 'Component';

const securedPage = WrappedComponent =>
  class WithAuth extends Component {
    static displayName = `withAuthSync(${getDisplayName(WrappedComponent)})`;

    static async getInitialProps({ ctx }: { ctx: MaiiaNextPageContext }) {
      const { query = {}, pathname, store } = ctx;
      const { practitionerId, centerId, next, tsToken, tab } = query;

      const { token, refresh, tokenHash, data } = Auth(ctx);
      const authData: Maybe<AuthData> = store.getState().authentication.item;
      const { operatorId, tlsFirstName, tlsLastName } = parseJwt(token) || {};

      const wrappedComponentProps = async () => ({
        ...(await WrappedComponent.getInitialProps?.({ ctx })),
        token,
      });

      const getRedirectionPathnameByOnboardingType = (nextPathname: string) =>
        isPathWhiteListed(pathname) || pathname === nextPathname
          ? wrappedComponentProps()
          : isomorphicRedirect(nextPathname, ctx);

      let user = store.getState().users.item;

      // A new user will be redirected to the SignUp page
      // until they have verified their identity
      // or unless they want to go to a white-listed URL.
      if (isConnectOnboardingUser(user)) {
        getRedirectionPathnameByOnboardingType(Routes.SIGNUP);
      }

      // A new user who comes from an LGC Onboarding (Callibri)
      // will be redirected to the Subscription page (/subscription/synchro)
      // until the synchronization is properly done
      if (isLGCOnboardingUser(user)) {
        getRedirectionPathnameByOnboardingType(Routes.SIGNUP_LGC_SYNC);
      }

      if (isEmpty(authData) && token) {
        try {
          // can't remove token param from this request
          user = await asyncActions(
            store.dispatch,
            usersActions.getOne('me', { params: { token } }),
          );

          if (
            tsToken &&
            !isPathWhiteListed(pathname) &&
            !user?.userProInformation?.secretaryAccessToken?.includes(tsToken)
          ) {
            handleWrongTSToken(ctx);
          }

          store.dispatch(
            authenticationActions.setItem({
              token,
              refresh,
              operatorId,
              tlsFirstName,
              tlsLastName,
            }),
          );

          store.dispatch(usersActions.setItem(user));

          if (isConnectOnboardingUser(user)) {
            getRedirectionPathnameByOnboardingType(Routes.SIGNUP);
          }

          if (isLGCOnboardingUser(user)) {
            getRedirectionPathnameByOnboardingType(Routes.SIGNUP_LGC_SYNC);
          }
          /*
           * if user is unauthorized to access the chat (like deactivated substitute) -> redirect to home page
           */
          if (isPathAllowedToChat(pathname) && !user?.isFreemium) {
            try {
              await asyncActions(
                store.dispatch,
                chatMessagesActions.getLatest({
                  centerId,
                  params: {
                    token,
                  },
                }),
              );
            } catch (error) {
              if ((error as { status: number }).status === 403) {
                return isomorphicRedirect(Routes.ROOT, ctx);
              }
            }
          }

          if (pathname === Routes.SSO && next && !tsToken && !tokenHash) {
            const nextUrl = buildUrlString(next, {
              targetPractitionerId: ctx.query.targetPractitionerId as string,
              targetCenterId: ctx.query.targetCenterId as string,
            });
            isomorphicRedirect(nextUrl, ctx);
            return {};
          }
          if (pathname === Routes.LOGIN && !tsToken && !tokenHash && !data) {
            const nextRoute =
              next && isNextRouteSafe(next) ? next : Routes.ROOT;
            isomorphicRedirect(nextRoute, ctx);
            return {};
          }
        } catch (error) {
          const loginUrl = buildUrlString(Routes.LOGIN, {
            tsToken,
            next: ctx.asPath,
          });

          if (!isPathWhiteListed(pathname)) {
            isomorphicRedirect(loginUrl, ctx);
            return {};
          }
        }
      }

      const defaultPractitionerId =
        user?.userProInformation?.defaultPractitionerId;
      let center = store.getState().centers.item;

      // Regulators users only have some white listed routes they can access. Otherwise they will be redirected to patients list
      if (isRegulatorUser(user) && !isPathAllowedToRegulator(pathname)) {
        isomorphicRedirect(Routes.PATIENTS, ctx);
        return {};
      }

      // At the 1st connection the lastVisitedChatRoomId is set  by the backend
      const hasLastChatRoomVisited = !!user?.userProInformation?.associatedCenters?.find(
        associatedCenter => associatedCenter.centerId === centerId,
      )?.lastVisitedChatRoomId;

      if (user?.isFreemium && !isConnectOnboardingUser(user)) {
        const target = hasLastChatRoomVisited
          ? Routes.CHAT
          : Routes.CHAT_CREATE_INVITATIONS;

        const isAllowedPath = isPathAllowedToFreemiumUsers(pathname);
        const isAllowedTab = !tab || isTabAllowedToFreemiumUsers(tab);
        if (!isAllowedPath || !isAllowedTab) {
          isomorphicRedirect(target, ctx);
          return {};
        }
      }

      if (
        user?.isFreemium &&
        isFromConnectOnboarding(user) &&
        pathname === Routes.ROOT
      ) {
        const target = hasLastChatRoomVisited
          ? Routes.CHAT
          : Routes.CHAT_CREATE_INVITATIONS;
        isomorphicRedirect(target, ctx);
        return {};
      }

      if (!center && user) {
        const defaultCenterId = find(
          user?.userProInformation?.associatedCenters || [],
          { isDefaultCenter: true },
        )?.centerId;

        try {
          const centerIdOrDefault = centerId || defaultCenterId;
          if (centerIdOrDefault !== undefined) {
            center = await asyncActions(
              store.dispatch,
              centersActions.getOne(centerIdOrDefault, {
                params: { isWithExternalSyncCenters: true },
              }),
            );
          }

          if (center) {
            if (
              isSynchronizationNeeded(center) &&
              pathname !== Routes.SIGNUP_LGC_SYNC &&
              pathname !== Routes.SIGNUP_LGC
            ) {
              isomorphicRedirect(Routes.SIGNUP_LGC_SYNC, ctx);
              return {};
            }

            const offset = getOffset(center);
            store.dispatch(offsetActions.setOffset({ offset }));
            if (
              (!practitionerId && !centerId) ||
              (center.id === centerId && !store.getState().centers.item)
            ) {
              store.dispatch(centersActions.setItem(center));
            }
          }
        } catch (error) {
          // eslint-disable-next-line no-console
          console.log('error', error);
        }
      } else if (center) {
        if (
          isSynchronizationNeeded(center) &&
          pathname !== Routes.SIGNUP_LGC_SYNC &&
          pathname !== Routes.SIGNUP_LGC
        ) {
          isomorphicRedirect(Routes.SIGNUP_LGC_SYNC, ctx);
          return {};
        }
        const offset = getOffset(center);
        if (offset !== store.getState()?.offset?.offset) {
          store.dispatch(offsetActions.setOffset({ offset }));
        }
      }

      const agendaSettingsDefaults = store.getState().agendaSettingsDefault
        .items;

      if (
        defaultPractitionerId &&
        !agendaSettingsDefaults.some(
          a => a.practitionerId === defaultPractitionerId,
        ) &&
        token
      ) {
        store.dispatch(
          agendaSettingsDefaultActions.getList({
            practitionerId: defaultPractitionerId,
            aggregateWith: 'teleconsultationUser',
          }),
        );
      } else if (
        user?.role?.name === 'SUBSTITUTE_PRACTITIONER' &&
        !user.userProInformation?.substitutedPractitioners.every(practitioner =>
          agendaSettingsDefaults.find(
            agendaSettings =>
              practitioner.practitionerId === agendaSettings.practitionerId &&
              practitioner.centerId === agendaSettings.centerId,
          ),
        ) &&
        token
      ) {
        store.dispatch(
          agendaSettingsDefaultActions.getSubstitutedList({
            userId: user.id,
            aggregateWith: 'teleconsultationUser',
          }),
        );
      }

      return wrappedComponentProps();
    }

    render() {
      return <WrappedComponent {...this.props} />;
    }
  };

export default securedPage;
