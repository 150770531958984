import { RefObject, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRouter } from 'next/router';
import { useKeycloak } from '@react-keycloak/ssr';
import { FormikProps } from 'formik';
import cookie from 'js-cookie';
import { KeycloakInstance } from 'keycloak-js';
import get from 'lodash/get';
import omit from 'lodash/omit';
import moment from 'moment';
import { useDebouncedCallback } from 'use-debounce';

import {
  asyncActions,
  DELETE,
  parseJwt,
  serialActions,
  storeActions,
  UPDATE,
  UPLOAD,
} from '@docavenue/core';
import {
  AppointmentGetDTO,
  CenterDTO,
  ContactDTO,
  TimeSlotGetDTO,
} from '@maiia/model/generated/model/api-pro/api-pro';

import { useAnalyticsContext } from '../../components/contexts';
import { RadioCancelReasonItem } from '../../components/metaOrganisms/forms/Appointment/containers/RadioCancelReason';
import { useTranslation } from '../../i18n';
import { Document } from '../../types/pro.types';
import {
  acknowledgeDocumentsActions,
  appointmentsActions,
  appointmentsTlcActions,
  authenticationActions,
  complexFormActions,
  contactsActions,
  documentsActions,
  documentsHistoryActions,
  loaderMaiiaActions,
  logoutActions,
  patientsActions,
  paymentsActions,
  snacksActions,
  timeSlotsActions,
  usersActions,
  videoSessionsActions,
} from '../actions';
import confirmLogoutActions, {
  ConfirmLogoutMode,
} from '../actions/confirmLogout';
import { login } from '../auth';
import {
  PAYMENT_TYPE_DEFAULT,
  PAYMENT_TYPE_FREE,
  PAYMENT_TYPE_LATER,
  PAYMENT_TYPE_OTHER,
  PENDING,
  PRO,
  STARTED,
  TO_CAPTURE,
  WAITING,
  WEB_PRO,
} from '../constants';
import Routes from '../constants/routes';
import {
  GAActions,
  GAActionStatus,
  GAAuthenticationActions,
  GACategories,
} from '../constants/tracking';
import { RootState } from '../reducer/rootState';
import { SnackActionType } from '../reducer/snacks';
import {
  clearAppointmentConsultationReasonIfNotActive,
  getListPeriod,
  getPeriod,
  unpack,
} from '../utils';
import { convertCenterDTOToCenter } from '../utils/centerUtils';
import { buildUrlString } from '../utils/RoutesUtils';
import { useNextParameterFromUrl } from './query';
import {
  useCenterId,
  useCurrentUser,
  useCurrentVideoSession,
  useIsDefaultOfferTeleconsultation,
  useIsKeycloakEnabled,
  usePatient,
  usePatientId,
  usePractitionerId,
  useTlcCenterAmc,
  useTlcConflict,
  useVideoSessions,
} from './selectors';
import { useAnalyticsTracker, useInterval } from './utils';

import { Actions } from '@/src/actions/actionsTypes';
import { screebIdentityReset } from '@/src/screeb';

// START ACTIONS

export const useLoginAction = () => {
  const nextParameter = useNextParameterFromUrl();
  const dispatch = useDispatch();
  const router = useRouter();
  const triggerAnalyticsEvent = useAnalyticsTracker(
    GACategories.Authentication,
  );
  const { query } = router;
  const previousTLCAPatientUrl = query?.redirectUrl as string;
  const urlParams = new URLSearchParams(previousTLCAPatientUrl);
  const previousTLCAPatientId = urlParams.get('patientId');

  const redirectToPreviousTLCAPatient = async (
    patientId: string,
    center: CenterDTO,
  ) => {
    try {
      await asyncActions(dispatch, patientsActions.getOne(patientId));
      login({
        next: previousTLCAPatientUrl,
        center,
      });
    } catch (error) {
      login({
        next: nextParameter,
        center,
      });
      console.error(error);
    }
  };

  return (values: {
    tokenHash?: string;
    hash?: string;
    data?: string;
    tsToken?: string;
    uaaToken?: string;
    tlsFirstName?: string;
    tlsLastName?: string;
    securityCode?: string;
    username?: string;
    password?: string;
    deviceToken?: string;
    mfaToken?: string;
    oneTimePIN?: string;
    shouldRememberDevice?: boolean;
  }) =>
    serialActions.serial(
      [
        () => {
          cookie.remove('tokenPro');
          cookie.remove('refreshPro');
          return storeActions.resetStore({
            toKeepStates: ['loaderMaiia', 'websocket', 'authentication'],
          });
        },
        () => {
          let action = authenticationActions.create;
          if (values?.uaaToken) {
            action = authenticationActions.createUAA;
          } else if (values?.mfaToken) {
            action = authenticationActions.createMFA;
          }
          return action?.(
            {
              ...values,
              applicationType: WEB_PRO,
              type: PRO,
            },
            {
              isListDst: false,
            },
          );
        },
        () => usersActions.getOne('me'),
        (state: RootState) => {
          const token = state.authentication?.item?.token;
          if (token) {
            const center = state.centers?.item!;
            if (previousTLCAPatientId) {
              redirectToPreviousTLCAPatient(previousTLCAPatientId, center);
            } else {
              login({
                next: nextParameter,
                center,
              });
            }
          }
        },
        () => {
          if (values.shouldRememberDevice) {
            triggerAnalyticsEvent(GAAuthenticationActions.ClickRememberDevice);
          }
        },
        () => {
          if (values.oneTimePIN) {
            triggerAnalyticsEvent(
              GAAuthenticationActions.SuccessMultiFactorAuthentication,
            );
          } else {
            triggerAnalyticsEvent(
              GAAuthenticationActions.SuccessWithoutMultiFactorAuthentication,
            );
          }
        },
      ],
      true,
    );
};

export const useAutoLoginAction = () => {
  const dispatch = useDispatch();
  const nextParameter = useNextParameterFromUrl();

  return (autoLogin: {
    tokenHash?: string;
    hash?: string;
    data?: string;
    tsToken?: string;
  }) =>
    serialActions.serial(
      [
        () => {
          cookie.remove('tokenPro');
          cookie.remove('refreshPro');

          return storeActions.resetStore({
            toKeepStates: ['websocket'],
          });
        },
        () => {
          if (autoLogin?.tsToken) return serialActions.break();
          return serialActions.continue();
        },
        () => loaderMaiiaActions.setOpen(true),
        () =>
          authenticationActions.create(autoLogin, {
            isListDst: false,
          }),
        (state: RootState) => {
          const error = state.authentication?.error;
          if (error) {
            const actions = [
              () => authenticationActions.resetError(),
              () => loaderMaiiaActions.setOpen(false),
            ];
            dispatch(serialActions.serial(actions));
            return serialActions.break();
          }
          return serialActions.continue();
        },
        (state: RootState) => {
          const token = get(state, 'authentication.item.token');
          const operatorId = parseJwt(token)?.operatorId;
          return dispatch(
            authenticationActions.setItem({
              ...state.authentication.item,
              operatorId,
            }),
          );
        },
        () => usersActions.getOne('me'),
        (state: RootState) => {
          const center = state.centers?.item;
          login({
            next: nextParameter,
            center,
          });
        },
      ],
      false,
    );
};

export const useLogoutActions = (redirectUrl?: string) => {
  const { keycloak } = useKeycloak<KeycloakInstance>();
  const isKeycloakEnabled = useIsKeycloakEnabled();
  const loginUrl = redirectUrl?.length
    ? buildUrlString(Routes.LOGIN, {
        redirectUrl,
      })
    : Routes.LOGIN;

  return [
    () => logoutActions.create(null),
    // @ts-ignore
    () => {
      if (keycloak?.authenticated && isKeycloakEnabled) {
        keycloak?.logout({
          redirectUri: `${window?.location?.origin}${Routes.LOGIN}`,
        });
      } else {
        window.location.href = loginUrl;
      }
    },
  ];
};

export const useOpenComplexForm = () => {
  const dispatch = useDispatch();
  const { push, query, pathname } = useRouter() || {};
  const isComplexFormPath = pathname?.includes(Routes.CREATE_APPOINTMENT);

  return useCallback(() => {
    dispatch(
      serialActions.serial([
        () => {
          if (!isComplexFormPath) {
            return complexFormActions.setPreviousPath({
              pathname,
              query,
            });
          }
        },
        () => {
          const { patientId, practitionerId, rootCenterId, centerId } = query;
          push({
            pathname: Routes.CREATE_APPOINTMENT,
            query: {
              practitionerId,
              patientId,
              rootCenterId,
              centerId,
            },
          });
        },
      ]),
    );
  }, [query, pathname]);
};

export const useEditAppointmentInComplexFormAction = () => {
  const openComplexForm = useOpenComplexForm();
  const centerId = useCenterId();

  return (appointment?: AppointmentGetDTO) => {
    return serialActions.serial([
      () => {
        if (appointment?.patient) {
          // Get full patient data instead of the partial object saved in the appointment
          return patientsActions.getOne(appointment.patient.id, {
            params: { centerId },
          });
        }
        return serialActions.continue();
      },
      (state: RootState) => {
        if (!appointment) {
          return;
        }
        return complexFormActions.setItem(
          clearAppointmentConsultationReasonIfNotActive({
            ...appointment,
            center: state.centers.item
              ? convertCenterDTOToCenter(state.centers.item)
              : undefined,
          }),
        );
      },
      () => complexFormActions.setWaitingList(!!appointment?.waitingListOwner),
      openComplexForm,
    ]);
  };
};

export const useReplanAppointmentActions = () => {
  const openComplexForm = useOpenComplexForm();
  return (appointment: AppointmentGetDTO) =>
    serialActions.serial([
      () => {
        if (appointment.patient) {
          return patientsActions.setItem(appointment.patient);
        }
        return serialActions.continue();
      },
      () =>
        complexFormActions.setItem({
          appointmentIdToDelete: appointment.id,
          ...omit(clearAppointmentConsultationReasonIfNotActive(appointment), [
            'id',
            'creationDate',
            'appointmentStatus',
          ]),
        }),
      openComplexForm,
      () => appointmentsActions.setItem(null),
    ]);
};

export const useGetTimeSlotByDate = () => {
  const practitionerId = usePractitionerId();
  const centerId = useCenterId();
  const router = useRouter();
  const date = unpack(router.query.date);
  const dispatch = useDispatch();
  const agendaSettings = useSelector(state => state.agendaSettings.item);

  const getTimeSlotByDate = useDebouncedCallback(() => {
    if (practitionerId && centerId) {
      const { from, to } = getPeriod(date);
      const timeSlotsParams = {
        centerId,
        from: from.toISOString(),
        limit: 2500,
        practitionerId,
        to: to.toISOString(),
      };
      dispatch(
        serialActions.serial([
          () =>
            timeSlotsActions.getList(timeSlotsParams, {
              currentDate: from.toISOString(),
            }),
          (state: RootState) => {
            const newActions = getListPeriod<TimeSlotGetDTO>(
              state.timeSlots,
              timeSlotsActions,
              timeSlotsParams,
            );
            return serialActions.serial(newActions);
          },
        ]),
      );
    }
  }, 100);

  useEffect(() => {
    if (
      agendaSettings?.practitionerId === practitionerId &&
      agendaSettings?.centerId === centerId
    ) {
      getTimeSlotByDate();
    }
  }, [date, centerId, practitionerId, getTimeSlotByDate, agendaSettings]);

  return getTimeSlotByDate;
};

export const useTlcChargeCallback = () => {
  const dispatch = useDispatch();
  const item = useSelector((state: RootState) => state.appointmentsTlc.item);
  const { id } = item || {};
  const getChargeAction = useCallback(
    (
      paymentType:
        | typeof PAYMENT_TYPE_FREE
        | typeof PAYMENT_TYPE_DEFAULT
        | typeof PAYMENT_TYPE_OTHER
        | typeof PAYMENT_TYPE_LATER,
      otherAmount: number,
    ) => {
      if (!id) return [];
      switch (paymentType) {
        case PAYMENT_TYPE_FREE: {
          return [
            () =>
              paymentsActions.create({
                amountToCapture: 0,
                appointmentId: id,
              }),
            () => appointmentsTlcActions.getOne(id, { target: 'list' }),
          ];
        }

        case PAYMENT_TYPE_DEFAULT: {
          return [
            () =>
              paymentsActions.create({
                amountToCapture:
                  item?.paymentInformation?.maxTeleconsultationPrice,
                appointmentId: id,
              }),
            () => appointmentsTlcActions.getOne(id, { target: 'list' }),
          ];
        }

        case PAYMENT_TYPE_OTHER: {
          return [
            () =>
              paymentsActions.create({
                amountToCapture: otherAmount,
                appointmentId: id,
              }),
            () => appointmentsTlcActions.getOne(id, { target: 'list' }),
          ];
        }

        case PAYMENT_TYPE_LATER: {
          return [
            () =>
              appointmentsTlcActions.updateOne({
                ...item,
                paymentInformation: {
                  ...(item?.paymentInformation || {}),
                  payStatus: TO_CAPTURE,
                },
              }),
          ];
        }

        default: {
          console.warn(
            `Expected a payment type (i.e. PAYMENT_TYPE_FREE, PAYMENT_TYPE_DEFAULT, PAYMENT_TYPE_OTHER, or PAYMENT_TYPE_LATER), but got: ${paymentType}`,
          );
          return [];
        }
      }
    },
    [dispatch, id, item],
  );

  return getChargeAction;
};

export const useResetRessourceError = (
  ressourceActions: { resetError: () => { type: string; resource: string } },
  deps: any[],
) => {
  const dispatch = useDispatch();
  useEffect(
    () => () => {
      dispatch(ressourceActions.resetError());
    },
    deps,
  );
};

export const useResetFormErrors = <T extends {}>(
  formRef: RefObject<FormikProps<T>>,
  deps: any[],
) => {
  useEffect(() => {
    if (formRef.current) formRef.current.setErrors({});
  }, deps);
};

export const useIntervalFetchVideoSessions = () => {
  const centerId = useCenterId();
  const defaultPractitionerId = useSelector(
    state =>
      state.agendaSettingsDefault.items.find(item => item.centerId === centerId)
        ?.practitionerId,
  );
  // Handle TLC Center / AMC conflict when two practitioners take simultaneously the same call
  const hasErrorTlcConflict = useTlcConflict();
  const isTlc = useIsDefaultOfferTeleconsultation();
  const { isTlcCenterOrAmcAndHasCenterId } = useTlcCenterAmc();
  const dispatch = useDispatch();
  const delay = 30000;
  useInterval(() => {
    if (isTlc && defaultPractitionerId && !hasErrorTlcConflict) {
      dispatch(
        videoSessionsActions.getList({
          ...(isTlcCenterOrAmcAndHasCenterId && { centerId }),
          practitionerId: defaultPractitionerId,
          statuses: [PENDING, WAITING, STARTED].join(','),
          aggregateWith: 'patient,consultationReason,teleconsultationRelay',
        }),
      );
    }
  }, delay);
};

export const useAcknowledgeDocumentsHistoryByDocumentId = () => {
  const dispatch = useDispatch();
  const centerId = useCenterId();
  return (documentId: string, isSeen: boolean) => {
    dispatch(
      serialActions.serial([
        () =>
          acknowledgeDocumentsActions.acknowledgeByDocumentId(
            {
              documentId,
            },
            { params: { isSeen, centerId } },
          ),
        ({ documentsHistory: { items } }) => {
          const currentDocuments = items.map(item =>
            item.id === documentId ? { ...item, isSeen } : item,
          );

          return documentsHistoryActions.setItems(currentDocuments);
        },
      ]),
    );
  };
};

export const useAcknowledgeDocumentsByDocumentId = () => {
  const dispatch = useDispatch();
  const centerId = useCenterId();
  return (documentId: string, isSeen: boolean) => {
    dispatch(
      serialActions.serial([
        () =>
          acknowledgeDocumentsActions.acknowledgeByDocumentId(
            {
              documentId,
            },
            { params: { isSeen, centerId } },
          ),
        ({ documents: { items } }) => {
          const currentDocuments = items.map(item =>
            item.id === documentId ? { ...item, isSeen } : item,
          );

          return documentsActions.setItems(currentDocuments);
        },
      ]),
    );
  };
};

function returnActionByType<T>(actions: Actions<T>, type: string) {
  switch (type) {
    case 'DELETE_MANY':
      return actions.deleteManyPost;
    case DELETE:
      return actions.delete;
    case 'UPDATE_MANY':
    case 'CANCEL_MANY':
      return actions.updateManyPost;
    case UPDATE:
    case 'CANCEL':
      return actions.updateOne;
    default:
      return undefined;
  }
}

type HandleSubmit<T> = (
  values: T,
  options?: {
    chunkResource?: string;
    params: {
      aggregateWith?: string;
      centerId?: string;
      practitionerId?: string;
      isAgendaDisplaySettings?: boolean;
      recurrentAbsenceUniqueId?: string;
      substituteUserId?: string;
      cancelAction?: Actions<T>;
      isPunctual?: boolean;
      cancelReason?: RadioCancelReasonItem['value'];
    };
  },
  onSubmit?: () => void,
  onSuccess?: () => void,
) => void;
type Params = {
  isNotification?: boolean;
};

export function useResourceContainer<T = any>(
  actions: any,
  type: SnackActionType,
  params: Params = {
    isNotification: true,
  },
): [HandleSubmit<T>, boolean] {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  const handleSubmit: HandleSubmit<T> = (
    values,
    options,
    onSubmit,
    onSuccess?: () => void,
  ) => {
    const hasCancelAction = !!options?.params?.cancelAction;
    setIsLoading(true);

    const _serialActions = serialActions.serial([
      () => returnActionByType(actions, type)?.(values as any, options),
      () => onSubmit?.(),
      () => setIsLoading(false),
      () =>
        params.isNotification &&
        snacksActions.enqueueSnack({
          action: type,
          resource: actions.resource.toLowerCase(),
          variant: 'info',
          actionNode: hasCancelAction ? t('cancel') : undefined,
          onClick: () =>
            hasCancelAction ? options.params.cancelAction : undefined,
        }),
    ]);

    if (typeof onSuccess === 'function') {
      _serialActions.onSuccess = onSuccess;
    }

    dispatch(_serialActions);
  };

  return [handleSubmit, isLoading];
}

export const useUploadPatientDocument = () => {
  const dispatch = useDispatch();
  const videoSession = useSelector(state => state.videoSessions.item);
  const videoSessionId = videoSession?.id;
  const { pathname } = useRouter() || {};
  const patientId = usePatientId();
  const centerId = useCenterId();
  const { t } = useTranslation();
  const profileId = usePatient()?.profileId;

  const { trackUserEvent } = useAnalyticsContext();

  return (
    share,
    file,
    category,
    document,
    callback,
    successCallBack: (document: Document) => void = () => {},
  ) => {
    const isVideoSession =
      videoSessionId &&
      share &&
      pathname === '/tlc' &&
      videoSession?.patientId === patientId;

    let action;
    if (isVideoSession) {
      const data: any = {
        file,
        category,
      };
      if (document.prescription) {
        data.prescription = JSON.stringify(document.prescription);
      }
      action = videoSessionsActions.upload(data, {
        chunkId: videoSessionId,
        chunkResource: 'documents',
      });
    } else {
      action = documentsActions.upload(
        {
          // @ts-ignore
          document: JSON.stringify(document),
          file,
        },
        { params: { centerId } },
      );
      // This is useful when generating docs at the end of a tLC
      action.onSuccess = doc => {
        if (pathname === Routes.TLC && doc.id) {
          dispatch(videoSessionsActions.setSharedDocuments(doc));
        }
        successCallBack(doc);
      };
    }

    const _serialActions = serialActions.serial([
      () => documentsActions.resetError(),
      () => action,
      () =>
        documentsActions.getList({
          patientId,
          centerId,
          direction: 'desc',
          sort: 'creationDate',
        }),
      () => {
        const getMessage = () => {
          if (share) {
            return t('common:document_upload_success_uploaded_and_shared');
          }
          return profileId
            ? t('common:document_upload_success_uploaded')
            : t('common:document_upload_success_notsharable');
        };
        return snacksActions.enqueueSnack(
          videoSessionId && !share
            ? {
                action: UPLOAD,
                resource: 'documents',
                variant: 'success',
              }
            : {
                variant: 'success',
                message: getMessage(),
              },
        );
      },
      () => callback?.(),
    ]);

    _serialActions.onSuccess = () =>
      trackUserEvent?.(GAActions.ClickSendDocument, GAActionStatus.SUCCESS);

    _serialActions.onError = () =>
      trackUserEvent?.(GAActions.ClickSendDocument, GAActionStatus.ERROR);

    dispatch(_serialActions);
  };
};

export const useSendMailContact = ({
  messageMail,
  objectMail,
  onSuccess,
  onError,
  contactReason,
}: {
  messageMail: string;
  objectMail: string;
  onSuccess?: () => void;
  onError?: () => void;
  contactReason?: ContactDTO['contactReason'];
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [isSuccess, setIsSuccess] = useState(false);
  const centerName = useSelector(state => state.centers.item?.name);
  const user = useCurrentUser();
  const error = useSelector(state => state.contacts.error);

  const successMessage = t('common:header_need_contact_tlc_mail_success');
  const errorMessage = `${t('common:header_need_contact_tlc_mail_error')} ${t(
    'common:error_contact_support',
    {
      supportPhoneNumber: t('common:support_phone_number'),
    },
  )}`;
  const lastName = user?.userProInformation?.lastName;
  const firstName = user?.userProInformation?.firstName;

  useEffect(
    () => () => {
      dispatch(contactsActions.resetError());
    },
    [],
  );

  const sendMail = () => {
    const mail = {
      name: lastName,
      firstName,
      mail: user?.username,
      mailTo: 'pro',
      object: t(objectMail),
      message: t(messageMail, {
        lastName,
        firstName,
        centerName,
      }),
      ...(!!contactReason && { contactReason }),
    };

    const _serialActions = serialActions.serial([
      () => contactsActions.resetError(),
      () => contactsActions.create(mail),
      () => setIsSuccess(true),
    ]);

    _serialActions.onSuccess = onSuccess;
    _serialActions.onError = onError;

    dispatch(_serialActions);
  };

  return {
    sendMail,
    error: error && errorMessage,
    message: isSuccess && successMessage,
  };
};

export const useDisconnect = ({
  callback,
  agendaSettings,
  redirectUrl = '',
}) => {
  const dispatch = useDispatch();
  const currentVideoSession = useCurrentVideoSession();
  const videoSessions = useVideoSessions();
  const allLogoutActions = useLogoutActions(redirectUrl);
  const confirmLogout = (mode: ConfirmLogoutMode) =>
    dispatch(
      serialActions.serial([
        () => confirmLogoutActions.openDialog({ mode }),
        () => {
          screebIdentityReset();
          callback();
        },
      ]),
    );

  const disconnect = () => {
    if (currentVideoSession?.videoSessionStatus === STARTED) {
      confirmLogout(ConfirmLogoutMode.ONGOING_TLC);
    } else if (
      agendaSettings?.teleconsultationAvailabilityStartTime &&
      agendaSettings?.teleconsultationAvailabilityEndTime &&
      moment().isBefore(agendaSettings?.teleconsultationAvailabilityEndTime)
    ) {
      if (
        videoSessions.some(session => session.videoSessionStatus === WAITING)
      ) {
        confirmLogout(ConfirmLogoutMode.WAITING_ROOM_NOT_EMPTY);
      } else {
        confirmLogout(ConfirmLogoutMode.WAITING_ROOM_EMPTY);
      }
    } else {
      dispatch(serialActions.serial(allLogoutActions));
    }
  };

  return disconnect;
};
