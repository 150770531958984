/* eslint-disable import/no-cycle */
import omit from 'lodash/omit';
import moment, { Moment } from 'moment-timezone';

import {
  CREATE_MANY_SUCCESS,
  CREATE_SUCCESS,
  crudInitialDefaultState,
  DELETE_SUCCESS,
  GET_LIST_SUCCESS,
  GET_ONE_SUCCESS,
  reducerConfigGenerator,
  reducersGenerator,
  SET_ITEMS,
  UPDATE_SUCCESS,
  UPLOAD_MANY_SUCCESS,
  UPLOAD_SUCCESS,
} from '@docavenue/core';

import { ItemsDictMapAction } from '../actions/actionsTypes';
import {
  ItemsDict,
  ItemsDictMap,
  ItemsDictMapReducer,
  ItemsDictMapState,
} from './reducersTypes';

import { WEEKS_AFTER, WEEKS_BEFORE } from '@/components/utils/constants';

const NB_MAX_ITEMS_DICT = 6;

export const agendaKeyFormat = (practitionerId: string, centerId: string) =>
  `${practitionerId}-${centerId}`;

const currentWeek = moment()
  .startOf('isoWeek')
  .toISOString();

export const initialStateItemsDict = {
  ...crudInitialDefaultState,
  currentDate: null,
  itemsDictMap: {},
  latestDeleted: null,
};

const getRange = (currentDate: Moment) => [
  currentDate
    .clone()
    .subtract(WEEKS_BEFORE, 'weeks')
    .toISOString(),
  currentDate
    .clone()
    .add(WEEKS_AFTER, 'weeks')
    .endOf('isoWeek')
    .toISOString(),
];

export const keysOptionsDefault = <const>{
  getPrimaryKey: ({
    practitionerId,
    centerId,
  }: {
    practitionerId: string;
    centerId: string;
  }): string => agendaKeyFormat(practitionerId, centerId),
  getSecondaryKey: (
    o:
      | {
          startDate: string;
        }
      | {
          startDateTime: string;
        },
  ): string =>
    moment('startDateTime' in o ? o.startDateTime : o.startDate)
      .startOf('isoWeek')
      .toISOString(),
  initialProperties: {},
};

export const getItemsFromKey = <T>(
  itemsDict: ItemsDict<T>,
  secondaryKey: string,
) => itemsDict[secondaryKey] || [];

export const assignItemsDictForPrimaryKey = <T, InitProperties extends {} = {}>(
  itemsDictMap: ItemsDictMap<T, InitProperties>,
  itemsDict: ItemsDict<T>,
  primaryKey: string,
) => {
  // eslint-disable-next-line no-param-reassign
  itemsDictMap[primaryKey] = {
    ...itemsDictMap[primaryKey],
    itemsDict,
    lastUpdatedDate: new Date().getTime(),
  };
  return itemsDictMap;
};

export const assignItemsForSecondaryKey = <T>(
  itemsDict: ItemsDict<T>,
  items: T[],
  secondaryKey: string,
) => {
  // if (items.length) {
  // eslint-disable-next-line no-param-reassign
  itemsDict[secondaryKey] = items;
  // }
  // chatMessages depends on?
  // else {
  //   // eslint-disable-next-line no-param-reassign
  //   delete itemsDict[secondaryKey];
  // }
  return itemsDict;
};

export const initIfEmptyForPrimaryKey = <T, InitProperties extends {} = {}>(
  itemsDictMap: ItemsDictMap<T>,
  initialProperties: InitProperties,
  primaryKey?: string,
) => {
  if (primaryKey && !itemsDictMap[primaryKey]) {
    // eslint-disable-next-line no-param-reassign
    itemsDictMap[primaryKey] = {
      latestDeleted: null,
      lastUpdatedDate: 0,
      itemsDict: {},
      ...initialProperties,
    };
  }
  return itemsDictMap;
};

export type KeysOptions<T extends {}, InitProperties extends {} = {}> = {
  getPrimaryKey: (o: T) => string | undefined;
  getSecondaryKey: (o: T) => string | undefined;
  initialProperties?: InitProperties;
};

const createSurchargeReducerItemsDict = <
  T extends U & { id?: string },
  U extends {},
  InitState extends {} = {},
  InitProperties extends {} = {}
>(
  name: string,
  keysOptions: KeysOptions<U, InitProperties>,
) => {
  const surchargeReducer = () => {
    const reducer: any = {};
    const {
      getPrimaryKey,
      getSecondaryKey,
      initialProperties = {},
    } = keysOptions;

    [GET_ONE_SUCCESS, `${GET_ONE_SUCCESS}/${name}`].forEach(actionType => {
      reducer[actionType] = (
        state: ItemsDictMapState<T, InitState, InitProperties>,
        action: ItemsDictMapAction<T> & { isWithFilter?: boolean },
      ) => {
        const { target, payload, isWithFilter } = action;
        const merged: any = {};
        const primaryKey = getPrimaryKey(payload);
        const secondaryKey = getSecondaryKey(payload);
        if (target !== 'list') {
          merged.item = payload;
        } else if (primaryKey && secondaryKey) {
          const { itemsDictMap } = state;
          initIfEmptyForPrimaryKey<T>(
            itemsDictMap,
            initialProperties,
            primaryKey,
          );

          const { itemsDict } = itemsDictMap[primaryKey];
          if (isWithFilter) {
            const { id } = payload;
            Object.keys(itemsDict).forEach((itemKey: string) => {
              const items = getItemsFromKey<T>(itemsDict, itemKey);
              const newItems = getItemsFromKey<T>(itemsDict, itemKey).filter(
                item => item.id !== id,
              );
              if (newItems.length !== items.length)
                assignItemsForSecondaryKey(itemsDict, newItems, itemKey);
            });
          }

          const items = getItemsFromKey<T>(itemsDict, secondaryKey);
          const index = items.findIndex(item => item.id === payload.id);

          if (index === -1) {
            assignItemsForSecondaryKey(
              itemsDict,
              [...items, payload],
              secondaryKey,
            );
          } else {
            items[index] = payload;
            assignItemsForSecondaryKey(itemsDict, [...items], secondaryKey);
          }

          merged.itemsDictMap = {
            ...itemsDictMap,
            [primaryKey]: { ...itemsDictMap[primaryKey], itemsDict },
          };
        }

        return {
          ...state,
          ...merged,
          isGetOneLoading: false,
          error: null,
        };
      };
    });

    reducer[SET_ITEMS] = (
      state: ItemsDictMapState<T, InitState, InitProperties>,
      action: ItemsDictMapAction<{ items: T[] }> & {
        primaryKey: string;
        secondaryKey: string;
      },
    ) => {
      const { itemsDictMap } = state;
      const {
        payload: { items },
        primaryKey,
        secondaryKey,
      } = action;
      initIfEmptyForPrimaryKey<T>(itemsDictMap, initialProperties, primaryKey);

      const { itemsDict } = itemsDictMap[primaryKey];
      const newItemsDict = {
        ...assignItemsForSecondaryKey(itemsDict, items, secondaryKey),
      };
      return {
        ...state,
        itemsDictMap: {
          ...assignItemsDictForPrimaryKey<T, InitProperties>(
            itemsDictMap,
            newItemsDict,
            primaryKey,
          ),
        },
      };
    };

    reducer[UPDATE_SUCCESS] = (
      state: ItemsDictMapState<T, InitState, InitProperties>,
      action: ItemsDictMapAction<T>,
    ) => {
      const { itemsDictMap } = state;
      const { payload } = action;
      const { id } = payload;
      const primaryKey = getPrimaryKey(payload);

      if (!primaryKey) {
        return state;
      }

      initIfEmptyForPrimaryKey<T>(itemsDictMap, initialProperties, primaryKey);

      const stateItem = state.item?.id === id ? { ...payload } : state.item;
      const { itemsDict } = itemsDictMap[primaryKey];
      const newItemsDict = {};
      Object.keys(itemsDict).forEach((itemKey: string) => {
        const items = getItemsFromKey<T>(itemsDict, itemKey).map(item => {
          if (item.id === id) {
            return { ...payload };
          }
          return item;
        });
        assignItemsForSecondaryKey(newItemsDict, items, itemKey);
      });
      return {
        ...state,
        itemsDictMap: {
          ...assignItemsDictForPrimaryKey<T, InitProperties>(
            itemsDictMap,
            newItemsDict,
            primaryKey,
          ),
        },
        item: stateItem,
        isLoading: false,
        error: null,
      };
    };

    reducer[DELETE_SUCCESS] = (
      state: ItemsDictMapState<T, InitState, InitProperties>,
      action: ItemsDictMapAction<Pick<T, 'id'>, { params: U }>,
    ) => {
      const { itemsDictMap } = state;
      const {
        payload: { id },
        query: { params },
      } = action;
      const primaryKey = getPrimaryKey(params);

      if (!primaryKey) {
        return state;
      }

      initIfEmptyForPrimaryKey<T>(itemsDictMap, initialProperties, primaryKey);

      const newItem = !state.item || state.item?.id === id ? null : state.item;
      const itemsDict = itemsDictMap[primaryKey]?.itemsDict || {};
      Object.keys(itemsDict).forEach((itemKey: string) => {
        const items = getItemsFromKey<T>(itemsDict, itemKey).filter(item => {
          if (item.id === id) {
            itemsDictMap[primaryKey].latestDeleted = item;
          }
          return item.id !== id;
        });
        if (getItemsFromKey<T>(itemsDict, itemKey).length !== items.length) {
          assignItemsForSecondaryKey(itemsDict, items, itemKey);
        }
      });
      return {
        ...state,
        itemsDictMap: {
          ...assignItemsDictForPrimaryKey<T, InitProperties>(
            itemsDictMap,
            itemsDict,
            primaryKey,
          ),
        },
        item: newItem,
        isLoading: false,
        latestDeleted: itemsDictMap[primaryKey].latestDeleted || params,
        error: null,
      };
    };

    [CREATE_SUCCESS, UPLOAD_SUCCESS, UPLOAD_MANY_SUCCESS].forEach(type => {
      reducer[type] = (
        state: ItemsDictMapState<T, InitState, InitProperties>,
        action: ItemsDictMapAction<T> & {
          isListDst: boolean;
        },
      ) => {
        const { itemsDictMap } = state;
        const { isListDst, payload } = action;
        const primaryKey = getPrimaryKey(payload);
        const secondaryKey = getSecondaryKey(payload);

        if (!primaryKey) {
          return state;
        }

        initIfEmptyForPrimaryKey<T>(
          itemsDictMap,
          initialProperties,
          primaryKey,
        );
        const { itemsDict } = itemsDictMap[primaryKey];
        const merged: { item?: Object } = {};
        if (!isListDst) {
          merged.item = { ...payload, error: null };
        } else if (isListDst && secondaryKey) {
          // WARNING: the item can be already present if the getOne triger by ws come before the post return
          const items = getItemsFromKey<T>(itemsDict, secondaryKey);
          const index = items.findIndex(item => item.id === payload.id);
          if (index === -1) {
            assignItemsForSecondaryKey(
              itemsDict,
              [...items, action.payload],
              secondaryKey,
            );
          } else {
            items[index] = payload;
            assignItemsForSecondaryKey(itemsDict, [...items], secondaryKey);
          }
        }

        return {
          ...state,
          ...merged,
          itemsDictMap: {
            ...assignItemsDictForPrimaryKey<T, InitProperties>(
              itemsDictMap,
              itemsDict,
              primaryKey,
            ),
          },
          isLoading: false,
          error: null,
        };
      };
    });

    reducer[CREATE_MANY_SUCCESS] = (
      state: ItemsDictMapState<T, InitState, InitProperties>,
      action: ItemsDictMapAction<T[]>,
    ) => {
      const { itemsDictMap: itemsDictMapState } = state;
      const { payload } = action;
      let itemsDictMap = itemsDictMapState;
      payload.forEach((element: T) => {
        const primaryKey = getPrimaryKey(element);

        if (!primaryKey) return;

        initIfEmptyForPrimaryKey<T>(
          itemsDictMap,
          initialProperties,
          primaryKey,
        );
        const { itemsDict } = itemsDictMap[primaryKey];
        const secondaryKey = getSecondaryKey(element);

        if (!secondaryKey) return;

        // WARNING: the item can be already present if the getOne triger by ws come before the post return
        const items = getItemsFromKey<T>(itemsDict, secondaryKey);
        const index = items.findIndex(item => item.id === element.id);
        if (index === -1) {
          assignItemsForSecondaryKey(
            itemsDict,
            [...items, element],
            secondaryKey,
          );
        } else {
          items[index] = element;
          assignItemsForSecondaryKey(itemsDict, [...items], secondaryKey);
        }
        itemsDictMap = {
          ...assignItemsDictForPrimaryKey<T, InitProperties>(
            itemsDictMap,
            itemsDict,
            primaryKey,
          ),
        };
      });

      return {
        ...state,
        itemsDictMap,
        isLoading: false,
        error: null,
      };
    };

    [GET_LIST_SUCCESS, `${GET_LIST_SUCCESS}/${name}`].forEach(actionType => {
      reducer[actionType] = (
        state: ItemsDictMapState<T, InitState, InitProperties>,
        action: ItemsDictMapAction<
          { items: T[]; total: number },
          U & { from: string }
        > & {
          isReplace: boolean;
          currentDate: Moment;
          chunkResource: string;
        },
      ) => {
        const { itemsDictMap } = state;
        const {
          isReplace,
          payload,
          currentDate,
          query,
          chunkResource,
        } = action;
        const { from } = query;
        const { items: itemsPayload, total } = payload;
        // const { items: newItems, total } = payload;
        // WARNING chunkResource CAN'T be the method trigger
        const data: any[] = chunkResource
          ? [...itemsPayload]
          : [
              {
                // @ts-ignore
                practitionerId: query?.practitionerId,
                // @ts-ignore
                centerId: query?.centerId,
                items: itemsPayload,
              },
            ];
        const updatedObject = {
          itemsDictMap: { ...itemsDictMap },
          total,
          isLoading: false,
          error: null,
          currentDate: state.currentDate,
        };
        const currentItemsDict = Object.keys(
          omit(itemsDictMap, 'latestDeleted'),
        );

        const newDataCount = data.filter(
          o => !currentItemsDict.includes(`${o.practitionerId}-${o.centerId}`),
        ).length;

        if (currentItemsDict.length + newDataCount > NB_MAX_ITEMS_DICT) {
          let nbToKeep = NB_MAX_ITEMS_DICT - newDataCount;
          if (nbToKeep < 0) nbToKeep = 0;
          if (!nbToKeep)
            updatedObject.itemsDictMap = {
              latestDeleted: itemsDictMap.latestDeleted,
            };
          else {
            nbToKeep =
              nbToKeep > currentItemsDict.length
                ? currentItemsDict.length
                : nbToKeep;
            const arr = currentItemsDict
              .map(k => ({ k, d: itemsDictMap[k].lastUpdatedDate }))
              .sort((a, b) => b.d - a.d)
              .map(k => k.k)
              .slice(0, nbToKeep);

            updatedObject.itemsDictMap = {
              ...currentItemsDict
                .filter(key => arr.includes(key))
                .reduce((obj, key) => {
                  // @ts-ignore
                  // eslint-disable-next-line no-param-reassign
                  obj[key] = updatedObject.itemsDictMap[key];
                  return obj;
                }, {}),
              latestDeleted: updatedObject.itemsDictMap.latestDeleted,
            };
          }
        }
        // @ts-ignore
        data.forEach(({ practitionerId, centerId, items: newItems }) => {
          // const primaryKey = getPrimaryKey(query);
          const primaryKey = `${practitionerId}-${centerId}`;

          initIfEmptyForPrimaryKey<T>(
            updatedObject.itemsDictMap,
            initialProperties,
            primaryKey,
          );
          const { itemsDict } = chunkResource
            ? { itemsDict: {} }
            : updatedObject.itemsDictMap[primaryKey];
          // UGLY FIX, BEFORE REPONSE BACK IS INCLUSE SEE notesParams IN Calendar.js
          const formattedFrom = moment(from)
            .add(1, 'minutes')
            .startOf('isoWeek')
            // .startOf('day')
            .toISOString();
          itemsDict[formattedFrom] = isReplace
            ? newItems
            : [...(itemsDict[formattedFrom] || []), ...newItems];

          const newItemsDict = {};
          const momentCurrentDate = moment(currentDate || state.currentDate);
          const [fromWeekBefore, toWeekAfter] = getRange(momentCurrentDate);

          Object.keys(itemsDict).forEach((itemKey: string) => {
            if (
              moment(itemKey).isBetween(fromWeekBefore, toWeekAfter) ||
              itemKey === currentWeek
            ) {
              assignItemsForSecondaryKey(
                newItemsDict,
                getItemsFromKey<T>(itemsDict, itemKey),
                itemKey,
              );
            }
          });
          updatedObject.itemsDictMap = {
            ...assignItemsDictForPrimaryKey<T, InitProperties>(
              updatedObject.itemsDictMap,
              newItemsDict,
              primaryKey,
            ),
          };
        });

        if (currentDate) {
          updatedObject.currentDate = currentDate;
        }
        const r = {
          ...state,
          ...updatedObject,
        };

        return r;
      };
    });

    return reducer;
  };
  return surchargeReducer;
};

export type ItemsDictMapReducerGeneratorOptions<
  T extends {} = {},
  InitState extends {} = {},
  InitProperties extends {} = {}
> = {
  name: string;
  keysOptions: KeysOptions<T, InitProperties>;
  surchargeReducer?: (
    s: ItemsDictMapReducer<T, InitState, InitProperties>,
  ) => ItemsDictMapReducer<T, InitState, InitProperties>;
  extendReducer?: ItemsDictMapReducer<T, InitState, InitProperties>;
};

export const itemsDictMapReducerGenerator = <
  T extends U & { id?: string },
  U extends {} = T,
  InitState extends {} = {},
  InitProperties extends {} = {}
>(
  options: ItemsDictMapReducerGeneratorOptions<U, InitState, InitProperties>,
) => {
  const { name, keysOptions, surchargeReducer, extendReducer } = options;
  const itemsDictMapSurchargeReducer = createSurchargeReducerItemsDict<
    T,
    U,
    InitState,
    InitProperties
  >(name, keysOptions);
  return reducersGenerator({
    name,
    surchargeReducer: surchargeReducer
      ? () =>
          surchargeReducer(
            reducerConfigGenerator({
              name: 'itemsDictMap',
              surchargeReducer: itemsDictMapSurchargeReducer,
              initialState: initialStateItemsDict,
            }),
          )
      : itemsDictMapSurchargeReducer,
    extendReducer,
  });
};
