import React, { ElementType, forwardRef } from 'react';
import { ListItem as MaterialListItem, ListItemProps } from '@mui/material';
import omit from 'lodash/omit';
import { makeStyles } from 'tss-react/mui';

import ButtonBase from '../ButtonBase';

import { useDatacyName } from '@/src/hooks/datacy';

const useStyles = makeStyles<{
  disabled: boolean;
  highlighted: boolean;
  selected: boolean;
}>()((theme: any, { disabled, highlighted, selected }) => ({
  root: {
    ...theme.typography.listItemBody,
    color: disabled
      ? theme.palette.text.disabled
      : theme.typography.listItemBody.color,
    paddingTop: theme.size.spacing[1],
    paddingBottom: theme.size.spacing[1],
    backgroundColor: highlighted
      ? theme.palette.background.hovered
      : theme.palette.destructive.paper,
    fontWeight: selected ? 'bold' : 'normal',
  },
}));

type Props = Omit<ListItemProps<'div'>, 'button' | 'classes'> & {
  className?: string;
  datacy?: string;
  highlighted?: boolean;
  component?: ElementType;
  value?: any;
  button?: boolean;
};

const ListItem = forwardRef((props: Props, ref: React.Ref<HTMLElement>) => {
  const { className, datacy, component = ButtonBase, ...rest } = props;
  const datacyName = useDatacyName(datacy);
  const { classes, cx } = useStyles({
    ...(props as any),
  });
  return (
    <MaterialListItem
      // @ts-ignore
      component={component}
      datacy={datacyName}
      classes={{ root: cx(classes.root, className && className) }}
      {...omit(rest, 'highlighted')}
      ref={ref as React.Ref<HTMLLIElement>}
    />
  );
});

ListItem.displayName = 'ListItem';

export default ListItem;
