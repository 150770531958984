import React, { forwardRef, Ref } from 'react';
import { makeStyles } from 'tss-react/mui';

import { useTranslation } from '../../../i18n';
import ButtonItem from '../../molecules/ButtonItem';

import FontAwesomeIcon from '@/components/atoms/FontawesomeIcon/FontawesomeIcon';

const useStyles = makeStyles()((theme: any) => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    position: 'absolute',
    zIndex: theme.zIndex.god,
    boxShadow: theme.shadows[3],
    '& *.MuiListItem-divider': {
      borderBottom: 'none',
    },
    margin: 0,
    padding: 0,
  },
  listElement: {
    '--itemHeight': '60px',
    display: 'block',
    overflowY: 'auto',
    maxHeight: 'calc(8 * var(--itemHeight))',
  },
}));

type Props = {
  isOpen: boolean;
  isLoading: boolean;
  blurSearchInput: () => void;
  onCreateNewPatient: () => void;
  children: any;
  closeMenu: () => void;
  className?: string;
};

const HeaderSearchListComponent = forwardRef((props: Props, ref: Ref<any>) => {
  const { t } = useTranslation('common');
  const { classes, cx } = useStyles();
  const {
    isOpen,
    isLoading,
    blurSearchInput,
    onCreateNewPatient = () => {},
    children,
    closeMenu,
    ...rest
  } = props;

  if (!isOpen) return <div {...rest} ref={ref} />;

  return (
    <div ref={ref} {...rest} className={cx(props.className, classes.root)}>
      {children && (
        <ul>
          <li className={cx(classes.listElement)}>{children}</li>
        </ul>
      )}
      <ButtonItem
        button
        datacy="patient_autocomplete-create_new_patient"
        text={t('editappointmentform_createpatient_button')}
        icon={<FontAwesomeIcon name="user-plus:regular" className="h-5 w-5" />}
        onClick={event => {
          if (event) {
            event.stopPropagation();
            event.preventDefault();
          }
          if (blurSearchInput) {
            blurSearchInput();
          }
          onCreateNewPatient();
        }}
      />
    </div>
  );
});

HeaderSearchListComponent.displayName = 'HeaderSearchListComponent';

export default HeaderSearchListComponent;
