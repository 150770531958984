/* eslint-disable import/no-cycle */
import { selectorsGenerator } from '@docavenue/core';
import {
  CenterDTO,
  ProAgendaSettingsDTO,
  ProStatisticDTO,
  TreatmentGetDTO,
  User,
  WeekTemplateCycle,
} from '@maiia/model/generated/model/api-pro/api-pro';

import agendaSettingsDefault from './agendaSettingsDefaultSelectors';
import agendaSettings from './agendaSettingsSelectors';
import chatMessages, { ChatMessagesSelector } from './chatMessagesSelectors';
import chatRooms, { ChatRoomsSelector } from './chatRoomsSelectors';
import chat, { ChatSelector } from './chatSelectors';
import consultationReasons, {
  ConsultationReasonSelector,
} from './consultationReasonsSelectors';
import documents, { DocumentSelector } from './documentsSelectors';
import patients, { PatientSelector } from './patientsSelectors';
import practitioners, { PractitionerSelector } from './practitionersSelectors';
import { GenericSelector } from './selectorsType';
import statistics from './statisticsSelectors';
import substitutePractitioners from './substitutePractitionersSelectors';
import users from './usersSelectors';
import weeks, { WeeksSelector } from './weeksSelectors';
import weekTemplateCycles from './weekTemplateCyclesSelectors';

export type Selectors = {
  agendaSettingsDefaultSelectors: GenericSelector<ProAgendaSettingsDTO>;
  agendaSettingsSelectors: GenericSelector<ProAgendaSettingsDTO>;
  centersSelectors: GenericSelector<CenterDTO>;
  consultationReasonsSelectors: ConsultationReasonSelector;
  documentsSelectors: DocumentSelector;
  patientsSelectors: PatientSelector;
  practitionersSelectors: PractitionerSelector;
  statisticsSelectors: GenericSelector<ProStatisticDTO>;
  substitutePractitionersSelectors: GenericSelector<User>;
  treatmentsSelectors: GenericSelector<TreatmentGetDTO>;
  usersSelectors: GenericSelector<User>;
  weeksSelectors: WeeksSelector;
  weekTemplateCyclesSelectors: GenericSelector<WeekTemplateCycle>;

  // Chat selectors
  chatMessagesSelectors: ChatMessagesSelector;
  chatRoomsSelectors: ChatRoomsSelector;
  chatSelectors: ChatSelector;
};

const {
  agendaSettingsDefaultSelectors,
  agendaSettingsSelectors,
  centersSelectors,
  consultationReasonsSelectors,
  documentsSelectors,
  patientsSelectors,
  practitionersSelectors,
  statisticsSelectors,
  substitutePractitionersSelectors,
  treatmentsSelectors,
  usersSelectors,
  weekTemplateCyclesSelectors,
  weeksSelectors,
  // Chat selectors
  chatMessagesSelectors,
  chatRoomsSelectors,
  chatSelectors,
}: Selectors = selectorsGenerator([
  patients,
  practitioners,
  { name: 'centers' },
  consultationReasons,
  documents,
  { name: 'treatments' },
  weeks,
  weekTemplateCycles,
  statistics,
  substitutePractitioners,
  agendaSettings,
  agendaSettingsDefault,
  users,
  // chat selectors
  chatRooms,
  chatMessages,
  chat,
]);

// eslint-disable-next-line import/prefer-default-export
export {
  agendaSettings,
  agendaSettingsDefault,
  agendaSettingsDefaultSelectors,
  agendaSettingsSelectors,
  centersSelectors,
  chatMessagesSelectors,
  chatRoomsSelectors,
  chatSelectors,
  consultationReasonsSelectors,
  documentsSelectors,
  patientsSelectors,
  practitionersSelectors,
  statisticsSelectors,
  substitutePractitionersSelectors,
  treatmentsSelectors,
  usersSelectors,
  weeksSelectors,
  weekTemplateCyclesSelectors,
};
