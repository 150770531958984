import Router from 'next/router';
import omit from 'lodash/omit';

import { UrlObject } from 'url';

import { User } from '@maiia/model/generated/model/api-pro/api-pro';

import { ONLINE_CALLIBRI, REGULATOR } from '../constants';
import Routes from '../constants/routes';
import { MaiiaNextPageContext } from '../types/MaiiaNextContext';
import { isServerSideContext } from '../utils';
import { buildUrlString } from '../utils/RoutesUtils';

export function serverSideRedirectTo(
  url: string | UrlObject,
  { res }: MaiiaNextPageContext,
): void {
  if (!res) {
    return;
  }
  let Location = url;

  if (typeof Location !== 'string') {
    const { pathname, query } = url as UrlObject;
    Location = `${pathname}?${new URLSearchParams(query?.toString())}`;
  }

  res.writeHead(302, { Location });
  res.end();
}

/*
 * Redirect function that can be used on both the client and the server
 */
export function isomorphicRedirect(
  url: string | UrlObject,
  ctx: MaiiaNextPageContext,
): void {
  if (isServerSideContext(ctx)) {
    serverSideRedirectTo(url, ctx);
  } else {
    Router.push(url);
  }
}

export function handleWrongTSToken(ctx: MaiiaNextPageContext) {
  const { pathname, query } = ctx;
  const { tsToken } = query;

  const next = buildUrlString(
    pathname,
    omit(query, 'tsToken') as Record<string, string | undefined>,
  );

  const loginUrl = buildUrlString(Routes.LOGIN, { tsToken, next });

  isomorphicRedirect(loginUrl, ctx);
}

export function isFromConnectOnboarding(user: Maybe<User>): boolean {
  return (
    !!user?.origin &&
    ['ONLINE_INVITATION', 'ONLINE_ONBOARDING'].includes(user.origin)
  );
}

export function isFromLGCOnboarding(user: Maybe<User>) {
  return user?.origin === ONLINE_CALLIBRI;
}

export function isConnectOnboardingUser(user: User | null): boolean {
  return isFromConnectOnboarding(user) && !user?.onboardingComplete;
}

export function isLGCOnboardingUser(user: User | null): boolean {
  return isFromLGCOnboarding(user) && !user?.onboardingComplete;
}
export function isRegulatorUser(user: User | null): boolean {
  return user?.role?.name === REGULATOR;
}
