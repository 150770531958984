import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { cn } from '@kiiwi/ui';
import { createSelector } from 'reselect';
import { makeStyles } from 'tss-react/mui';

import { asyncActions, serialActions } from '@docavenue/core';

import { notificationCentersActions } from '../../../src/actions';
import { useAnalyticsTracker } from '../../../src/hooks/utils';
import Badge from '../../atoms/Badge';
import FontAwesomeIcon, {
  AcceptedIconNames,
} from '../../atoms/FontawesomeIcon/FontawesomeIcon';
import Menu from '../Menu';
import ListNotificationContainer from './ListNotificationContainer';

import {
  GAActions,
  GAActionStatus,
  GACategories,
} from '@/src/constants/tracking';
import { useDatacyName } from '@/src/hooks/datacy';

const useStyles = makeStyles()((theme: any) => ({
  badge: {
    cursor: 'pointer',
    '& span': {
      backgroundColor: theme.palette.accent.dark,
      top: '20%',
      right: '25%',
    },
  },
  nonotif: {
    opacity: 0.5,
  },
}));

const LIMIT_NOTIFICATIONS = 20;

const areUnreadNotificationsSelector = createSelector(
  state => state.notificationCenters.items,
  items => items.some(item => !item.isRead),
);

const HeaderNotificationsDialog = ({
  iconClassname,
}: {
  iconClassname?: string;
}) => {
  const { classes } = useStyles();
  const refMenu: React.Ref<any> = useRef(null);
  const [showBadge, setShowBadge] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const areUnreadNotifications = useSelector(areUnreadNotificationsSelector);
  const dispatch = useDispatch();
  const datacyName = useDatacyName('notificationCenter');
  const triggerGAEvent = useAnalyticsTracker(GACategories.Header);

  const queryNotifications = {
    limit: LIMIT_NOTIFICATIONS,
    sort: 'creationDate',
    direction: 'desc',
    page: 0,
  };

  const acknowledgeRequest = () =>
    asyncActions(dispatch, notificationCentersActions.acknowledge());

  const onClose = () => {
    dispatch(notificationCentersActions.setAllNotificationsAsRead());
    acknowledgeRequest();
    setShowBadge(false);
    setIsMenuOpen(false);
  };

  useEffect(() => {
    dispatch(notificationCentersActions.getList(queryNotifications));
  }, []);

  useEffect(() => {
    if (areUnreadNotifications) {
      setShowBadge(true);
    }
  }, [areUnreadNotifications]);

  const onClick = async () => {
    if (showBadge) {
      setShowBadge(false);
    }

    const handleGAEvent = (status: GAActionStatus) => {
      triggerGAEvent(GAActions.ClickNotificationCenter, status);
    };

    const _serialActions = serialActions.serial([
      () => notificationCentersActions.getList(queryNotifications),
      () => acknowledgeRequest(),
    ]);

    // Prevent sending useless duplicate Analytics Event on click when closing
    if (!isMenuOpen) {
      _serialActions.onSuccess = () => handleGAEvent(GAActionStatus.SUCCESS);
      _serialActions.onError = () => handleGAEvent(GAActionStatus.ERROR);
      setIsMenuOpen(true);
    }

    dispatch(_serialActions);
  };

  const iconName: AcceptedIconNames = isMenuOpen
    ? 'bell:solid'
    : 'bell:regular';

  return (
    <Menu
      open={false}
      autoClose
      ref={refMenu}
      placement="bottom"
      onClose={onClose}
      trigger={
        <div onClick={onClick}>
          <Badge
            className={classes.badge}
            variant="dot"
            invisible={!showBadge}
            datacy={datacyName}
          >
            {showBadge ? (
              <FontAwesomeIcon
                name={iconName}
                className={cn('h-5 w-5', iconClassname)}
              />
            ) : (
              <FontAwesomeIcon
                name={iconName}
                className={cn('h-5 w-5 opacity-50', iconClassname)}
              />
            )}
          </Badge>
        </div>
      }
    >
      <ListNotificationContainer closeModal={() => refMenu?.current.close()} />
    </Menu>
  );
};

export default HeaderNotificationsDialog;
