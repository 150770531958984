/* eslint-disable import/no-cycle */
/* eslint-disable no-param-reassign */
import {
  ACKNOWLEDGE,
  CREATE,
  CREATE_SUCCESS,
  CRUD_FAILURE,
  DELETE_SUCCESS,
  GET_LIST_SUCCESS,
  UPDATE,
  UPDATE_SUCCESS,
} from '@docavenue/core';

import { ACKNOWLEDGE_SUCCESS } from '../actions';
import {
  initialStateItemsDict,
  initIfEmptyForPrimaryKey,
  itemsDictMapReducerGenerator,
} from './itemsDictMap';
import { ItemsDictMapReducer, ItemsDictMapState } from './reducersTypes';

import { Common, ItemsDictMapAction } from '@/src/actions/actionsTypes';
import { ChatReplyMessage } from '@/types/chat.types';

const name = 'chatReplyMessages';

type InitState = {};

export type ChatReplyMessageState = ItemsDictMapState<
  ChatReplyMessage,
  InitState,
  InitProperties
>;

export const chatReplyMessagesInitialState: ChatReplyMessageState = {
  ...initialStateItemsDict,
} as any; // todo types provide defaults that satify the interface

const getPrimaryKey = ({ chatRoomId }: { chatRoomId: string }): string =>
  chatRoomId;
const getSecondaryKey = ({ parentId }: { parentId: string }): string =>
  parentId;

type InitProperties = {
  deleteMessage: ChatReplyMessage | null;
  editMessage: ChatReplyMessage | null;
  total: number;
};

const initialProperties: InitProperties = {
  deleteMessage: null,
  editMessage: null,
  total: 0,
};

const keysOptions = { getPrimaryKey, getSecondaryKey, initialProperties };

const surchargeReducer = (
  itemsDictMapReducerConfig: ItemsDictMapReducer<
    ChatReplyMessage,
    InitState,
    InitProperties
  >,
) => {
  const initialCrudFailure = itemsDictMapReducerConfig[CRUD_FAILURE];
  const initialCrudUpdateSuccess = itemsDictMapReducerConfig[UPDATE_SUCCESS];

  [GET_LIST_SUCCESS, `${GET_LIST_SUCCESS}/${name}`].forEach(actionType => {
    itemsDictMapReducerConfig[actionType] = (
      state: ItemsDictMapState<ChatReplyMessage, InitState, InitProperties>,
      action: ItemsDictMapAction<{ items: ChatReplyMessage[]; total: number }>,
    ) => {
      const { itemsDictMap } = state;
      const { payload, chunkResource, query } = action;
      const { items, total } = payload;
      items.forEach(item => {
        const primaryKey = getPrimaryKey(item);
        const secondaryKey = getSecondaryKey(item);
        initIfEmptyForPrimaryKey<ChatReplyMessage>(
          itemsDictMap,
          initialProperties,
          primaryKey,
        );
        itemsDictMap[primaryKey].total = total;

        if (!itemsDictMap[primaryKey].itemsDict[secondaryKey]) {
          itemsDictMap[primaryKey].itemsDict[secondaryKey] = [];
        }
        const index = itemsDictMap[primaryKey].itemsDict[
          secondaryKey
        ].findIndex(oldItem => oldItem.id === item.id);
        if (index === -1) {
          itemsDictMap[primaryKey].itemsDict[secondaryKey] = [
            item,
            ...itemsDictMap[primaryKey].itemsDict[secondaryKey],
          ];
        } else {
          itemsDictMap[primaryKey].itemsDict[secondaryKey][index] = item;
          itemsDictMap[primaryKey].itemsDict[secondaryKey] = [
            ...itemsDictMap[primaryKey].itemsDict[secondaryKey],
          ];
        }
      });

      const updatedObject = {
        itemsDictMap: { ...itemsDictMap },
        [`isLoading${chunkResource || ''}`]: false,
        isLoading: false,
        error: null,
        currentDate: state.currentDate,
        query,
      };
      return {
        ...state,
        ...updatedObject,
      };
    };
  });

  itemsDictMapReducerConfig[UPDATE_SUCCESS] = (
    state: ItemsDictMapState<ChatReplyMessage, InitState, InitProperties>,
    action: ItemsDictMapAction<ChatReplyMessage>,
  ) => {
    if (!action.payload.parentId) {
      return {
        ...state,
        item: action.payload,
      };
    }
    return initialCrudUpdateSuccess(state, action);
  };

  itemsDictMapReducerConfig[ACKNOWLEDGE] = (
    state: ItemsDictMapState<ChatReplyMessage, InitState, InitProperties>,
    action: ItemsDictMapAction<ChatReplyMessage>,
  ) => {
    const { itemsDictMap } = state;
    const { payload } = action;
    const primaryKey = getPrimaryKey(payload);
    const secondaryKey = getSecondaryKey(payload);
    initIfEmptyForPrimaryKey(itemsDictMap, initialProperties, primaryKey);

    itemsDictMap[primaryKey].itemsDict[secondaryKey] = (
      itemsDictMap[primaryKey].itemsDict[secondaryKey] || []
    ).map(chatMessage => ({
      ...chatMessage,
      users: payload.users,
    }));

    return {
      ...state,
      itemsDictMap: { ...itemsDictMap },
    };
  };

  itemsDictMapReducerConfig[ACKNOWLEDGE_SUCCESS] = (
    state: ItemsDictMapState<ChatReplyMessage, InitState, InitProperties>,
    action: ItemsDictMapAction<ChatReplyMessage>,
  ) => {
    const { itemsDictMap } = state;
    const { payload, userId } = action;
    const primaryKey = getPrimaryKey(payload);
    const secondaryKey = getSecondaryKey(payload);
    initIfEmptyForPrimaryKey(itemsDictMap, initialProperties, primaryKey);

    itemsDictMap[primaryKey].itemsDict[secondaryKey] = (
      itemsDictMap[primaryKey].itemsDict[secondaryKey] || []
    ).map(chatMessage => ({
      ...chatMessage,
      users: chatMessage.users?.map(user => {
        if (user.userId === userId && !user.isRead) {
          return { ...user, isRead: true };
        }
        return user;
      }),
    }));

    return {
      ...state,
      itemsDictMap: { ...itemsDictMap },
    };
  };

  itemsDictMapReducerConfig[DELETE_SUCCESS] = (
    state: ItemsDictMapState<ChatReplyMessage, InitState, InitProperties>,
    action: ItemsDictMapAction<
      { id: string },
      ChatReplyMessage & { params: { chatRoomId: string; parentId: string } }
    >,
  ) => {
    const { itemsDictMap } = state;
    const { payload, query } = action;
    const { params } = query;
    const primaryKey = getPrimaryKey(params);
    const secondaryKey = getSecondaryKey(params);
    initIfEmptyForPrimaryKey(itemsDictMap, initialProperties, primaryKey);
    if (!secondaryKey) {
      itemsDictMap[primaryKey].itemsDict[payload.id] = (
        itemsDictMap[primaryKey].itemsDict[payload.id] || []
      ).filter(item => item.parentId !== payload.id);
      return {
        ...state,
        item: null,
        itemsDictMap: { ...itemsDictMap },
      };
    }

    itemsDictMap[primaryKey].itemsDict[secondaryKey] = (
      itemsDictMap[primaryKey].itemsDict[secondaryKey] || []
    ).filter(item => item.id !== payload.id);

    return {
      ...state,
      itemsDictMap: { ...itemsDictMap },
    };
  };

  itemsDictMapReducerConfig[CRUD_FAILURE] = (
    state: ItemsDictMapState<ChatReplyMessage, InitState, InitProperties>,
    action: ItemsDictMapAction<
      ChatReplyMessage & {
        action: {
          type: string;
          payload: {
            params: Record<string, any>;
            data: Record<string, any>;
            id: string;
          };
        } & Common;
      }
    >,
  ) => {
    if (
      !action.payload?.action?.type ||
      ![CREATE, UPDATE].includes(action.payload?.action?.type)
    ) {
      return initialCrudFailure(state, action);
    }

    const baseAction = {
      ...action.payload.action,
      query: { ...action.payload.action.payload.params },
      payload: {
        isError: true,
        users: [],
      },
    };

    const newAction =
      action.payload.action.type === CREATE
        ? {
            ...baseAction,
            type: CREATE_SUCCESS,
            payload: {
              ...action.payload.action.payload.data,
              ...baseAction.payload,
              id: new Date().getTime(),
              creationDate: new Date().toISOString(),
              updateDate: new Date().toISOString(),
            },
          }
        : {
            ...baseAction,
            type: UPDATE_SUCCESS,
            payload: {
              ...action.payload.action.payload.data,
              ...baseAction.payload,
              id: action.payload.action.payload.id,
              creationDate: action.payload.action.payload.params.creationDate,
              updateDate: action.payload.action.payload.params.creationDate,
            },
          };
    return itemsDictMapReducerConfig[UPDATE_SUCCESS](state, newAction);
  };

  return itemsDictMapReducerConfig;
};

export const chatReplyMessages = itemsDictMapReducerGenerator<
  ChatReplyMessage,
  ChatReplyMessage,
  InitState,
  InitProperties
>({
  name,
  keysOptions,
  surchargeReducer,
});
