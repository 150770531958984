import React, { forwardRef } from 'react';
import { Chip as MaterialChip, ChipProps } from '@mui/material';
import omit from 'lodash/omit';
import { makeStyles } from 'tss-react/mui';

import Typography from '../Typography';

import { useDatacyName } from '@/src/hooks/datacy';

const useStyles = makeStyles()((theme: any) => ({
  chipRoot: {
    backgroundColor: theme.palette.background.tagGrey,

    '&.bluePill': {
      '--bg-blue': theme.palette.blue[100],
      '--border-blue': theme.palette.blue[500],
      padding: theme.spacing(1),
      display: 'flex',
      alignItems: 'center',
      backgroundColor: theme.palette.blue[100],
      color: theme.palette.blue[500],
      border: '1px solid',
      borderRadius: theme.spacing(5),
      borderColor: theme.palette.blue[200],

      '& > svg': {
        fill: theme.palette.blue[200],
        marginLeft: theme.spacing(2),
      },
      '&:hover': {
        borderColor: theme.palette.blue[500],
        '& > svg': {
          fill: theme.palette.blue[500],
        },
      },

      '& .MuiChip-label': {
        display: 'flex',
        alignItems: 'center',
        padding: 0,

        '& .avatar': {
          border: '1px solid',
          borderRadius: '50%',
          borderColor: theme.palette.blue[200],

          '--size': theme.spacing(6),
          height: 'var(--size)',
          width: 'var(--size)',
          marginRight: theme.spacing(2),
        },
      },
    },
  },
}));

type Props = {
  datacy?: string;
  className?: string;
  item?: any;
} & ChipProps;

const Chip = forwardRef((props: Props, ref: React.Ref<HTMLDivElement>) => {
  const { label, className, ...rest } = props;
  const { classes, cx } = useStyles();
  const datacyName = useDatacyName(typeof label === 'string' ? label : '');
  const classNames = cx(classes.chipRoot, className);

  if (typeof label === 'string') {
    return (
      <MaterialChip
        label={
          <Typography variant="bodySmall" className={className}>
            {label}
          </Typography>
        }
        ref={ref}
        className={classNames}
        datacy={`${datacyName}_chip`}
        {...omit(rest, 'datacy')}
      />
    );
  }
  return (
    <MaterialChip
      label={label}
      className={classNames}
      {...omit(rest, 'datacy')}
      ref={ref}
    />
  );
});

Chip.displayName = 'Chip';

export default Chip;
