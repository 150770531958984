import { generateInitialSelectors } from '@docavenue/core';
import { WeekTemplateCycle } from '@maiia/model/generated/model/api-pro/api-pro';

import {
  GenericSelector,
  InitialGenericSelector,
} from '@/src/selector/selectorsType';

const initialSelectors: InitialGenericSelector<WeekTemplateCycle> = generateInitialSelectors(
  'weekTemplateCycles',
);

const selectors: GenericSelector<WeekTemplateCycle> = {
  name: 'weekTemplateCycles',
  ...initialSelectors,
};

export default selectors;
