/* eslint-disable no-nested-ternary */
import React, { useLayoutEffect, useRef } from 'react';
import { TextFieldProps } from '@mui/material';
import { FieldProps, getIn } from 'formik';
import get from 'lodash/get';
import identity from 'lodash/identity';
import isEmpty from 'lodash/isEmpty';

import { useTranslation } from '../../../../i18n';
import { withFieldComponentPoc } from '../../../organisms/FieldsBuilder';
import TextField from './TextField';

import { useDatacyName } from '@/src/hooks/datacy';

type TextFieldControllerHookProps = {
  toUpperCase?: boolean;
  placeholder?: string;
  select?: boolean;
  suggested?: boolean;
  hideError?: boolean;
  warning?: boolean;
  focus?: boolean;
  shouldSkipBlur?: (
    form: Object,
    field: Object,
    event: React.FocusEvent<HTMLInputElement>,
  ) => boolean;
  getValue?: (v: any) => string;
  setValue?: (s: string) => any;
  InputComponent?: React.ElementType;
  warningText?: string;
} & Partial<TextFieldProps> &
  Omit<FieldProps, 'meta'>;

export const useFieldToTextField = (props: TextFieldControllerHookProps) => {
  const {
    field,
    form,
    disabled = false,
    toUpperCase = false,
    select,
    placeholder,
    required,
    suggested,
    hideError,
    warning = false,
    focus = false,
    type,
    shouldSkipBlur = () => false,
    getValue = identity,
    setValue = identity,
    ...rest
  } = props;

  const { t } = useTranslation('schemas');
  const datacy = useDatacyName(get(field, 'name'));
  const inputRef = useRef<HTMLInputElement>(null);

  useLayoutEffect(() => {
    if (focus) {
      const timeout = setTimeout(() => {
        inputRef.current?.focus();
      }, 10);

      return () => {
        clearTimeout(timeout);
      };
    }
  }, [focus]);

  if (!field) {
    const { name: nameFromProps } = rest;
    if (nameFromProps) {
      const errorMsg = get(form, `errors.${nameFromProps}`);
      return {
        ...props,
        error: !!errorMsg,
        helperText: !hideError && t(errorMsg),
      };
    }
    return props;
  }
  const { name } = field;
  const { touched, errors, isSubmitting } = form;

  const fieldError = getIn(errors, name);
  const showError = getIn(touched, name) && !!fieldError;

  type Value = string | number | { target: { value: string } };

  const valueToText = (value: Value) => {
    if (typeof value === 'string') return value;
    if (typeof value === 'number') return value.toString();
    return value.target.value;
  };

  const onChange = toUpperCase
    ? value =>
        form.setFieldValue(
          field.name,
          setValue(valueToText(value).toUpperCase()),
        )
    : value => form.setFieldValue(field.name, setValue(valueToText(value)));
  const placeholderWithRequiredMark =
    required && placeholder ? `${placeholder}*` : placeholder;
  // const onAccept = (value, mask) => form.setFieldValue(field.name, value));
  const newProps = {
    ...field,
    value: getValue(field.value),
    onChange,
    ...rest,
    error: showError,
    helperText: !hideError
      ? showError
        ? t(fieldError)
        : rest.helperText
      : null,
    disabled: isSubmitting || disabled,
    isUnderline: required && !getValue(field.value),
    isWarningUnderline: isEmpty(getValue(field.value)) && warning,
    // focused: required && !field.value,
    suggested,
    placeholder: placeholderWithRequiredMark,
    select,
    inputProps: {
      datacy,
      type,
      ref: inputRef,
      ...rest.inputProps,
    },
    InputProps: {
      ...rest.InputProps,
      onBlur: (event: React.FocusEvent<HTMLInputElement>) => {
        const onBlurAutocomplete = get(rest, 'InputProps.onBlur');
        if (onBlurAutocomplete) {
          onBlurAutocomplete(event);
        }
        if (!shouldSkipBlur(form, field, event)) {
          // in order to set the field touched, formik uses the name prop.
          // since we use uuid, it's random so we override this with the field's name
          field.onBlur({ target: { name: field.name } });
        }
      },
    },
  };
  return newProps;
};

export type TextFieldControllerProps = {
  InputComponent?: React.FunctionComponent<{}>;
  valueToText?: (value: string) => {};
} & TextFieldControllerHookProps;

const TextFieldController = React.forwardRef(
  (
    props: TextFieldControllerProps,
    ref: React.ForwardedRef<HTMLDivElement>,
  ) => {
    const { InputComponent = TextField, valueToText, ...rest } = props;
    const childProps = useFieldToTextField({ ...rest, ref });

    return <InputComponent {...childProps} />;
  },
);

export const TextFieldControllerWithFormik = withFieldComponentPoc<
  TextFieldControllerProps
>(TextFieldController);

TextFieldController.displayName = 'TextFieldController';

export default TextFieldController;
