import React, { forwardRef } from 'react';
import { Drawer as MaterialDrawer, DrawerProps } from '@mui/material';

import { DatacyContext } from '@/src/contexts/DatacyContext';
import { useDatacyName } from '@/src/hooks/datacy';

type Props = DrawerProps;

const Drawer = forwardRef((props: Props, ref: React.Ref<HTMLDivElement>) => {
  const datacyName = useDatacyName('drawer');
  return (
    <DatacyContext.Provider value={datacyName}>
      <MaterialDrawer {...props} ref={ref} />
    </DatacyContext.Provider>
  );
});

Drawer.displayName = 'Drawer';

export default Drawer;
