export const getTaskPatternForGetListAction = (action: {
  type: string;
  resource: string;
  payload?: { practitionerId: string; centerId: string };
}) =>
  `${action.type}/${action.resource}/${action.payload?.practitionerId}/${action.payload?.centerId}`;

export const chatResourceList = [
  { name: 'chatRoomDocuments' },
  { name: 'agendaSettings' },
  { name: 'agendaSettingsDefault' },
  { name: 'centers' },
  { name: 'chatDrafts', getTaskPatternForGetListAction },
  { name: 'chatRooms', getTaskPatternForGetListAction },
  { name: 'chatMessages', getTaskPatternForGetListAction },
  { name: 'chatReplyMessages', getTaskPatternForGetListAction },
  { name: 'chatThreads' },
  { name: 'chatUsersConnected' },
  { name: 'chatUsersTyping', getTaskPatternForGetListAction },
  { name: 'users' },
  { name: 'searchInvitation' },
];
