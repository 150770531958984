import React from 'react';
import { makeStyles } from 'tss-react/mui';

import FontAwesomeIcon from '../FontawesomeIcon/FontawesomeIcon';

type SProps = {
  // eslint-disable-next-line react/no-unused-prop-types
  width?: number;
  open?: boolean;
};

type CProps = {
  onClick: (event: React.MouseEvent<HTMLDivElement>) => void;
  rtl?: boolean;
  children?: React.ReactNode;
};

type Props = CProps & SProps;

const useStyles = makeStyles<{ open: boolean; width: number }>()(
  (theme: any, { open, width }) => {
    return {
      sidebarToggleWrapper: {
        display: 'flex',
        height: '100vh',
        backgroundColor: 'white',
      },
      rtl: {
        height: theme.layout.regularPage.minHeight,
      },
      close: {
        boxShadow: theme.shadows[6],
      },
      open: {
        marginLeft: 0,
      },
      sidebarContent: {
        display: 'flex',
        flexDirection: 'column',
        width: `calc(100% - ${width}px)`,
        height: '100%',
        overflowX: 'hidden',
        overflowY: 'auto',
      },
      sidebarToggleButton: {
        width,
        height: '100%',
        cursor: 'pointer',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderLeft: open ? '1px solid rgba(0, 0, 0, 0.12)' : 'inherit',
      },
    };
  },
);

const SidebarToggle = (props: Props) => {
  const { onClick, children, open, rtl = false } = props;
  const { classes, cx } = useStyles({
    ...(props as any),
  });
  return (
    <div
      className={cx(
        classes.sidebarToggleWrapper,
        rtl && classes.rtl,
        rtl && open && classes.open,
        rtl && !open && classes.close,
      )}
    >
      {children && <div className={classes.sidebarContent}>{children}</div>}
      <div
        onClick={onClick}
        className={cx(classes.sidebarToggleButton, 'sidebar-toggle-button')}
      >
        {!rtl &&
          (open ? (
            <FontAwesomeIcon name="angle-left:regular" className="h-3 w-3" />
          ) : (
            <FontAwesomeIcon name="angle-right:regular" className="h-3 w-3" />
          ))}
        {rtl &&
          (open ? (
            <FontAwesomeIcon name="angle-right:regular" className="h-3 w-3" />
          ) : (
            <FontAwesomeIcon name="angle-left:regular" className="h-3 w-3" />
          ))}
      </div>
    </div>
  );
};

export default SidebarToggle;
