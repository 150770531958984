import {
  DELETE_SUCCESS,
  reducersGenerator,
  UPDATE_SUCCESS,
} from '@docavenue/core';
import {
  NotificationSettings,
  NotificationTypeSettings,
} from '@maiia/model/generated/model/api-pro/api-pro';

import { CrudState } from '@/src/reducer/reducersTypes';

export const surchargeReducer = () => {
  /* eslint-disable-next-line no-console */
  const obj: any = {};

  obj[UPDATE_SUCCESS] = (
    state: CrudState<NotificationSettings>,
    action: {
      payload: NotificationSettings;
      query?: { data?: { notificationType?: { name: string } } };
    },
  ) => {
    const {
      payload: { notificationTypesSettings: notificationTypesSettingsPayload },
      query,
    } = action;
    const notificationType = query?.data?.notificationType?.name;

    const notificationTypesSettings: any = {};
    const item = { notificationTypesSettings, ...state.item };
    if (notificationType && notificationTypesSettingsPayload) {
      item.notificationTypesSettings[notificationType] = {
        ...notificationTypesSettingsPayload[notificationType],
      };
    }

    return { ...state, item, isLoading: false, error: null };
  };

  obj[DELETE_SUCCESS] = (
    state: CrudState<NotificationSettings>,
    action: { payload: NotificationTypeSettings },
  ) => {
    const notificationType = action.payload?.notificationType?.name || '';

    const item: any = {
      ...state.item,
    };
    item.notificationTypesSettings[notificationType] = { ...action.payload };

    return { ...state, item, isLoading: false, error: null };
  };
  return obj;
};

const notificationSettings = reducersGenerator({
  name: 'notificationSettings',
  surchargeReducer,
});

export default notificationSettings;
