import { actionsGeneratorV2 } from '@docavenue/core';

import { ChatReplyMessage } from '../../types/chat.types';
import { Actions, DataAction } from './actionsTypes';
import { ACKNOWLEDGE_SUCCESS } from './constants';
// eslint-disable-next-line import/no-cycle

export type ChatReplyMessagesActions = Actions<ChatReplyMessage> & {
  acknowledge: (params: { parentId: string }) => DataAction<ChatReplyMessage>;
  acknowledgeSuccess: (
    payload: any,
    options: any,
  ) => DataAction<ChatReplyMessage>;
  getReplies: (params: { parentId: string }) => DataAction<ChatReplyMessage>;
};

const chatReplyMessagesActions: ChatReplyMessagesActions = actionsGeneratorV2({
  resource: 'chatReplyMessages',
  chunkUrlResource: 'chat-messages',
}) as any;

const { create, getList } = chatReplyMessagesActions;

chatReplyMessagesActions.acknowledge = (params: { parentId: string }) =>
  create(
    {},
    {
      chunkUrlResource: 'chat-messages',
      chunkResource: 'acknowledge-replies',
      chunkId: params.parentId,
      ...(params || {}),
    },
  );

chatReplyMessagesActions.acknowledgeSuccess = (
  payload: ChatReplyMessage,
  options: any = {},
) => ({
  type: ACKNOWLEDGE_SUCCESS,
  ...chatReplyMessagesActions.common,
  ...options,
  payload,
  resource: chatReplyMessagesActions.resource,
});

chatReplyMessagesActions.getReplies = (params: { parentId: string }) =>
  getList(
    {},
    {
      chunkUrlResource: 'chat-messages',
      chunkResource: 'replies',
      chunkId: params.parentId,
      ...(params || {}),
    },
  );

export default chatReplyMessagesActions;
