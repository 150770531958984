import { generateInitialSelectors } from '@docavenue/core';
import { User } from '@maiia/model/generated/model/api-pro/api-pro';

import {
  GenericSelector,
  InitialGenericSelector,
} from '@/src/selector/selectorsType';

const initialSelectors: InitialGenericSelector<User> = generateInitialSelectors(
  'users',
);

const selectors: GenericSelector<User> = {
  name: 'users',
  ...initialSelectors,
};

export default selectors;
