import React, { ReactNode, useState } from 'react';
import dynamic from 'next/dynamic';
import { Hidden } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { makeStyles } from 'tss-react/mui';

import Drawer from '../components/atoms/Drawer';
import SidebarToggle from '../components/atoms/SidebarToggle';
import VideoMeeting from '../components/organisms/VideoMeeting';
import Routes from './constants/routes';
import {
  SIDEBAR_CONNECT_WIDTH,
  SIDEBAR_EXPAND_BUTTON_WIDTH,
  SIDEBAR_TLC_WIDTH,
  SIDEBAR_WIDTH,
} from './theme/height';
import { isConnectPathname } from './utils';

import { DatacyContext } from '@/src/contexts/DatacyContext';
import { useDatacyName } from '@/src/hooks/datacy';

const MiniOpenTok = dynamic(
  import('../components/organisms/OpenTok/MiniOpenTok'),
  {
    ssr: false,
  },
);

const getSidebarWidth = (pathname: string) => {
  const isTLC = pathname === Routes.TLC;
  if (isTLC) return SIDEBAR_TLC_WIDTH;
  const isConnect = isConnectPathname(pathname);
  if (isConnect) return SIDEBAR_CONNECT_WIDTH;
  return SIDEBAR_WIDTH;
};

export const SIDEBAR_BREAKPOINT = 1280;
export const SIDEBAR_TLC_BREAKPOINT = 1460;

const getSidebarBreakpoint = (pathname: string) =>
  pathname === Routes.TLC ? SIDEBAR_TLC_BREAKPOINT : SIDEBAR_BREAKPOINT;

type UseStyles = {
  pathname: string;
  isTlcSmallScreen: boolean;
  isNotTlcSmallScreen: boolean;
  hideHeader: boolean;
};

const useStyles = makeStyles<UseStyles>()(
  (
    theme: any,
    { hideHeader, pathname, isTlcSmallScreen, isNotTlcSmallScreen },
  ) => ({
    root: {
      '--header-height': hideHeader ? '' : `${theme.layout.headerHeight}px`,
      display: 'grid',
      minHeight: '100vh',
      '& > div[layout-gridkey="header"]': {
        gridArea: 'header',
        // This is 46 because we want to put the header on top of events poppers that are now in the body, with a zIndex of 45.
        // zIndex: 46,
        zIndex: theme.zIndex.headerSearch,
      },
      '& > div[layout-gridkey="breadcrumbs"]': {
        gridArea: 'breadcrumbs',
        display: 'none',
        alignItems: 'center',
      },
      '& > div[layout-gridkey="sidebar"]': {
        gridArea: 'sidebar',
        height: `calc(100vh - var(--header-height))`,
        backgroundColor: theme.palette.common.white,
        zIndex: theme.zIndex.layoutSidebar,
      },
      '& > div[layout-gridkey="content"]': {
        gridArea: 'content',
        height: `calc(100vh - var(--header-height))`,
        overflow: 'auto',
      },
    },
    template: {
      gridTemplateColumns: '1fr',
      gridTemplateRows: `var(--header-height) 1fr`,
      gridTemplateAreas: `${hideHeader ? '' : "'header'"} 'content'`,
    },
    templateWithSideBar: {
      gridTemplateColumns: ` ${
        // eslint-disable-next-line no-nested-ternary
        pathname === Routes.TLC
          ? isTlcSmallScreen
            ? SIDEBAR_EXPAND_BUTTON_WIDTH
            : SIDEBAR_TLC_WIDTH
          : isNotTlcSmallScreen
          ? SIDEBAR_EXPAND_BUTTON_WIDTH
          : getSidebarWidth(pathname)
      }px 1fr`,
      gridTemplateRows: `var(--header-height) 1fr`,
      gridTemplateAreas: `${
        hideHeader ? '' : "'header header'"
      } 'sidebar content'`,
      '& > div[layout-gridkey="content"]': {
        height: `calc(100vh - var(--header-height))`,
        overflow: 'auto',
      },
      '& > div[layout-gridkey="sidebar"]': {
        display: 'flex',
        maxWidth: getSidebarWidth(pathname),
        height: `calc(100vh - var(--header-height))`,
        boxShadow: theme.shadows[4],
        '& > *': {
          display: 'flex',
          flex: 1,
          flexDirection: 'column',
          height: `calc(100vh - var(--header-height))`,
          maxWidth: getSidebarWidth(pathname),
        },
      },
    },
    sidebarMobileDrawer: {
      width: getSidebarWidth(pathname) + SIDEBAR_EXPAND_BUTTON_WIDTH,
      boxShadow: theme.shadows[5],
      top: 'unset',
    },
  }),
);

type Props = {
  variantSideBar: 'WithSideBar' | '';
  header: ReactNode;
  hideHeader?: boolean;
  pathname: string;
  sideBar?: ReactNode;
  content: ReactNode;
};

const Layout = ({
  variantSideBar = '',
  header,
  hideHeader = false,
  pathname,
  sideBar,
  content,
}: Props) => {
  const isTlcSmallScreen = useMediaQuery(
    `(max-width: ${SIDEBAR_TLC_BREAKPOINT}px)`,
  );
  const isNotTlcSmallScreen = useMediaQuery(
    `(max-width: ${SIDEBAR_BREAKPOINT}px)`,
  );
  const { classes: classesSrc, cx } = useStyles({
    pathname,
    isTlcSmallScreen,
    isNotTlcSmallScreen,
    hideHeader,
  });
  const classes = {
    ...classesSrc,
    template: classesSrc[`template${variantSideBar}`],
  };
  const [isSidebarOpen, setSidebarOpen] = useState(false);

  const isMobile = useMediaQuery(
    `(max-width: ${getSidebarBreakpoint(pathname)}px)`,
  );
  const handleSidebarToggle = () => setSidebarOpen(!isSidebarOpen);

  const datacyName = useDatacyName();
  const headerDatacyName = useDatacyName('header');
  const sidebarDatacyName = useDatacyName('sidebar');
  const theme = useTheme() as any;

  return (
    <div className={cx(classes.root, classes.template)} datacy={datacyName}>
      {!hideHeader && theme.layout.headerHeight > 0 && (
        <DatacyContext.Provider value={headerDatacyName}>
          <div layout-gridkey="header" datacy={headerDatacyName}>
            {header}
          </div>
        </DatacyContext.Provider>
      )}
      {sideBar && (
        <DatacyContext.Provider value={sidebarDatacyName}>
          <div layout-gridkey="sidebar" datacy={sidebarDatacyName}>
            {isMobile ? (
              <>
                <Drawer
                  variant="persistent"
                  anchor="left"
                  open={isSidebarOpen}
                  classes={{
                    paper: classes.sidebarMobileDrawer,
                  }}
                  ModalProps={{
                    keepMounted: true,
                  }}
                >
                  <SidebarToggle
                    width={SIDEBAR_EXPAND_BUTTON_WIDTH}
                    open={isSidebarOpen}
                    onClick={handleSidebarToggle}
                  >
                    {sideBar}
                  </SidebarToggle>
                </Drawer>
                <SidebarToggle
                  width={SIDEBAR_EXPAND_BUTTON_WIDTH}
                  onClick={handleSidebarToggle}
                />
              </>
            ) : (
              <Hidden smDown implementation="css">
                {sideBar}
              </Hidden>
            )}
          </div>
        </DatacyContext.Provider>
      )}
      <MiniOpenTok />
      <VideoMeeting />
      <div
        id="layout-content"
        layout-gridkey="content"
        className="flex flex-col"
      >
        {content}
      </div>
      <div id="portal-root" />
    </div>
  );
};

export default Layout;
