import { actionsGeneratorV2 } from '@docavenue/core';

type SubstituedListParamsType = {
  userId: string;
  centerId: string;
  recursive?: boolean;
};

type PractitionersListParamsType = {
  centerId: string;
  term?: string;
  limit?: number;
  onlyWithAgenda?: boolean;
  recursive?: boolean;
};

export function isSubstituedListParams(
  params: Record<string, any>,
): params is SubstituedListParamsType {
  return params.centerId && params.userId;
}

const practitionersActions = actionsGeneratorV2({
  resource: 'practitioners',
  disableConcurrency: {
    isGetListDisableConcurrency: true,
    isGetOneDisableConcurrency: true,
  },
});

const { getList: _getList } = practitionersActions;

const getList = (params: PractitionersListParamsType) =>
  _getList({ onlyWithAgenda: true, ...(params || {}) }); // Filter connect only practitioners by default

const getSubstitutedList = (
  params: SubstituedListParamsType,
  options: Object = {},
) =>
  _getList(params, {
    chunkUrlResource: 'users',
    chunkResource: 'substitutedPractitioners',
    ...options,
  });

export default {
  ...practitionersActions,
  getList,
  getSubstitutedList,
};
