import { actionsGeneratorV2, DELETE_SUCCESS } from '@docavenue/core';
import {
  AppointmentCreateDTO,
  AppointmentGetDTO,
  AppointmentUpdateDTO,
} from '@maiia/model/generated/model/api-pro/api-pro';

const resource = 'appointments';

const appointmentsActions = actionsGeneratorV2({
  resource,
});

const {
  deleteManyPost: _deleteManyPost,
  updateManyPost: _updateManyPost,
} = appointmentsActions;

const getList = (
  params: Object = {},
  options: Object = {},
  getId?: (item: any) => string,
) =>
  appointmentsActions.getList(
    params,
    {
      chunkUrlResource: 'v2/appointments',
      ...options,
    },
    getId,
  );

const getModificationHistories = (appointmentId: string) =>
  appointmentsActions.getOne(appointmentId, {
    chunkResource: 'modification-histories',
    params: {
      aggregateWith: 'patient,practitioner,user',
    },
  });

const deleteManyPost = (
  appointmentIds: string[],
  options: Record<string, any>,
) =>
  _deleteManyPost(appointmentIds, {
    chunkUrlResource: 'delete-appointments',
    ...(options || {}),
  });

const updateManyPost = (
  appointments: AppointmentUpdateDTO[],
  options: Record<string, any>,
) =>
  _updateManyPost(appointments, {
    chunkUrlResource: 'update-appointments',
    ...(options || {}),
  });

const createMany = (
  appointments: AppointmentCreateDTO[],
  options: Record<string, any> = {},
) =>
  _updateManyPost(appointments, {
    chunkResource: 'batch',
    ...options,
  });

const deleteFromItemsDict = (appointment: AppointmentGetDTO) => ({
  resource,
  type: DELETE_SUCCESS,
  payload: appointment,
  query: { params: appointment },
});

export default {
  ...appointmentsActions,
  getList,
  getModificationHistories,
  deleteManyPost,
  updateManyPost,
  createMany,
  deleteFromItemsDict,
};
