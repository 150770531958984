/* eslint-disable import/no-cycle */
import { actionsGeneratorV2, UPDATE } from '@docavenue/core';
import { User } from '@maiia/model/generated/model/api-pro/api-pro';

import {
  ChatMessage,
  ChatReplyMessage,
  ComputedChatMessage,
  MarketingCampaignVoteType,
} from '../../types/chat.types';
import {
  ACKNOWLEDGE_SUCCESS,
  CLEAR_OLD_CHATROOM,
  DECREMENT_REPLY_NUMBER,
  GET_FIRST_UNREAD_MESSAGE_SUCCESS,
  GET_LATEST_SUCCESS,
  GET_UNREAD_COUNT_SUCCESS,
  INCREMENT_REPLY_NUMBER,
  RESET_MESSAGES,
  SAVE_EDIT_DRAFT,
  SET_CHAT_ROOM_INCOMING_CALL,
  SET_CURRENT_CHATROOM_ID,
  SET_IS_CHATROOM_VISIBLE,
  SET_IS_TOUCHING_BOTTOM,
  TOGGLE_DELETE,
  TOGGLE_EDIT,
  UNREAD_MESSAGE_SUCCESS,
  UPDATE_TELE_DATA_EXPERT,
} from './constants';

const chatMessagesActions = actionsGeneratorV2({
  resource: 'chatMessages',
  chunkUrlResource: 'chat-messages',
  disableConcurrency: {
    isGetListDisableConcurrency: true,
    isGetOneDisableConcurrency: true,
  },
});

const {
  create,
  getList,
  removeItem,
  updateOne,
  uploadMany,
} = chatMessagesActions;

const acknowledge = (options: Object = {}) =>
  create(
    {},
    {
      chunkResource: 'acknowledge-messages',
      ...options,
    },
  );

const acknowledgeSuccess = (payload: Object = {}, options: Object = {}) => ({
  type: ACKNOWLEDGE_SUCCESS,
  ...chatMessagesActions.common,
  ...options,
  payload,
  resource: chatMessagesActions.resource,
});

const getLatest = (params: Object, options: Object = {}) =>
  getList(params, {
    chunkResource: 'latest-messages',
    ...options,
  });

const getLatestSuccess = (
  latestChatMessages: ChatMessage[],
  options: Object = {},
) => ({
  type: GET_LATEST_SUCCESS,
  ...chatMessagesActions.common,
  ...options,
  payload: latestChatMessages,
  resource: chatMessagesActions.resource,
});

const getUnreadCount = (centerId: string | null, options = {}) =>
  getList(
    { centerId },
    {
      chunkResource: 'unread-messages',
      ...options,
    },
  );

const getUnreadCountSuccess = (
  unreadMessageCountByChatRoom: Array<{
    chatRoomId: string;
    centerId: string;
    unreadMessageCount: number;
  }>,
  options: Object = {},
) => ({
  type: GET_UNREAD_COUNT_SUCCESS,
  ...chatMessagesActions.common,
  ...options,
  payload: unreadMessageCountByChatRoom,
  resource: chatMessagesActions.resource,
});

const getFirstUnreadMessageSuccess = (
  payload: Object = {},
  options: Object = {},
) => ({
  type: GET_FIRST_UNREAD_MESSAGE_SUCCESS,
  ...chatMessagesActions.common,
  ...options,
  payload,
  resource: chatMessagesActions.resource,
});

const setCurrentChatRoomId = (payload: Object = {}, options: Object = {}) => ({
  type: SET_CURRENT_CHATROOM_ID,
  ...chatMessagesActions.common,
  ...options,
  payload,
  resource: chatMessagesActions.resource,
});

const setChatRoomIncomingCall = (
  payload: ChatMessage | null,
  options: Object = {},
) => ({
  type: SET_CHAT_ROOM_INCOMING_CALL,
  ...chatMessagesActions.common,
  ...options,
  payload,
  resource: chatMessagesActions.resource,
});

const removeFailedMessage = (id: string, chatRoomId: string) => ({
  ...removeItem(id, { params: { chatRoomId } }),
  skipExtendedReducer: true,
});

const toggleDelete = (message: ChatMessage | null) => ({
  type: TOGGLE_DELETE,
  ...chatMessagesActions.common,
  payload: message,
  resource: chatMessagesActions.resource,
});

const toggleEdit = (
  message: (ChatMessage | ComputedChatMessage) & {
    originalMessageContent: string;
  },
) => ({
  type: TOGGLE_EDIT,
  ...chatMessagesActions.common,
  payload: message,
  resource: chatMessagesActions.resource,
});

const saveEdit = (message: ChatMessage | ComputedChatMessage) => ({
  type: SAVE_EDIT_DRAFT,
  ...chatMessagesActions.common,
  payload: message,
  resource: chatMessagesActions.resource,
});

const setIsTouchingBottom = (isTouchingBottom: boolean) => ({
  type: SET_IS_TOUCHING_BOTTOM,
  ...chatMessagesActions.common,
  payload: isTouchingBottom,
  resource: chatMessagesActions.resource,
});

const setIsChatRoomVisible = (isChatRoomVisible: boolean) => ({
  type: SET_IS_CHATROOM_VISIBLE,
  ...chatMessagesActions.common,
  payload: isChatRoomVisible,
  resource: chatMessagesActions.resource,
});

const incrementReplyNumber = (
  message: ChatMessage,
  reply: ChatReplyMessage,
) => ({
  type: INCREMENT_REPLY_NUMBER,
  ...chatMessagesActions.common,
  payload: { message, reply },
  resource: chatMessagesActions.resource,
});

const decrementReplyNumber = (
  message: ChatMessage | ChatReplyMessage | null,
  reply: ChatReplyMessage,
) => ({
  type: DECREMENT_REPLY_NUMBER,
  ...chatMessagesActions.common,
  payload: { message, reply },
  resource: chatMessagesActions.resource,
});

const clearOldChatRoom = (chatRoomId: string, messagesToKeep: number) => ({
  type: CLEAR_OLD_CHATROOM,
  ...chatMessagesActions.common,
  payload: { chatRoomId, messagesToKeep },
  resource: chatMessagesActions.resource,
});

const updateTaskStatus = (chatMessagesId: string, status: string) =>
  updateOne(
    {},
    {
      chunkResource: `task/status/${chatMessagesId}`,
      type: UPDATE,
      params: { status },
    },
  );

const createTask = (
  data: Object,
  options: { params: { chatMessagesId: string } },
) => {
  const { params } = options || {};
  const { chatMessagesId, ...restParams } = params;
  return updateOne(data, {
    chunkUrlResource: 'chat-messages/task',
    type: UPDATE,
    ...{
      ...options,
      params: { ...restParams },
    },
  });
};

const resetMessages = () => ({
  type: RESET_MESSAGES,
  ...chatMessagesActions.common,
  payload: {},
  resource: chatMessagesActions.resource,
});

const updateTeleDataExpert = (message: ChatMessage, expert: User) => ({
  type: UPDATE_TELE_DATA_EXPERT,
  ...chatMessagesActions.common,
  payload: { message, expert },
  resource: chatMessagesActions.resource,
});

const unreadMessage = (chatMessagesId: string) =>
  updateOne(
    {},
    {
      chunkResource: `${chatMessagesId}/isRead`,
      type: UPDATE,
      params: { isRead: 'false' },
    },
  );

const unreadMessageSuccess = (
  message?: ChatMessage,
  userId?: string | null,
) => ({
  type: UNREAD_MESSAGE_SUCCESS,
  ...chatMessagesActions.common,
  payload: { message, userId },
  resource: chatMessagesActions.resource,
});

const updateMarketingCampaignVote = (
  chatMessageId: string,
  voteType: MarketingCampaignVoteType,
) =>
  updateOne(
    {},
    {
      chunkResource: `${chatMessageId}/maiia-info/vote`,
      type: UPDATE,
      params: { voteType },
    },
  );

const uploadMultiDocuments = params =>
  uploadMany(params, {
    chunkResource: 'attachments',
    isListDst: true,
  });

export default {
  ...chatMessagesActions,
  acknowledge,
  acknowledgeSuccess,
  getLatest,
  getLatestSuccess,
  getUnreadCount,
  getUnreadCountSuccess,
  getFirstUnreadMessageSuccess,
  setCurrentChatRoomId,
  removeFailedMessage,
  toggleDelete,
  toggleEdit,
  saveEdit,
  setIsTouchingBottom,
  setIsChatRoomVisible,
  incrementReplyNumber,
  decrementReplyNumber,
  clearOldChatRoom,
  updateTaskStatus,
  createTask,
  resetMessages,
  updateTeleDataExpert,
  setChatRoomIncomingCall,
  unreadMessage,
  unreadMessageSuccess,
  updateMarketingCampaignVote,
  uploadMultiDocuments,
};
