import {
  AppointmentGetDTO,
  TimeSlotGetDTO,
} from '@maiia/model/generated/model/api-pro/api-pro';

export const SET_DATE = 'SET_DATE';
export const SET_KEY_BY_VALUE = 'SET_KEY_BY_VALUE';
export const RESTORE_SHORTCUT = 'RESTORE_SHORTCUT';
export const SET_TIMESLOT_STEP = 'SET_TIMESLOT_STEP';
export const SET_CONFIG = 'SET_CONFIG';
export const SET_CALENDAR_EVENT = 'SET_CALENDAR_EVENT';
export const SET_CURRENT_SLOT = 'SET_CURRENT_SLOT';
export const SET_DUPLICATE_MODE = 'SET_DUPLICATE_MODE';

// WARNING: THE VALUE CAN'T BE CHANGED big-calendar DEPENDS ON HIS
export const DAY = 'day';
export const WEEK = 'week';
export const MONTH = 'month';
export const AGENDA = 'agenda';

export const AGENDA_SETTINGS_VIEW_DAY = 'DAY';
export const AGENDA_SETTINGS_VIEW_WEEK = 'WEEK';
export const AGENDA_SETTINGS_VIEW_MONTH = 'MONTH';
export const AGENDA_SETTINGS_VIEW_AGENDA = 'PLANNING';

export const matchAgendaSettingsViewToView = {
  [AGENDA_SETTINGS_VIEW_DAY]: DAY,
  [AGENDA_SETTINGS_VIEW_WEEK]: WEEK,
  [AGENDA_SETTINGS_VIEW_AGENDA]: AGENDA,
} as const;
export const matchViewToAgendaSettingsView = {
  [DAY]: AGENDA_SETTINGS_VIEW_DAY,
  [WEEK]: AGENDA_SETTINGS_VIEW_WEEK,
  [AGENDA]: AGENDA_SETTINGS_VIEW_AGENDA,
} as const;

type Incr = 1 | -1;

// export type CalendarActions = {
//   setConfig: (config: Object) => Action,
//   setStepTimeslots: (incr: Incr) => Action,
//   restoreShortcut: () => { type: string },
//   setView: (view: string) => Action,
//   setItem
// };

const setItem = (item: AppointmentGetDTO | TimeSlotGetDTO) => ({
  type: SET_CALENDAR_EVENT,
  payload: { item },
});

export const restoreShortcut = () => ({
  type: RESTORE_SHORTCUT,
});

export const setStepTimeslots = (incr: Incr) => ({
  type: SET_TIMESLOT_STEP,
  payload: { incr },
});

export const setConfig = (config: Object) => ({
  type: SET_CONFIG,
  payload: { config },
});

export const setIsDuplicateMode = (isDuplicateMode: any) => ({
  type: SET_DUPLICATE_MODE,
  payload: { isDuplicateMode },
});

const calendarActions = {
  setItem,
  restoreShortcut,
  setStepTimeslots,
  setConfig,
  setIsDuplicateMode,
};

export default calendarActions;
