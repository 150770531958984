import React, { RefObject } from 'react';
import { NextRouter } from 'next/router';
import moment from 'moment-timezone';
// eslint-disable-next-line import/no-extraneous-dependencies
import WaveSurfer from 'wavesurfer.js';

import { UrlObject } from 'url';

export type UseIntersectionObserverConfig = {
  root: RefObject<HTMLElement>;
  target: RefObject<HTMLElement>;
  onIntersect: IntersectionObserverCallback;
  threshold: number | number[];
  rootMargin?: string;
};

export type ChatMessageGetListParams = {
  chatRoomId: string | string[] | undefined;
  limit: number;
  page?: number;
  taskStatusFilter?: string;
};
export type AudioPlayingInfo = {
  url: string | null;
  ref: WaveSurfer | null;
};
export type ChatContextValue = {
  t: Function;
  useRouter: () => Pick<NextRouter, 'query' | 'pathname'> & {
    replace: (route: UrlObject, hasToScroll: boolean) => Promise<boolean>;
  };
  useIntersectionObserver: (config: UseIntersectionObserverConfig) => void;
  moment?: typeof moment;
  CHAT_PAGINATION_LIMIT: number;
  redirectOnSelfChatRoomOnChangeCenter: boolean;
  isChatRoomVisible: boolean;
  hasBeenHiddenChatRoom: boolean;
  setHasBeenHiddenChatRoom: React.Dispatch<React.SetStateAction<boolean>>;
  audioPlayingInfo: AudioPlayingInfo;
  setAudioPlayingInfo: React.Dispatch<React.SetStateAction<AudioPlayingInfo>>;
  parentIdToRedirectTo: string | null;
  setParentIdToRedirectTo: React.Dispatch<React.SetStateAction<string | null>>;
  childMessageIdToRedirectTo: string | null;
  setChildMessageIdToRedirectTo: React.Dispatch<
    React.SetStateAction<string | null>
  >;
};

const ChatContext = React.createContext<ChatContextValue>({
  t: (key: string, variables?: Object) =>
    `${key}${variables ? ` ${JSON.stringify(variables)}` : ''}`,
  useRouter: () => ({} as any),
  useIntersectionObserver: () => {},
  CHAT_PAGINATION_LIMIT: 150,
  redirectOnSelfChatRoomOnChangeCenter: true,
  isChatRoomVisible: true,
  hasBeenHiddenChatRoom: false,
  setHasBeenHiddenChatRoom: () => {},
  audioPlayingInfo: { url: null, ref: null },
  setAudioPlayingInfo: () => {},
  parentIdToRedirectTo: null,
  setParentIdToRedirectTo: () => {},
  childMessageIdToRedirectTo: null,
  setChildMessageIdToRedirectTo: () => {},
});

export default ChatContext;
