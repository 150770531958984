/* eslint-disable import/no-cycle */
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRouter } from 'next/router';
import find from 'lodash/find';
import moment from 'moment';
import { createSelector } from 'reselect';

import { stringify } from 'querystring';

import { asyncActions } from '@docavenue/core';
import {
  Center,
  Patient,
  Practitioner,
  PractitionerDTO,
  ProAgendaSettingsDTO,
  SubstitutedPractitioner,
  VideoProMeetingDTO,
} from '@maiia/model/generated/model/api-pro/api-pro';

import { findWeekTemplateCycleByWeek } from '../../components/utils/tools';
import { Dictionary, VideoSessionAggregate } from '../../types/pro.types';
import {
  centersActions,
  offsetActions,
  practitionersDefaultActions,
} from '../actions';
import config from '../config';
import {
  AGENDA_APOINTMENT_ALLOWED_LENGTH,
  DEFAULT_AGENDA_APPOINTMENT_LENGTH,
  DEFAULT_AGENDA_AVAILABILITY_MAX_WEEK_PERIOD,
  FRIDAY,
  MONDAY,
  PRACTITIONER,
  REGULATOR,
  SATURDAY,
  SECRETARY,
  STRIPE_STATUS_ACTIVE,
  STRIPE_STATUS_VERIFIED,
  SUNDAY,
  SYNCHRONIZED,
  TELESECRETARY,
  THURSDAY,
  TUESDAY,
  VACCINATION_CENTER_CODE,
  WEDNESDAY,
} from '../constants';
import { MiniVideoSessionState } from '../reducer/miniVideoSession';
import { RootState } from '../reducer/rootState';
import {
  patientsSelectors,
  practitionersSelectors,
  statisticsSelectors,
  weeksSelectors,
} from '../selector';
import {
  getDisplayDaysByAgendas,
  getOffset,
  getPractitionerDisplayName,
  getWeekDaySettings,
} from '../utils';
import { useDate } from './date';
import { useTimeSlotResourcesKeys } from './query';

import { isVideoSessionOngoing } from '@/components/organisms/Tlc/tlc.utils';

export const useCenterId = () =>
  useSelector<string | null>(store => store.centers.item?.id ?? null);

export const useRootCenterId = () =>
  useSelector<string | null>(store => store.centers.rootCenterId);

export const useRootCenterIdFromURL = () => {
  const { query } = useRouter();
  const { rootCenterId } = query;
  const centerId = useCenterId();
  return rootCenterId || centerId;
};

export const useCenterProFunctionalities = (centerId?: string) => {
  const centersItems = useSelector(state => state.centers.items);
  const centerItem = useSelector(store => store.centers.item);
  if (centerId && centersItems.length) {
    return find(centersItems, { id: centerId })?.proFunctionalities || null;
  }
  return centerItem?.proFunctionalities || null;
};

export const useIsUserRightV2Enabled = (centerId?: string) => {
  const proFunctionalities = useCenterProFunctionalities(centerId);
  return proFunctionalities?.USER_RIGHT_V2 || false;
};

export const useIsEhrFeatureFlagEnabled = () => {
  const currentCenterId = useCenterId() || undefined;

  const proFunctionalities = useCenterProFunctionalities(currentCenterId);
  return proFunctionalities?.EHR || false;
};

export const useCenterSpeciality = () =>
  useSelector<Center['speciality'] | null>(
    store => store.centers.item?.speciality ?? null,
  );

export const useIsVaccinationCenter = () => {
  const center = useSelector(state => state.centers.item);
  const centerSpeciality = useCenterSpeciality();
  return Boolean(
    centerSpeciality?.code === VACCINATION_CENTER_CODE ||
      center?.isVaccinationPlace,
  );
};

export const usePractitionerId = () =>
  useSelector<string | null>(store => store.practitioners.item?.id ?? null);

export const usePatientId = () =>
  useSelector<string | null>(store => store.patients.item?.id ?? null);

export const useUserId = () =>
  useSelector<string | null>(state => state.users.item?.id ?? null);

export const useCurrentUser = () => useSelector(state => state.users.item);

export const useIsKeycloakEnabled = () =>
  useSelector<boolean | null>(
    state =>
      (!!state.users.item?.userProInformation?.uaaId &&
        !!state.users.item?.userProInformation?.isKeyCloakEnabled) ??
      null,
  );

export const useVideoSessionId = () =>
  useSelector<string | null>(state => state.videoSessions.item?.id ?? null);

export const useVideoMeetingId = () =>
  useSelector<string | null>(store => store.videoProMeeting.item?.id ?? null);

export const useCurrentVideoSession = () =>
  useSelector<VideoSessionAggregate | null>(state => state.videoSessions.item);

export const useCurrentVideoMeeting = () =>
  useSelector<VideoProMeetingDTO | null>(state => state.videoProMeeting.item);

export const useVideoSessions = () =>
  useSelector<VideoSessionAggregate[]>(state => state.videoSessions.items);

/**
 * Whether there's an ongoing TLC.
 * Both check on videoSessions's items and item
 */
export const useIsTlcStarted = () => {
  const videoSessions = useVideoSessions();
  const videoSession = useCurrentVideoSession();
  return (
    videoSessions.some(session => isVideoSessionOngoing(session)) ||
    (!!videoSession && isVideoSessionOngoing(videoSession))
  );
};

export const useTlcConflict = () =>
  useSelector(state => state.videoSessions.error?.status === 409);

export const useIsSubstitute = () =>
  useSelector(
    state => state.users.item?.role?.name === 'SUBSTITUTE_PRACTITIONER',
  );

export const useAgendaSettings = () =>
  useSelector<ProAgendaSettingsDTO | null>(store => store.agendaSettings.item);

export const useDefaultAgendaSettings = () => {
  const centerId = useCenterId();
  const practitionerId = usePractitionerId();
  const agendaSettingsDefaults = useSelector<ProAgendaSettingsDTO[]>(
    store => store.agendaSettingsDefault.items,
  );
  const isSubstitute = useIsSubstitute();
  return useMemo<ProAgendaSettingsDTO | null>(
    () =>
      find(
        agendaSettingsDefaults,
        isSubstitute ? { centerId, practitionerId } : ({ centerId } as any),
      ) ?? null,
    [agendaSettingsDefaults, centerId, practitionerId, isSubstitute],
  );
};

export const useIsDefaultOfferTeleconsultation = () => {
  const agendaSettingsDefault = useDefaultAgendaSettings();
  return agendaSettingsDefault?.isTeleconsultation ?? false;
};

export const useIsOfferTeleconsultation = () => {
  const agendaSettings = useAgendaSettings();
  return agendaSettings?.isTeleconsultation ?? false;
};

function isSubstitutedPractitioner(
  data: SubstitutedPractitioner | Practitioner,
): data is SubstitutedPractitioner {
  return !!(data as SubstitutedPractitioner).practitionerId;
}

const useHasTlcOffer = () => {
  const agendaSettings = useSelector(state => state.agendaSettings.items);

  return (practitioner: SubstitutedPractitioner | Practitioner) => {
    const practitionerId = isSubstitutedPractitioner(practitioner)
      ? practitioner.practitionerId
      : practitioner.id;

    return agendaSettings.some(
      agendaSetting =>
        agendaSetting.practitionerId === practitionerId &&
        (agendaSetting.isTeleconsultation ||
          agendaSetting.isFreeTeleconsultation),
    );
  };
};

export const useHasTransactionsAccess = () => {
  const isDefaultOfferTLC = useIsDefaultOfferTeleconsultation();
  const currentUser = useCurrentUser();
  const hasTlcOffer = useHasTlcOffer();
  const centerPractitioners = useSelector(state => state.practitioners.items);
  let hasAccess: boolean;

  switch (currentUser?.role.name) {
    case 'SECRETARY':
      hasAccess = centerPractitioners.some(hasTlcOffer);
      break;
    case 'PRACTITIONER':
      hasAccess = isDefaultOfferTLC;
      break;
    case 'SUBSTITUTE_PRACTITIONER':
      hasAccess =
        isDefaultOfferTLC ??
        currentUser.userProInformation?.substitutedPractitioners?.some(
          hasTlcOffer,
        )!!;
      break;

    default:
      hasAccess = false;
  }

  return hasAccess;
};

export const useIsDefaultOfferOnlineAppointment = () => {
  const agendaSettingsDefault = useDefaultAgendaSettings();
  return agendaSettingsDefault?.isOnlineAppointment ?? false;
};

export const useIsOfferOnlineAppointment = () => {
  const agendaSettings = useAgendaSettings();
  return agendaSettings?.isOnlineAppointment ?? false;
};
export const useIsOfferFreeTeleconsultation = () => {
  const agendaSettings = useAgendaSettings();
  return agendaSettings?.offer?.freeTeleconsultation ?? false;
};

export const useAgendaSettingsDefault = (): ProAgendaSettingsDTO | null => {
  const centerId = useCenterId();
  const practitionerId = usePractitionerId();
  const isSubstitute = useIsSubstitute();

  return useSelector<ProAgendaSettingsDTO | null>(store =>
    isSubstitute && practitionerId
      ? find(store.agendaSettingsDefault.items, {
          centerId,
          practitionerId,
        } as any) ?? null
      : find(store.agendaSettingsDefault.items, {
          centerId,
        } as any) ?? null,
  );
};

export const useDefaultPractitionerId = (): string | null =>
  useAgendaSettingsDefault()?.practitionerId ?? null;

export const useTlcCenterAmc = () => {
  const centerId = useCenterId();
  const practitionerId = useDefaultPractitionerId();
  const isTlcCenter = useSelector(
    store => !!store.centers.item?.proFunctionalities?.TLC_CENTER,
  );
  const isTlcAmc = useSelector(
    store => !!store.centers.item?.proFunctionalities?.TLC_AMC,
  );

  return {
    isTlcCenter,
    isTlcAmc,
    isTlcCenterOrAmc: isTlcCenter || isTlcAmc,
    isTlcCenterOrAmcAndHasPractitionerId:
      (isTlcCenter || isTlcAmc) && !!practitionerId,
    isTlcCenterOrAmcAndHasCenterId: (isTlcCenter || isTlcAmc) && !!centerId,
  };
};

export const useCpsId = () => {
  const defaultPractitionerId = useDefaultPractitionerId();
  const defaultPractitioner = useSelector(state =>
    practitionersSelectors.getById(state, defaultPractitionerId as string),
  )!;

  return defaultPractitioner?.cpsId;
};

export const useIsCpsIdCertified = () => {
  const defaultPractitionerId = useDefaultPractitionerId();
  const defaultPractitioner = useSelector(state =>
    practitionersSelectors.getById(state, defaultPractitionerId!),
  );

  return !!defaultPractitioner?.isCpsIdCertified;
};

export const useDefaultCenterId = (): string | null =>
  useSelector(store => store.agendaSettingsDefault.item?.centerId) ?? null;

export const useDisplaySettingsDurationSlot = () => {
  const durationSlot = useSelector(
    state =>
      state.agendaSettings.item?.agendaDisplaySettings?.displayDurationSlot ||
      DEFAULT_AGENDA_APPOINTMENT_LENGTH,
  );
  if (AGENDA_APOINTMENT_ALLOWED_LENGTH.indexOf(durationSlot) === -1) {
    // eslint-disable-next-line no-console
    console.warn(
      `Unsupported setting: timeSlice should be 5, 10, 15, 20, 30, 40 or 60 but it is '${durationSlot}'`,
    );
    return DEFAULT_AGENDA_APPOINTMENT_LENGTH;
  }
  return durationSlot;
};

export const useCurrentWeekTemplateCycle = () => {
  const router = useRouter();
  const { date } = router.query;
  const weekTemplateCycles = useSelector(
    state => state.weekTemplateCycles.items,
  );
  const weeks = useSelector(weeksSelectors.getWeeks);

  return useMemo(
    () =>
      findWeekTemplateCycleByWeek(
        date as string | undefined,
        weekTemplateCycles,
        weeks,
      ) ?? undefined,
    [date, weekTemplateCycles, weeks],
  );
};

export const usePractitionersCount = () => {
  const pratCount = useSelector(practitionersSelectors.getItems).length;
  return pratCount;
};

export const useIsPractitioner = () => {
  const role = useSelector(state => state.users.item?.role?.name);
  return role === PRACTITIONER;
};

export const useIsSecretary = () => {
  const role = useSelector(state => state.users.item?.role?.name);
  return role === SECRETARY;
};

export const useIsRegulator = () => {
  const role = useSelector(state => state.users.item?.role?.name);
  return role === REGULATOR;
};

export const useIsTelesecretary = () => {
  const role = useSelector(state => state.users.item?.role?.name);
  return role === TELESECRETARY;
};

export const useIsFreemium = () =>
  useSelector<boolean>(state => Boolean(state.users.item?.isFreemium));

export const useTimezone = () => {
  const dispatch = useDispatch();
  const date = useDate();
  const center = useSelector(state => state.centers.item);
  useEffect(() => {
    if (center?.timeZone) {
      const offset = getOffset(center, date);
      dispatch(offsetActions.setOffset({ offset }));
    }
  }, [center?.timeZone]);
};

export const usePrescritionUrl = () => {
  const defaultPractitioner = useSelector(state =>
    practitionersSelectors.getById(
      state,
      state.users.item?.userProInformation?.defaultPractitionerId || '',
    ),
  );
  const center = useSelector(state => state.centers.item);
  const patient = useSelector(patientsSelectors.getItem);

  const isDefaultOfferTeleconsultation = useIsDefaultOfferTeleconsultation();

  if (
    !isDefaultOfferTeleconsultation ||
    !defaultPractitioner ||
    !center ||
    !patient
  ) {
    return null;
  }

  const { publicInformation } = center;

  const query = {
    practitioner: getPractitionerDisplayName(defaultPractitioner),
    address1: `${publicInformation?.address?.number || ''} ${publicInformation
      ?.address?.street || ''}`.trim(),
    address2: `${publicInformation?.address?.zipCode || ''} ${publicInformation
      ?.address?.city || ''}`.trim(),
    phone: publicInformation?.officeInformation?.phoneNumber,
    specialiteLabel: defaultPractitioner.speciality?.name,
    rpps: defaultPractitioner.cpsId,
    patientFirstName: patient.firstName,
    patientFamilyName: patient.lastName,
    patientSexe: patient.sex,
    patientBirthDate:
      patient.birthDate && moment(patient.birthDate).format('YYYYMMDD'),
  };

  const url = `${config.get('PRESCRIPTION_URL')}?${stringify(
    JSON.parse(JSON.stringify(query)),
  )}`;

  return url;
};

export const useQueryDataForSelector = (
  queryDataName: string,
  selector: (state: RootState) => any,
) => {
  const { query } = useRouter();
  const queryData = query && query[queryDataName];
  const [queryDataState, setQueryDataState] = useState(queryData);
  const data = useSelector(selector);
  useEffect(() => {
    setQueryDataState(queryData);
  }, [data]);
  return queryDataState;
};

export const useQueryDataListForSelector = (
  queryDataNames: string[],
  selector: (state: RootState) => any,
) => {
  const { query } = useRouter();
  const queryData: Dictionary<string> = {};
  queryDataNames.forEach((name: string) => {
    queryData[name] = (query[name] as string | undefined) || '';
  });

  const [queryDataState, setQueryDataState] = useState(queryData);
  const data = useSelector(selector);
  useEffect(() => {
    setQueryDataState(queryData);
  }, [data]);
  return queryDataState;
};

export const usePractitionerIdByProfile = () => {
  const practitionerIdByProfile = useQueryDataForSelector(
    'practitionerId',
    state => state.profiles.item,
  );
  return practitionerIdByProfile;
};

export const useCenterIdByProfile = () => {
  const centerIdByProfile = useQueryDataForSelector(
    'centerId',
    state => state.profilesParent.item,
  );
  return centerIdByProfile;
};

export const useIsCenterMode = () => {
  const {
    query: { tab, practitionerId },
  } = useRouter();

  const practitionerIdByProfile = usePractitionerIdByProfile();
  return !!(practitionerId && tab === 'profile'
    ? practitionerIdByProfile
    : practitionerId);
};

export const useSettingsPageIdsForSelector = (
  selector: (state: RootState) => any,
) => {
  const settingsPageIds = useQueryDataListForSelector(
    ['centerId', 'practitionerId'],
    selector,
  );
  return settingsPageIds;
};

export const usePatient = () =>
  useSelector<Patient | null>(patientsSelectors.getItem);

export const useHasPatientAccessRights = (patient?: Patient) => {
  const currentPatient = usePatient();
  const user = useCurrentUser();

  if (user) {
    const centerIds = user.commonPatientBases || [];

    if (patient)
      return !!patient?.centerId && centerIds.includes(patient?.centerId);

    return (
      !!currentPatient?.centerId && centerIds.includes(currentPatient?.centerId)
    );
  }
  return false;
};

export const useProfileIds = () => {
  const profileIds = useSettingsPageIdsForSelector(
    state => state.profiles.item,
  );
  return profileIds;
};

export const useConsultationReasonsIds = () =>
  useSettingsPageIdsForSelector(state => state.consultationReasons.items);

export const useResourcesIds = () =>
  useSettingsPageIdsForSelector(state => state.resources.items);

export const useNotificationSettingsIds = () =>
  useSettingsPageIdsForSelector(state => state.notificationSettings.item);

export const useAgendaSettingsIds = () =>
  useSettingsPageIdsForSelector(state => state.agendaSettings.item);

const agendaSettingsDefaultSelector = createSelector(
  state => state.agendaSettingsDefault.items,
  items => items.filter(agendaSettings => agendaSettings.isChat),
);

export const useAgendaSettingsDefaultWithChat = () =>
  useSelector(agendaSettingsDefaultSelector);

const agendaSettingsSelector = createSelector(
  state => state.agendaSettings.items,
  items => items.filter(agendaSettings => agendaSettings.isChat),
);

export const useIsOfferChat = () => {
  const isPractitioner = useIsPractitioner();
  const agendaSettingsWithChat = useSelector(agendaSettingsSelector);
  const agendaSettingsDefaultWithChat = useAgendaSettingsDefaultWithChat();
  const isRegulatorUser = useIsRegulator();
  if (isRegulatorUser) {
    return true;
  }
  return !!(isPractitioner
    ? agendaSettingsDefaultWithChat.length
    : agendaSettingsWithChat.length);
};

export const useIsOperator = () => {
  const operatorId = useSelector(
    store => store.authentication.item?.operatorId,
  );

  return !!operatorId;
};

export const useSubstituteData = () => {
  const isSubstitute = useIsSubstitute();
  const user = useCurrentUser();
  if (!user || !user?.userProInformation) return {};
  const {
    userProInformation: { firstName, lastName },
    id,
  } = user;
  return isSubstitute ? { firstName, lastName, userId: id } : {};
};

const WEEK_DAYS = {
  MONDAY,
  TUESDAY,
  WEDNESDAY,
  THURSDAY,
  FRIDAY,
  SATURDAY,
  SUNDAY,
} as const;

type WeekDaysType = typeof WEEK_DAYS;
export type Day = WeekDaysType[keyof WeekDaysType];

export const COMPLETE_WEEK: Day[] = [
  MONDAY,
  TUESDAY,
  WEDNESDAY,
  THURSDAY,
  FRIDAY,
  SATURDAY,
  SUNDAY,
];

export const useGetTimeSlotResourcesWeekDaysSettings = () => {
  const { length: nbDisplayedCalendars } = useTimeSlotResourcesKeys();

  const createDefaultWeekDaySetting = useMemo(() => {
    const days = Array.from(
      { length: nbDisplayedCalendars },
      () => COMPLETE_WEEK,
    );
    const weekDaysSettings = getWeekDaySettings(days);
    return weekDaysSettings;
  }, [nbDisplayedCalendars]);
  return createDefaultWeekDaySetting;
};

export const useGetAgendasWeekDaysSettings = () => {
  const agendaSettings = useSelector(state => state.agendaSettings.items);
  const getAgendasWeekDaysSettings = useCallback(
    agendas => {
      const displaySettingsDays = getDisplayDaysByAgendas(
        agendaSettings,
        agendas,
      );
      const agendasWeekDaysSettings = getWeekDaySettings(displaySettingsDays);
      return agendasWeekDaysSettings;
    },
    [agendaSettings],
  );
  return getAgendasWeekDaysSettings;
};

export const useIsTeleSecretary = () => {
  const role = useSelector(state => state.users.item?.role?.name);
  return role === TELESECRETARY;
};

export const useIsLoadingComplexForm = () => {
  const isGetOneLoading = useSelector(
    state => state.complexFormData.isGetOneLoading,
  );
  const itemComplexForm = useSelector(state => state.complexFormData.item);

  return isGetOneLoading && !itemComplexForm;
};

export const useStatistics = () => useSelector(statisticsSelectors.getItem);

export const useSoftwaresSynchronizedList = () =>
  useSelector(state => {
    const list = state?.centers?.item?.centerExternalSyncList || [];
    const softwareList = list.filter(
      ({ software, syncStatus }) =>
        (software?.displayName || '').trim() && syncStatus === SYNCHRONIZED,
    );

    return softwareList || [];
  });

const patientMentionSuggestionsSelector = createSelector(
  [state => state.patients.items],
  patients =>
    patients.map(patient => ({
      id: patient.id,
      name: `${patient.firstName} ${patient.lastName}`,
      data: patient,
    })),
);

export const usePatientMentionSuggestionsSelector = () =>
  useSelector(patientMentionSuggestionsSelector);
export const useVideoMeetingUsers = () =>
  useSelector(state => {
    const chatRooms = state?.chatRooms.items;
    const videoProMeeting = state?.videoProMeeting?.item;
    const users = chatRooms.find(
      room => room.id === videoProMeeting?.chatRoomId,
    )?.users;

    return users || [];
  });

export const useIsConnectedAs = () =>
  useSelector<boolean>(store => !!store.authentication.item?.operatorId);

export const useCanLaunchAssistedTLC = () => {
  const center = useSelector(state => state.centers.item);
  const agendaSettings = useAgendaSettingsDefault();
  const isSecretary = useIsSecretary();
  const isTls = useIsTeleSecretary();

  const hasTLCOffer =
    agendaSettings?.isTeleconsultation && center?.teleconsultationRelay?.id;

  return hasTLCOffer && !isSecretary && !isTls;
};

export const useConventionedActs = (speId?: string) => {
  const specialityId =
    useSelector(store => store.practitioners.item)?.speciality?.id ||
    speId ||
    '';
  const allConventionedActs = useSelector(
    store => store.conventionedActs.items,
  );
  return allConventionedActs.filter(act =>
    act?.specialityIds?.includes(specialityId),
  );
};

export const useIsStripeAccountValid = () => {
  const [isValid, setIsValid] = useState(true);
  const {
    query: { centerId },
  } = useRouter();

  const practitionerId = useDefaultPractitionerId() as string;
  const dispatch = useDispatch();

  const centerStripeInformation = async () =>
    asyncActions(
      dispatch,
      centersActions.getOne(centerId as string, {
        params: { isWithExternalSyncCenters: true },
      }),
    );

  const practitionerStripeInformation = async (): Promise<PractitionerDTO> =>
    asyncActions(
      dispatch,
      practitionersDefaultActions.getOne(practitionerId, {
        params: { centerId },
      }),
    );

  const getStripeInformation = async () => {
    const centerInformation = await centerStripeInformation();

    if (centerInformation?.stripeInformation?.status === STRIPE_STATUS_ACTIVE) {
      setIsValid(true);
      return;
    }
    const practitionerInformation = await practitionerStripeInformation();
    setIsValid(
      practitionerInformation?.stripeInformation?.status ===
        STRIPE_STATUS_VERIFIED &&
        practitionerInformation?.stripeInformation?.cardPayments ===
          STRIPE_STATUS_ACTIVE &&
        practitionerInformation?.stripeInformation?.transfers ===
          STRIPE_STATUS_ACTIVE,
    );
  };

  useEffect(() => {
    getStripeInformation();
  }, [practitionerId, centerId]);

  return isValid;
};
export const useMiniVideoSession = () =>
  useSelector<MiniVideoSessionState>(state => state.miniVideoSession);

/**
 * A premium offer is a payed offer.
 * Each practitioner can have a premium offer or not (check agendaSettings.isPremium property)
 * If no current practitioner we check if every practitioner in the center has premium offer. If not we consider the center to be a non premium center.
 * The component PremiumOfferDialog (in components/molecules/PremiumOfferDialog) can limit access to some features for non premium practitioners.
 */
export const useIsPremiumPractitioner = (practitionerId?: string): boolean => {
  const activePractitionerId = usePractitionerId();
  const _practitionerId = practitionerId ?? activePractitionerId;
  const agendaSettingsList = useSelector(state => state.agendaSettings.items);
  const agendaSettings = agendaSettingsList.find(
    item => item.practitionerId === _practitionerId,
  );
  const isAgendaSettingsPremium = !!agendaSettings?.isPremium;
  const hasOnlyPremiumPractitioners = !!useSelector(
    state => state.centers.item?.hasOnlyPremiumPractitioners,
  );
  const [isPremium, setIsPremium] = useState(
    _practitionerId
      ? isAgendaSettingsPremium || false
      : hasOnlyPremiumPractitioners,
  );

  useEffect(() => {
    if (typeof isAgendaSettingsPremium === 'boolean') {
      setIsPremium(
        _practitionerId
          ? isAgendaSettingsPremium || false
          : hasOnlyPremiumPractitioners,
      );
    }
    // the else of that condition means that the practitioner has changed and thus the agenda setting is loading,
    //  so we keep the old values till the loading finished.
  }, [_practitionerId, isAgendaSettingsPremium, hasOnlyPremiumPractitioners]);

  return isPremium;
};

export const useAvailabilityMaxWeekPeriod = () => {
  const availabilityMaxWeekPeriod = useSelector(
    state =>
      state.agendaSettings.item?.availabilityMaxWeekPeriod ||
      DEFAULT_AGENDA_AVAILABILITY_MAX_WEEK_PERIOD,
  );

  return availabilityMaxWeekPeriod;
};
