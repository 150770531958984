import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useUpdateEffect } from 'react-use';
import { useRouter } from 'next/router';
import { Badge, cn } from '@kiiwi/ui';
import { makeStyles } from 'tss-react/mui';

import { useGet as useGetUnreadMessages } from '@maiia/model/generated/model/api-pro/hooks/proChatMessagesCountUnreadMessages';

import { useTranslation } from '../../../i18n';
import IconButton from '../../atoms/IconButton';
import Tooltip from '../../atoms/Tooltip';

import FontAwesomeIcon from '@/components/atoms/FontawesomeIcon/FontawesomeIcon';
import { chatInvitationsActions } from '@/src/actions';
import Routes from '@/src/constants/routes';
import {
  useGetReceivedInvitations,
  useGetSentInvitations,
} from '@/src/hooks/chatUtils';

const useStyles = makeStyles()((theme: any) => ({
  chatButton: {
    position: 'relative',
    padding: 0,
    '& span': {
      zIndex: theme.zIndex.chatHeaderIcon,
    },
    '&.chatopen::after': {
      content: '""',
      position: 'absolute',
      top: 9,
      left: 9,
      right: 9,
      bottom: 9,
      backgroundColor: theme.palette.accent.dark,
      borderRadius: '50%',
      zIndex: 0,
    },
  },
}));

type Props = {
  onClick: () => void;
  chatOpen: boolean;
  disabled?: boolean;
};
// CHAT ICON IS HERE
const ChatIconButton = (props: Props) => {
  const { onClick, chatOpen, disabled } = props;
  const { classes, cx } = useStyles();
  const { t } = useTranslation('connect');
  const hasReceivedNewInvitation = !!useSelector(
    state => state.chatInvitations.hasReceivedNewInvitation,
  );
  const getSentInvitations = useGetSentInvitations();
  const getReceivedChatInvitations = useGetReceivedInvitations();
  useEffect(() => {
    getSentInvitations();
    getReceivedChatInvitations();
    chatInvitationsActions.hasReceivedNewInvitation(false);
  }, []);
  const router = useRouter();

  const isCreateAppointment = router.pathname === Routes.CREATE_APPOINTMENT;

  useUpdateEffect(() => {
    if (hasReceivedNewInvitation) {
      getReceivedChatInvitations();
      chatInvitationsActions.hasReceivedNewInvitation(false);
    }
  }, [hasReceivedNewInvitation]);

  const { data: unreadMessages } = useGetUnreadMessages({});
  const { count: unreadMessageCount = 0 } = unreadMessages || {};

  return (
    <Tooltip title={t('connect:page_maiia_connect')}>
      <IconButton
        className={cx(classes.chatButton, 'chatbtn')}
        datacy="chat"
        disabled={disabled}
        onClick={onClick}
      >
        <FontAwesomeIcon
          name={chatOpen ? 'messages:solid' : 'messages:regular'}
          className={cn(
            'h-7 w-7 text-white',
            isCreateAppointment && 'text-content-primary',
          )}
        />

        {unreadMessageCount > 0 && (
          // <div className="bg-macro-enable absolute right-0 top-0.5 size-2 rounded-full" />
          <Badge variant="macro" className="absolute -right-1.5 -top-0.5">
            {unreadMessageCount.toString()}
          </Badge>
        )}
      </IconButton>
    </Tooltip>
  );
};

export default ChatIconButton;
