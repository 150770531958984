import { actionsGeneratorV2, PATCH, UPDATE } from '@docavenue/core';
import {
  CenterRights,
  OnfidoVerification,
} from '@maiia/model/generated/model/api-pro/api-pro';

type UpdateAssociatedCentersType = {
  centerRights: CenterRights;
  centerId?: string;
  userId?: string;
};

const usersActions = actionsGeneratorV2({
  resource: 'users',
  disableConcurrency: {
    isGetListDisableConcurrency: true,
    isGetOneDisableConcurrency: true,
  },
});

const { updateOne, patchOne, getOneWithCompositeKey } = usersActions;

const activateOrDeactivateSubstitutePractitioner = (
  data: Object,
  options: { params: { userId: string } },
) => {
  const { params } = options || {};
  const { userId, ...restParams } = params;
  return updateOne(data, {
    chunkUrlResource: 'users',
    chunkResource: 'substitutedPractitioners/activateOrDeactivate',
    chunkId: userId,
    type: UPDATE,
    ...{ ...options, params: restParams },
  });
};

const updateRppsNumber = (params: { userId: string; cpsId: string }) => {
  const { userId, cpsId } = params;
  return updateOne(
    {},
    {
      chunkUrlResource: 'users',
      chunkResource: `substitute/${userId}/updateCpsId/${cpsId}`,
      type: UPDATE,
    },
  );
};

const updateAmNumber = (
  data: Object,
  options: { params: { userId: string; centerId: string } },
) => {
  const { params } = options || {};
  const { userId, centerId, ...restParams } = params;
  return updateOne(data, {
    chunkUrlResource: 'users',
    chunkResource: `${userId}/associatedCenters/${centerId}`,
    type: UPDATE,
    ...{
      ...options,
      params: { ...restParams },
    },
  });
};

const updateAssociatedCenters = ({
  centerRights,
  centerId,
  userId,
}: UpdateAssociatedCentersType) => {
  return updateOne(centerRights as Object, {
    chunkResource: `associatedCenters/${centerId}`,
    chunkId: userId,
  });
};

const updateAutoOnbardingComplete = () => {
  return patchOne(
    {},
    {
      chunkUrlResource: 'users',
      chunkResource: `me/finalize-auto-onboarding-complete`,
      type: PATCH,
    },
  );
};

const getTsTokenByUserId = (userId: string) => {
  return getOneWithCompositeKey(
    {},
    {
      chunkResource: `tsToken/${userId}`,
    },
  );
};

export const SET_ONFIDO_VERIFICATION = 'SET_ONFIDO_VERIFICATION';

const setOnfidoVerification = (onfidoVerification: OnfidoVerification) => ({
  type: SET_ONFIDO_VERIFICATION,
  payload: onfidoVerification,
  resource: 'users',
});

export default {
  ...usersActions,
  activateOrDeactivateSubstitutePractitioner,
  updateAmNumber,
  updateRppsNumber,
  updateAssociatedCenters,
  setOnfidoVerification,
  updateAutoOnbardingComplete,
  getTsTokenByUserId,
};
