import { useContext } from 'react';
import snakeCase from 'lodash/snakeCase';

import { DatacyContext } from '@/src/contexts/DatacyContext';

export const useDatacyName = (name?: string | null): string | undefined => {
  const datacyContextValue = useContext(DatacyContext);

  if (!name) {
    return datacyContextValue || undefined;
  }

  const formatedName = snakeCase(name);
  return datacyContextValue
    ? `${datacyContextValue}-${formatedName}`
    : formatedName;
};
