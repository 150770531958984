import { useContext, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRouter } from 'next/router';
import { createSelector } from 'reselect';

import { TeleconsultationReminder } from '@maiia/model/generated/model/api-pro/api-pro';

import { startTlc } from '../../components/utils/tools';
import { VideoSessionAggregate } from '../../types/pro.types';
import { videoSessionsActions } from '../actions';
import { SubscribersPing } from '../actions/videoSessions';
import { REFUSED, STARTED, WAITING } from '../constants';
import Routes from '../constants/routes';
import {
  useDefaultPractitionerId,
  useIsTlcStarted,
  useTlcCenterAmc,
} from './selectors';
import { useEffectEvent } from './utils';
import { useVideoMeeting } from './videoMeeting';

import { ActionCallbacksContext } from '@/components/contexts';

export function useRefusePatient({
  videoSession,
}: {
  videoSession: VideoSessionAggregate;
}) {
  const dispatch = useDispatch();
  const practitionerId = useDefaultPractitionerId();
  const { isTlcCenterOrAmcAndHasPractitionerId } = useTlcCenterAmc();

  const refuse = () => {
    return dispatch(
      videoSessionsActions.updateOne({
        ...videoSession,
        ...(isTlcCenterOrAmcAndHasPractitionerId && { practitionerId }),
        videoSessionStatus: REFUSED,
      }),
    );
  };

  return {
    refuse,
    isWaiting: videoSession.videoSessionStatus === WAITING,
  };
}

const tlcReminderSelector = createSelector(
  state => state?.teleconsultationReminders?.items,
  items => items?.filter(item => item.isPopup === true),
);

export function useAcceptPatient({
  videoSession,
  miniDialog,
}: {
  videoSession?: VideoSessionAggregate;
  miniDialog?: boolean;
}) {
  const dispatch = useDispatch();
  const router = useRouter();
  const isTlcStarted = useIsTlcStarted();
  const actionCallbacks = useContext(ActionCallbacksContext);

  const tlcReminder = useSelector<TeleconsultationReminder[]>(
    tlcReminderSelector,
  );
  const videoMeeting = useSelector(state => state.videoProMeeting.item);
  const { leave: leaveVideoMeeting } = useVideoMeeting(videoMeeting);
  const practitionerId = useDefaultPractitionerId();
  const { isTlcCenterOrAmcAndHasPractitionerId } = useTlcCenterAmc();

  if (!videoSession) {
    return null;
  }

  const isSessionStarted = videoSession.videoSessionStatus === STARTED;

  const closeWaitingRoom = () => {
    dispatch(videoSessionsActions.setOpenWaitingRoom(false));
  };

  const acceptPatient = () => {
    // if connection is lost or pro close window, redirect to the previously created TLC
    if (isTlcStarted && isSessionStarted) {
      router.push({
        pathname: Routes.TLC,
        query: {
          ...router.query,
          videoSessionId: videoSession.id,
        },
      });
      actionCallbacks?.close();
      if (!miniDialog) {
        closeWaitingRoom();
      }
    } else if (!isTlcStarted) {
      leaveVideoMeeting();
      startTlc(
        dispatch,
        videoSession,
        isTlcCenterOrAmcAndHasPractitionerId,
        practitionerId,
        tlcReminder[0],
      );
      actionCallbacks?.close();
      if (!miniDialog) {
        closeWaitingRoom();
      }
    }
  };

  return { acceptPatient };
}

type SubscriberPingOfflineStatus = { isOffline?: boolean } & SubscribersPing;

/**
 * Hook to watch subscriber offline status.
 * It relies on the slice subscribersPing which takes its values from websocket endpoint 'ping'
 * @see packages/pro-frontend/src/store.ts
 */
export const useSubscribersOfflineStatus = ({
  hasGoneTime,
  delay = 1000,
}: {
  hasGoneTime: number;
  delay?: number;
}): Record<string, SubscriberPingOfflineStatus> => {
  const videoSessions = useSelector(state => state?.videoSessions?.items);
  const subscribersPing = useSelector(
    state => state?.videoSessions?.subscribersPing,
  );

  const [
    subscribersPingOfflineStatus,
    setSubscribersPingOfflineStatus,
  ] = useState<Record<string, SubscriberPingOfflineStatus>>(subscribersPing);

  const subscribersPingRef = useRef(subscribersPing);
  subscribersPingRef.current = {
    ...subscribersPingRef.current,
    ...subscribersPing,
  };

  const handler = useEffectEvent(() => {
    const newSubscribersPingOfflineStatus: Record<
      string,
      SubscriberPingOfflineStatus
    > = subscribersPingRef.current;
    videoSessions.forEach(videoSession => {
      const subscriberPing = newSubscribersPingOfflineStatus[videoSession.id];

      // If not found, try to tweak it, in order to handle the case where we got no signal at all
      if (!subscriberPing) {
        subscribersPingRef.current[videoSession.id] = {
          dateIsoString: new Date().toISOString(),
          emitter: 'web',
          subscriberId: videoSession?.patientId,
          videoSessionId: videoSession.id,
        };
        return;
      }
      subscriberPing.isOffline =
        Date.now() - new Date(subscriberPing.dateIsoString).valueOf() >=
        hasGoneTime;

      newSubscribersPingOfflineStatus[videoSession.id] = subscriberPing;
    });
    setSubscribersPingOfflineStatus(newSubscribersPingOfflineStatus);
  });

  useEffect(() => {
    const interval = setInterval(handler, delay);
    return () => {
      clearInterval(interval);
    };
  }, [delay]);

  return subscribersPingOfflineStatus;
};
