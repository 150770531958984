/* eslint-disable import/no-cycle */
import {
  initialStateItemsDict,
  itemsDictMapReducerGenerator,
} from './itemsDictMap';

const name = 'chatDrafts';

export const chatDraftsInitialState = initialStateItemsDict;

const getPrimaryKey = ({ centerId }: { centerId: string }): string => centerId;
const getSecondaryKey = ({ chatRoomId }: { chatRoomId: string }): string =>
  chatRoomId;

const keysOptions = { getPrimaryKey, getSecondaryKey };

export type ChatDraft = {
  id?: string;
  chatDraftContent: string;
  centerId: string;
  chatRoomId: string;
  hasAudio: boolean;
};

export const chatDrafts = itemsDictMapReducerGenerator<ChatDraft>({
  name,
  keysOptions,
});
