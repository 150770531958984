import React from 'react';

import { ChatContextValue } from '@/src/contexts';
import ChatContext from '@/src/contexts/ChatContexts/ChatContext';
import {
  useMemoizedValues,
  useWsConnectChatByCenters,
  useWsConnectChatByUser,
  useWsConnectChatUserList,
} from '@/src/hooks/chatUtils';

type Props = {
  children: React.ReactNode;
} & ChatContextValue;

export const ChatProvider = (props: Props) => {
  const { children, ...rest } = props;
  useWsConnectChatByUser();
  useWsConnectChatByCenters();
  useWsConnectChatUserList();
  const value = useMemoizedValues(rest);
  return <ChatContext.Provider value={value}>{children}</ChatContext.Provider>;
};
