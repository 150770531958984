/* eslint-disable import/no-cycle */
import { crudInitialDefaultState, reducersGenerator } from '@docavenue/core';
// it's ok to disable the line below because it's only type import - todo find a way to export types in built packages
// eslint-disable-next-line no-restricted-imports
import { CrudInitialDefaultState } from '@docavenue/core/src/types';
import {
  SelfOnboardingInvitationCardHcdDTO,
  SelfOnboardingInvitationDTO,
} from '@maiia/model/generated/model/api-pro/api-pro';

import {
  GET_RECEIVE_INVITATIONS_SUCCESS,
  GET_SENT_INVITATIONS_SUCCESS,
  RECEIVED_NEW_INVITATION,
} from '../actions';

type InvitationsListType = {
  items: SelfOnboardingInvitationCardHcdDTO[];
};

export type ChatInvitationsState = CrudInitialDefaultState<
  SelfOnboardingInvitationDTO
> & {
  sent: InvitationsListType;
  receive: InvitationsListType;
  hasReceivedNewInvitation: boolean;
};

export const chatInvitationsInitialState: ChatInvitationsState = {
  ...(crudInitialDefaultState as CrudInitialDefaultState<
    SelfOnboardingInvitationDTO
  >),
  sent: { items: [] },
  receive: { items: [] },
  hasReceivedNewInvitation: false,
};

const surchargeReducer = () => ({
  [GET_SENT_INVITATIONS_SUCCESS]: (
    state: ChatInvitationsState,
    action: { type: string; payload: string | null },
  ) => ({ ...state, sent: action.payload }),
  [GET_RECEIVE_INVITATIONS_SUCCESS]: (
    state: ChatInvitationsState,
    action: { type: string; payload: string | null },
  ) => ({ ...state, receive: action.payload }),
  [RECEIVED_NEW_INVITATION]: (
    state: ChatInvitationsState,
    action: { type: string; payload: string | null },
  ) => ({ ...state, hasReceivedNewInvitation: action.payload }),
});

export const chatInvitations = reducersGenerator({
  name: 'chatInvitations',
  surchargeReducer,
});
