import { actionsGeneratorV2, UPDATE } from '@docavenue/core';
import {
  ReceiverData,
  SelfOnboardingInvitationCardHcdDTO,
} from '@maiia/model/generated/model/api-pro/api-pro';

import {
  GET_RECEIVE_INVITATIONS_SUCCESS,
  GET_SENT_INVITATIONS_SUCCESS,
  RECEIVED_NEW_INVITATION,
} from './constants';

const chatInvitationsActions = actionsGeneratorV2({
  resource: 'chatInvitations',
  chunkUrlResource: 'chat-invitations',
});

const { getList, updateOne, create } = chatInvitationsActions;

const getReceivedInvitations = (params?: Object, options: Object = {}) =>
  getList(params, {
    chunkResource: 'received',
    ...options,
  });

const getSentInvitations = (params?: Object, options: Object = {}) =>
  getList(params, {
    chunkResource: 'sent',
    ...options,
  });

const getAcknowledgeAcceptedInvitations = (
  options: Record<string, unknown> = {},
) =>
  updateOne(
    {},
    {
      chunkResource: 'acknowledge-accepted',
      ...options,
    },
  );

const getReceivedInvitationsSuccess = (
  receive: SelfOnboardingInvitationCardHcdDTO[],
  options: Object = {},
) => ({
  type: GET_RECEIVE_INVITATIONS_SUCCESS,
  ...chatInvitationsActions.common,
  ...options,
  payload: receive,
  resource: chatInvitationsActions.resource,
});

const getSentInvitationsSuccess = (
  sent: SelfOnboardingInvitationCardHcdDTO[],
  options: Object = {},
) => ({
  type: GET_SENT_INVITATIONS_SUCCESS,
  ...chatInvitationsActions.common,
  ...options,
  payload: sent,
  resource: chatInvitationsActions.resource,
});

const updateChatStatus = (chatInvitationId: string, status: string) =>
  updateOne(
    {},
    {
      chunkResource: `${chatInvitationId}`,
      type: UPDATE,
      params: { status },
    },
  );

const hasReceivedNewInvitation = (chatHasReceivedNewInvitation: boolean) => ({
  resource: 'chatInvitations',
  type: RECEIVED_NEW_INVITATION,
  payload: chatHasReceivedNewInvitation,
});

const reSendInvitation = (
  chatInvitationId: string,
  data: ReceiverData,
  centerId: string | null,
) =>
  create(data, {
    chunkResource: `reinvite/${chatInvitationId}`,
    params: { centerId },
  });

export default {
  ...chatInvitationsActions,
  getReceivedInvitations,
  getSentInvitations,
  getAcknowledgeAcceptedInvitations,
  getReceivedInvitationsSuccess,
  getSentInvitationsSuccess,
  updateChatStatus,
  hasReceivedNewInvitation,
  reSendInvitation,
};
