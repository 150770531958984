import {
  crudInitialDefaultState as initialStateCrudState,
  reducersGenerator,
} from '@docavenue/core';
import { CenterDTO } from '@maiia/model/generated/model/api-pro/api-pro';

import { SET_ROOT_CENTER_ID } from '../actions/centers';
import { CrudState } from './reducersTypes';

export type CentersState = {
  rootCenterId: string | null;
} & CrudState<CenterDTO>;

export const centersInitialState: CentersState = {
  rootCenterId: null,
  ...(initialStateCrudState as CrudState<CenterDTO>),
};

const surchargeReducer = () => ({
  [SET_ROOT_CENTER_ID]: (
    state: CentersState,
    action: { type: string; payload: string | null },
  ) => ({ ...state, rootCenterId: action.payload || null }),
});

export const centers = reducersGenerator({
  name: 'centers',
  surchargeReducer,
});
