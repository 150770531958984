import React, { forwardRef } from 'react';
import {
  InputAdornment as MaterialInputAdornment,
  InputAdornmentProps,
} from '@mui/material';

import { useDatacyName } from '@/src/hooks/datacy';

type Props = {
  datacy?: string;
  position?: 'end' | 'start';
} & InputAdornmentProps;

const InputAdornment = forwardRef(
  (props: Props, ref: React.Ref<HTMLInputElement>) => {
    const { datacy, ...rest } = props;
    const datacyName = useDatacyName(datacy);
    return <MaterialInputAdornment {...rest} ref={ref} datacy={datacyName} />;
  },
);

InputAdornment.displayName = 'InputAdornment';

export default InputAdornment;
