import { GET_ONE_SUCCESS, reducersGenerator } from '@docavenue/core';
import { AvailablePractitionerForImmediateTlcCenterDTO } from '@maiia/model/generated/model/api-pro/api-pro';

import { ItemsDictMapAction } from '@/src/actions/actionsTypes';
import {
  ItemsDictMapReducer,
  ItemsDictMapState,
} from '@/src/reducer/reducersTypes';

const name = 'availablePractitioners';

const surchargeReducer = (
  initialReducer: ItemsDictMapReducer<
    AvailablePractitionerForImmediateTlcCenterDTO
  >,
) => {
  const extendReducer: ItemsDictMapReducer<AvailablePractitionerForImmediateTlcCenterDTO> = {
    ...initialReducer,
  };
  const initialGetListSuccess = extendReducer[GET_ONE_SUCCESS];

  [GET_ONE_SUCCESS, `${GET_ONE_SUCCESS}/${name}`].forEach(actionType => {
    extendReducer[actionType] = (
      state: ItemsDictMapState<AvailablePractitionerForImmediateTlcCenterDTO>,
      action: ItemsDictMapAction<AvailablePractitionerForImmediateTlcCenterDTO>,
    ) => {
      const { payload } = action;

      return initialGetListSuccess(state, {
        ...action,
        payload,
      });
    };
  });

  return extendReducer;
};

const availablePractitioners = reducersGenerator({
  name,
  surchargeReducer,
});

export default availablePractitioners;
