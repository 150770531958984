import find from 'lodash/find';
import _orderBy from 'lodash/orderBy';
import _sortBy from 'lodash/sortBy';

import {
  ChatRoomDTO,
  User,
  UserChatRoomDTO,
} from '@maiia/model/generated/model/api-pro/api-pro';

import { ItemsDictMap } from '@/src/reducer/reducersTypes';
import { ChatMessage } from '@/types/chat.types';

export const isMobile = () => typeof document === 'undefined';

export const getIsChatRoomGroup = (chatRoom?: ChatRoomDTO) => !!chatRoom?.name;

// TODO: Delete when chatSelectors.ts is removed
export const getCompleteUsers = (
  users: User[],
  chatRoomUsers: (User | UserChatRoomDTO)[],
) =>
  users.filter(user =>
    chatRoomUsers?.find(chatRoomUser => chatRoomUser.id === user.id),
  );

/**
 * If a chatroom doesn't have an associated centerId it means that at least one of the chatroom's user is an external user.
 * Invitation chatrooms are displayed seperatly from "classic" center associated chatrooms.
 * TODO: Delete when chatSelectors.ts is removed
 */
export const getIsInvitationChatRoom = (chatRoom?: ChatRoomDTO) =>
  !chatRoom?.centerId;

// TODO: Delete when chatSelectors.ts is removed
export const getUserInChatroom = (
  chatRoom: ChatRoomDTO,
  currentUserId: string,
): UserChatRoomDTO | undefined =>
  chatRoom?.users.find(user => user.id !== currentUserId);

/**
 * This method cannot be used to find group chatroom as multiple groups could have same users in them.
 * In this case the result would be the first group found that would possibly lead to bugs.
 * TODO: Delete when chatSelectors.ts is removed
 */
export const findChatRoomIdByUsers = (
  chatRooms: ChatRoomDTO[],
  userList: User[],
  centerId?: string | null,
): string | typeof undefined =>
  find(
    chatRooms.filter(
      chatRoom =>
        chatRoom.centerId === centerId &&
        !chatRoom.default &&
        !getIsChatRoomGroup(chatRoom),
    ),
    ({ users }) =>
      users?.length === userList?.length &&
      userList.every(user => users.map(u => u?.id).includes(user?.id)),
  )?.id;

// TODO: Delete when chatSelectors.ts is removed
export const sortUsersChatRoomsOrderedByLastNameAndFirstName = (
  centerUsers: User[],
  usersFromOtherChatRooms: User[],
) => {
  const users = [
    ...centerUsers,
    ...usersFromOtherChatRooms.map(chatRoomUser => ({
      ...chatRoomUser,
      isExternal: true,
    })),
  ];

  return _orderBy(
    users,
    item =>
      `${item.userProInformation?.lastName} ${item.userProInformation?.firstName}`.toLocaleLowerCase(),
    ['asc'],
  );
};

/**
 * This method can be used to find all interlocutors in all chatrooms (one to one and self chatroom),
 * This methode dont car about duplication chatroom if that return Two times the same user with charRoomId for each user
 * and ordred the interlocutors by latest chat message  group chatroom.
 * return User[] enhanced with `chatRoomId`
 * TODO: Delete when chatSelectors.ts is removed
 */
export const findUsersChatRoomsOrderedByLatestChatMessage = <T>(
  centerRooms: ChatRoomDTO[],
  otherRooms: ChatRoomDTO[],
  itemsDictMap: ItemsDictMap<
    T,
    ChatMessage & {
      latestChatMessage?: ChatMessage;
    }
  >,
  user: User | null,
  centerUsers: User[],
  usersFromOtherChatRooms: User[],
): (User & { chatRoomId?: string })[] => {
  const users = [
    ...centerUsers,
    ...usersFromOtherChatRooms.map(chatRoomUser => ({
      ...chatRoomUser,
      isExternal: true,
    })),
  ];

  const rooms = [...otherRooms, ...centerRooms];
  const latestChatMessageRoom: ChatMessage[] = [];
  rooms.forEach(room => {
    const latestChatMessage =
      itemsDictMap[room.id as string]?.latestChatMessage;
    if (latestChatMessage) {
      latestChatMessageRoom.push(latestChatMessage);
    }
  });

  const orderedInterlocutorWithLatestChatMessage = _orderBy(
    latestChatMessageRoom,
    [(latestChatMessage: ChatMessage) => latestChatMessage?.updateDate],
    ['desc'],
  ).map((latestChatMessage: ChatMessage) => {
    const latestChatMessageUserId =
      latestChatMessage.users?.find(u => u.userId !== user?.id)?.userId ||
      user?.id;

    return `${latestChatMessageUserId}-${latestChatMessage.chatRoomId}`;
  });
  // users interlocutor
  const usersInterlocutor = rooms.reduce(
    (acc: (User & { chatRoomId: string })[], room) => {
      /*
       * if room.users.length === 1 is  self chat room else interlocutor chat room
       */
      const interlocutorId =
        room.users.length === 1
          ? room.users[0].id
          : room.users.find(u => u.id !== user?.id)?.id || '';

      const interlocutor = users.find(u => u.id === interlocutorId);
      if (interlocutor)
        acc.push({
          ...interlocutor,
          chatRoomId: room.id,
        });
      return acc;
    },
    [],
  );

  const sortedUsersInterlocutor = _sortBy(usersInterlocutor, u => {
    const position = orderedInterlocutorWithLatestChatMessage.indexOf(
      `${u.id}-${u.chatRoomId}`,
    );
    return position !== -1 ? position : usersInterlocutor.length;
  });
  const sortedUsersInterlocutorIds = sortedUsersInterlocutor.map(
    userInterlocutor => userInterlocutor.id,
  );
  const usersWithoutChatRooms = users.filter(
    u => !sortedUsersInterlocutorIds.includes(u.id),
  );

  return [...sortedUsersInterlocutor, ...usersWithoutChatRooms];
};

export const generateInitialsAvatarCanvas = (
  initials: string,
  backgroundColor: string,
) => {
  // Create canvas
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d')!;
  const avatarSize = 200;

  canvas.width = avatarSize;
  canvas.height = avatarSize;

  // Draw background
  context.fillStyle = backgroundColor;
  context.fillRect(0, 0, canvas.width, canvas.height);

  // Draw initials
  context.font = `bold ${avatarSize / 2}px sans-serif`;
  context.fillStyle = 'white';
  context.textAlign = 'center';
  context.textBaseline = 'middle';
  context.fillText(initials, canvas.width / 2, canvas.height / 1.8);

  return canvas.toDataURL('image/png');
};

/**
 * If author couldn't be found in users list
 * It means we probably search for an invitation chatroom's user (without a common center)
 * TODO: Delete when chatSelectors.ts is removed
 */
export const getMessageAuthor = (
  authorId: string,
  users: User[],
  chatRoomUsers: (User | UserChatRoomDTO)[] = [],
): User | UserChatRoomDTO | undefined =>
  [...users, ...chatRoomUsers].find(user => user.id === authorId);

export function sortDateDesc(a: string, b: string) {
  const key1 = new Date(b);
  const key2 = new Date(a);

  if (key1 < key2) {
    return -1;
  }
  if (key1 === key2) {
    return 0;
  }
  return 1;
}
