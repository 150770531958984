import { User } from '@maiia/model/generated/model/api-pro/api-pro';

import {
  initialStateItemsDict,
  itemsDictMapReducerGenerator,
} from './itemsDictMap';

const name = 'chatUsersTyping';

export const chatUsersTypingInitialState = {
  ...initialStateItemsDict,
};

const getPrimaryKey = ({ centerId }: { centerId: string }): string => centerId;
const getSecondaryKey = ({ chatRoomId }: { chatRoomId: string }): string =>
  chatRoomId;

const keysOptions = { getPrimaryKey, getSecondaryKey };

export type ChatUsersTyping = User & { chatRoomId: string; centerId: string };

export const chatUsersTyping = itemsDictMapReducerGenerator<ChatUsersTyping>({
  name,
  keysOptions,
});
