import { actionsGeneratorV2 } from '@docavenue/core';

import { ChatThread } from '../../types/chat.types';
import { Actions, DataAction } from './actionsTypes';
import { ACKNOWLEDGE_SUCCESS } from './constants';

export type ChatThreadsActions = Actions<ChatThread> & {
  acknowledge: (params: { parentId: string }) => DataAction<ChatThread>;
  acknowledgeSuccess: (payload: any, options?: any) => DataAction<ChatThread>;
  setIsThreadViewActive: (
    isActive: boolean,
    options: any,
  ) => DataAction<boolean>;
};

const chatThreadsActions: ChatThreadsActions = actionsGeneratorV2({
  resource: 'chatThreads',
  chunkUrlResource: 'chat-messages/threads',
}) as any;

const { create, delete: deleteAction } = chatThreadsActions;

chatThreadsActions.delete = (id: string, options: any = {}) =>
  deleteAction(id, {
    chunkUrlResource: 'chat-messages',
    ...options,
  });

chatThreadsActions.acknowledge = (params: { parentId: string }) =>
  create({} as ChatThread, {
    chunkUrlResource: 'chat-messages',
    chunkResource: 'acknowledge-replies',
    chunkId: params.parentId,
    ...(params || {}),
  });

chatThreadsActions.acknowledgeSuccess = (
  payload: ChatThread,
  options: any = {},
) => ({
  type: ACKNOWLEDGE_SUCCESS,
  ...chatThreadsActions.common,
  ...options,
  payload,
  resource: chatThreadsActions.resource,
});

export default chatThreadsActions;
