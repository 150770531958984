import React, { useCallback, useMemo } from 'react';
import { useRouter } from 'next/router';
import { makeStyles } from 'tss-react/mui';

import { useTranslation } from '../../../i18n';
import Routes from '../../../src/constants/routes';
import { ChatGroupInvitationNotificationCenter } from '../../../types/pro.types';
import PractitionerAvatar from '../../atoms/PractitionerAvatar';
import Typography from '../../atoms/Typography';
import NotificationWrapper from './NotificationWrapper';

import { ACCEPTED, CREATED } from '@/src/constants';
import { GAActions, GAActionStatus, GALabels } from '@/src/constants/tracking';
import { useChangeChatRoom } from '@/src/hooks/chatUtils';

const useStyles = makeStyles()((theme: any) => ({
  notificationInvitationRoot: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(4),

    '& .link': {
      ...theme.typography.blueLink,
      textDecoration: 'underline',
      fontSize: theme.size.font.sm,
    },
  },
}));

type NotificationGroupInvitationViewProps = {
  notification: ChatGroupInvitationNotificationCenter;
  closeModal: () => void;
  triggerGAEvent: Function;
};

const NotificationGroupInvitationView = (
  props: NotificationGroupInvitationViewProps,
) => {
  const { notification, closeModal, triggerGAEvent } = props;
  const { classes } = useStyles();
  const { t } = useTranslation('common');
  const router = useRouter();
  const { query } = router;
  const changeChatRoom = useChangeChatRoom();

  const { displayData, event: status, chatRoomId } = notification || {};
  const { user, chatRoomName } = displayData || {};
  const { civility = '', firstName = '', lastName = '' } = user || {};

  const name = `${civility} ${firstName} ${lastName}`.trim();
  const isValidStatus = [ACCEPTED, CREATED].includes(status);
  const chatRoomToJoin = status === ACCEPTED && chatRoomId;
  const description = useMemo(() => {
    if (status === CREATED)
      return t('common:notification_group_invitation_sent', {
        name,
        groupName: chatRoomName,
      });
    if (status === ACCEPTED)
      return t('common:notification_group_invitation_accepted', {
        name,
        groupName: chatRoomName,
      });
  }, [status, name, chatRoomName]);

  const handleChangeView = useCallback(() => {
    triggerGAEvent(
      GAActions.ClickNotification,
      GAActionStatus.SUCCESS,
      GALabels.NewsNotification,
    );
    if (chatRoomToJoin) {
      changeChatRoom(chatRoomId);
    } else {
      router.push({
        pathname: Routes.CHAT_CREATE_INVITATIONS,
        query,
      });
    }
    closeModal();
  }, [chatRoomId, changeChatRoom]);

  if (!isValidStatus) return null;

  return (
    <NotificationWrapper notification={notification}>
      <div className={classes.notificationInvitationRoot}>
        <PractitionerAvatar user={{ firstName, lastName }} />
        <div className="text">
          <Typography>{description}</Typography>
          <Typography onClick={handleChangeView} className="link">
            {chatRoomToJoin
              ? t('common:join_conversation')
              : t('common:notification_network_redirect')}
          </Typography>
        </div>
      </div>
    </NotificationWrapper>
  );
};

export default NotificationGroupInvitationView;
