/* eslint-disable react/jsx-key */
import React from 'react';

import { Trans, useTranslation } from '../../../i18n';
import MarketingMessage from '../../molecules/MarketingMessage';

import { useSendUpsellMail } from '@/pages/offers';
import { GACategories, GAUpsellActions } from '@/src/constants/tracking';
import { useAnalyticsTracker } from '@/src/hooks/utils';

type Props = {
  close: () => void;
};

const NeedContactTlc = (props: Props) => {
  const { close } = props;
  const { t } = useTranslation();

  const trackEvent = useAnalyticsTracker(GACategories.Upsell);

  const onMailSent = () => {
    trackEvent(GAUpsellActions.ClickUpsellAgendaTlc);
  };

  const { sendMail } = useSendUpsellMail({
    onSuccess: onMailSent,
  });
  const sendMailOnClick = () => {
    sendMail('UPSELL_TLC');
    close();
  };
  return (
    <MarketingMessage
      errorMessage={null}
      message={null}
      onClick={sendMailOnClick}
      mainIllu="/static/images/illu-market-tlc.svg"
      catchTitle={t('WaitingRoomDialog_NeedContactTlc_catchtitle')}
      catchPhrases={[
        <Trans
          i18nKey="common:WaitingRoomDialog_NeedContactTlc_catchphrase1"
          components={[<strong key="strong" />]}
        />,
        <Trans
          i18nKey="common:WaitingRoomDialog_NeedContactTlc_catchphrase2"
          components={[<strong key="strong" />]}
        />,
        <Trans
          i18nKey="common:WaitingRoomDialog_NeedContactTlc_catchphrase3"
          components={[<strong key="strong" />]}
        />,
      ]}
      close={close}
    />
  );
};

export default NeedContactTlc;
