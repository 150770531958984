/* eslint-disable no-param-reassign */
import sortBy from 'lodash/sortBy';

import {
  ACKNOWLEDGE,
  CREATE,
  CREATE_SUCCESS,
  CRUD_FAILURE,
  crudInitialDefaultState,
  DELETE_SUCCESS,
  reducersGenerator,
  UPDATE,
  UPDATE_SUCCESS,
} from '@docavenue/core';

import { DataAction } from '../actions/actionsTypes';
import { ACKNOWLEDGE_SUCCESS } from '../actions/constants';
import { CrudState, ReducerConfig } from './reducersTypes';

import { ChatThread } from '@/types/chat.types';

const name = 'chatThreads';

export const chatThreadsInitialState = crudInitialDefaultState;

const surchargeReducer = (reducerConfig: ReducerConfig<ChatThread>) => {
  const actionMap: ReducerConfig<ChatThread> = {};
  actionMap[ACKNOWLEDGE] = (
    state: CrudState<ChatThread>,
    action: DataAction<ChatThread>,
  ) => {
    const { payload } = action;
    const index = state.items.findIndex(
      item => item.parent.id === payload?.parent.id,
    );
    if (index === -1 && payload) {
      state.items = [...state.items, payload];
    } else {
      state.items[index].replies = sortBy(payload.replies, 'creationDate');
    }
    return state;
  };

  actionMap[ACKNOWLEDGE_SUCCESS] = (
    state: CrudState<ChatThread>,
    action: DataAction<ChatThread>,
  ) => {
    const { payload, userId } = action;
    const index = state.items.findIndex(
      item => item.parent.id === payload.parentId,
    );
    if (index !== -1) {
      let { replies } = state.items[index];
      if (replies?.length) {
        replies = (replies || []).map(reply => {
          const author = reply.users?.find(user => user.userId === userId);
          if (!author || author.isRead) {
            return reply;
          }
          return {
            ...reply,
            users: payload.users,
          };
        });

        state.items[index] = { ...state.items[index], replies };
        state.items = [...state.items];
      }
    }
    return state;
  };

  actionMap[DELETE_SUCCESS] = (
    state: CrudState<ChatThread>,
    action: DataAction<ChatThread>,
  ) => {
    const { payload } = action;
    const { id } = payload;

    let threads = state.items.filter(item => item.parent.id !== id);
    if (threads.length === state.items.length) {
      threads = state.items.map(item => {
        const { replies } = item;
        const filteredReplies = replies.filter(reply => reply.id !== id);
        if (replies.length !== filteredReplies.length) {
          return { ...item, replies: filteredReplies };
        }
        return item;
      });
    }
    return { ...state, items: threads };
  };

  actionMap[CRUD_FAILURE] = (
    state: CrudState<ChatThread>,
    action: DataAction<ChatThread>,
  ) => {
    if (
      !action.payload?.action?.type ||
      ![CREATE, UPDATE].includes(action.payload?.action?.type)
    ) {
      return reducerConfig[CRUD_FAILURE](state, action);
    }

    const baseAction = {
      ...action.payload.action,
      query: { ...action.payload.action.payload.params },
      payload: {
        isError: true,
        users: [],
      },
    };

    const newAction =
      action.payload.action.type === CREATE
        ? {
            ...baseAction,
            type: CREATE_SUCCESS,
            payload: {
              ...action.payload.action.payload.data,
              ...baseAction.payload,
              id: new Date().getTime(),
              creationDate: new Date().toISOString(),
              updateDate: new Date().toISOString(),
            },
          }
        : {
            ...baseAction,
            type: UPDATE_SUCCESS,
            payload: {
              ...action.payload.action.payload.data,
              ...baseAction.payload,
              id: action.payload.action.payload.id,
              creationDate: action.payload.action.payload.params.creationDate,
              updateDate: action.payload.action.payload.params.creationDate,
            },
          };

    return reducerConfig[UPDATE_SUCCESS](state, newAction);
  };

  return actionMap;
};

export const chatThreads = reducersGenerator({
  name,
  surchargeReducer,
});
