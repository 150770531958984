/* eslint-disable */
/* tslint:disable */
/*
 * -------------------------------------------------------------------
 * ## THIS FILE WAS GENERATED via our custom react-query-generator  ##
 * ##                                                               ##
 * ## DO NOT MODIFY                                                 ##
 * -------------------------------------------------------------------
 *
 * Implementation for `proContactsUpsell` hook
 * Endpoint: `/pro/contacts/upsell`
 */

import { useQueryClient, useMutation } from "@tanstack/react-query";

import omitBy from "lodash/omitBy";
import pick from "lodash/pick";

import { useApiClient } from "../../../core/react-query";

export function usePost(mutationOptions) {
	const { getApiClient } = useApiClient();
	// todo: add invalidateQuery mechanism
	return useMutation({
		...mutationOptions,
		mutationFn: (variables) => {
			return getApiClient({ apiName: "api-pro" })
				.post(`/pro/contacts/upsell`, variables.$payload, {})
				.then(({ data }) => data);
		},
	});
}
