import { createContext, RefObject } from 'react';

export type ScrollManagerProviderProps = {
  actions?: { setIsTouchingBottom: (isTouchingBottom: boolean) => any };
  messageToScrollOnEnter: any;
  acknowledge: (id: string) => void;
  children: React.ReactNode;
};

type ScrollManagerContextValue = {
  containerRef: RefObject<HTMLDivElement>;
  inputRef?: RefObject<HTMLDivElement>;
  loadMoreTopRef: RefObject<HTMLDivElement>;
  onNewLine?: () => void;
  scrollToBottom?: () => void;
};

// @ts-ignore
const ScrollManagerContext = createContext<ScrollManagerContextValue>({});

export default ScrollManagerContext;
