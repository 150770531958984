/* eslint-disable import/no-cycle */
import last from 'lodash/last';
import { createSelector } from 'reselect';

import { generateInitialSelectors } from '@docavenue/core';
import { Patient } from '@maiia/model/generated/model/api-pro/api-pro';

import { PATIENT_ROW_COUNT_PER_PAGE } from '../constants';
import { RootState } from '../reducer/rootState';

import {
  GenericSelector,
  InitialGenericSelector,
} from '@/src/selector/selectorsType';

const initialSelectors: InitialGenericSelector<Patient> = generateInitialSelectors(
  'patients',
);

export type PatientSelector = GenericSelector<Patient> & {
  getLastQuery: (state: RootState, centerId?: string) => any;
  getLastQueryTerm: (state: RootState) => string;
};

const selectors: PatientSelector = {
  name: 'patients',
  ...initialSelectors,
  getLastQuery: createSelector(
    initialSelectors.getQueries,
    (_, centerId) => centerId,
    (queries, centerId) => ({
      centerId,
      term: '',
      limit: PATIENT_ROW_COUNT_PER_PAGE,
      page: 0,
      ...last(queries),
    }),
  ),
  getLastQueryTerm: store =>
    (last(initialSelectors.getQueries(store)) as any)?.term || '',
};

export default selectors;
