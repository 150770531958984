import { actionsGeneratorV2 } from '@docavenue/core';

const agendaSettingsActions = actionsGeneratorV2({
  resource: 'agendaSettings',
  disableConcurrency: {
    isGetListDisableConcurrency: true,
    isGetOneDisableConcurrency: true,
  },
});

const { getList: _getList } = agendaSettingsActions;

const getList = (params: { centerId: string }, options: Object = {}) => {
  const { centerId, ...restParams } = params;
  return _getList(restParams, {
    chunkUrlResource: 'centers',
    chunkId: centerId,
    chunkResource: 'agendaSettings',
    ...options,
  });
};

export default {
  ...agendaSettingsActions,
  getList,
};
