// Appointment
export const AbsenceAppointment = 'ABSENCE';
export const CancelledAppointment = 'CANCELLED';
export const PhysicalAppointment = 'PHYSICAL';
export const NoteAppointment = 'NOTE';

// videoSession
export const FINISHED = 'FINISHED';
export const PENDING = 'PENDING';
export const STARTED = 'STARTED';
export const REFUSED = 'REFUSED';
export const WAITING = 'WAITING';

// AuthCard
export const AUTH = 'auth';
export const FORGOT_PASSWORD = 'forgot-password';
export const RESET_PASSWORD = 'reset-password';

// User Role
export const PRACTITIONER = 'PRACTITIONER';
export const SECRETARY = 'SECRETARY';
export const SUBSTITUTE_PRACTITIONER = 'SUBSTITUTE_PRACTITIONER';
export const TELESECRETARY = 'TELESECRETARY';
export const PATIENT = 'PATIENT';
export const PRIVATE = 'PRIVATE';

// feedback TLC
export const FEEDBACK_TLC_NEGATIVE = 'negative';
export const FEEDBACK_TLC_POSITIVE = 'positive';

export const feedbackMotifs = [
  {
    id: 'patientVideoOrSoundProblem',
    labelName: 'common:patient_could_not_ear',
  },
  {
    id: 'qualityVideoOrSoundProblem',
    labelName: 'common:poor_sound_video_quality',
  },
  {
    id: 'patientAccessProblem',
    labelName: 'common:patient_trouble_access_tlc',
  },
  { id: 'documentProblem', labelName: 'common:trouble_with_documents' },
  {
    id: 'wrongErrorMessageProblem',
    labelName: 'common:wrong_message_displayed',
  },
  { id: 'other', labelName: 'common:tlc_notation_other' },
];

// auto onboarding LGC carousel step number
export const PROFILE_CAROUSEL_STEP = 0;
export const CREDENTIALS_CAROUSEL_STEP = 1;
export const SYNCHRONIZATION_CAROUSEL_STEP = 2;

// chat onboarding step number
export const CHAT_ON_BOARDING_PROFILE_STEP = 0;
export const CHAT_ON_BOARDING_CREDENTIALS_STEP = 1;
export const CHAT_ON_BOARDING_ONFIDO_STEP = 2;
export const CHAT_ON_BOARDING_VALIDATION_STEP = 3;
export const MAX_ID_CHECKS_ATTEMPTS = 3;

// TeleExpertise status
export const ALL = 'ALL';
export const ONGOING = 'ONGOING';
export const COMPLETED = 'COMPLETED';

// Offers
export const PACK_OFFER = 'MAIIA-PCK-ABO-1';
export const AGENDA_OFFER = 'ABO-LOG-LGA-004';
export const OFFLINE_AGENDA_OFFER = 'ABO-LOG-LGA-008';
export const TLC_OFFER = 'ABO-LOG-TLC-008';
export const PHARMACY_OFFER = 'ABO-LOG-TLC-010';

export const WS_CONNECT_RESOURCES = {
  CHAT_MESSAGE: 'chatMessage',
  CHAT_REPLY_MESSAGE: 'chatReplyMessage',
  CHAT_ROOM: 'chatRoom',
  CHAT_THREAD: 'chatThread',
  CONNECTED_USERS: 'chatUsersConnected',
  INVITATIONS: 'selfOnboardingInvitation',
} as const;

// Software codes
export const SIMPLY_SOFTWARE_CODE = 'SIMPLY';

// software versions
export const SIMPLY_VERSION_410 = '4.1.0';

// Internal codes
export const SIMPLY_V1 = '401';
export const SIMPLY_V2 = '410';
export const ONLINE_SIMPLY = 'ONLINE_SIMPLY';

/**
 * Number of weeks to prefetch before a week range.
 * 0 or 1 means no prefetch
 * 2 means 1 week prefetch
 * */
export const WEEKS_BEFORE = 2;
/**
 * Number of weeks to prefetch after a week range.
 * 0 or 1 means no prefetch
 * 2 means 1 week prefetch
 * */
export const WEEKS_AFTER = 2;

export const CHAT_PAGINATION_LIMIT = 150;

export const SEEN = 'SEEN';
export const SENT = 'SENT';
export const NONE = 'NONE';

export const CHAT_DAY_FORMAT = 'ddd D/MM';
export const CHAT_DAY_ON_SCROLL_FORMAT = 'dddd DD MMMM';

export const CHAT_INVITATION_SEARCH_LIMIT = 20;

export const CHAT_SEARCH_RESULTS_LIMIT = 10;

export const DELETED_MESSAGE = 'DELETED_MESSAGE';

export const RECENT_SEARCH_HISTORY = 'RECENT_SEARCH_HISTORY';

export const TELE_EXPERTISE = 'TELE_EXPERTISE';

export const INFO = 'INFO';

export const SPECIAL_CHARACTER_REGEX = /[`!@#$%^&*()_+\-=\\[\]{};':"\\|,.<>\\/?~]/;

export const ALPHA_NUMERIC_CHARACTER_REGEX = /[a-zA-Z0-9]/;

export const MARKETING_CAMPAIGN_INTERACTION_LIKE = 'LIKE';
export const MARKETING_CAMPAIGN_INTERACTION_DISLIKE = 'DISLIKE';
export const MARKETING_CAMPAIGN_INTERACTION_NO_OPINION = 'NO_OPINION';
