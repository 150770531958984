export interface OpenTokKnownError {
  // Exception code: the number given by OpenTok to identify a group of errors
  code: number | undefined;
  // The string name in upper case
  name: string | undefined;
  // The message raised by the error
  message: string | undefined;
  // Similar to the name
  reason: string | undefined;
}

export const publishTimeoutError: OpenTokKnownError = {
  code: 1500,
  name: undefined,
  message:
    'OT.Publisher failed to publish in a reasonable amount of time (timeout)',
  reason: undefined,
};

export const publishTimeoutIceError: OpenTokKnownError = {
  code: 1500,
  name: 'OT_TIMEOUT',
  message: 'Could not publish in a reasonable amount of time',
  reason: 'ICEWorkflow',
};

export const publishHardwareUnavailableError: OpenTokKnownError = {
  code: 1500,
  name: 'OT_HARDWARE_UNAVAILABLE',
  message:
    'The selected voice or video devices are unavailable. Verify that the chosen devices are not use by another application',
  reason: 'ICEWorkflow',
};

// Access to user media was denied.
export const publishUserMediaAccessDeniedError: OpenTokKnownError = {
  code: undefined,
  name: 'OT_USER_MEDIA_ACCESS_DENIED',
  message: 'End-user denied permission to hardware devices',
  reason: 'ICEWorkflow',
};
// Screen sharing denied error
export const screenSharingPermissionError: OpenTokKnownError = {
  code: 1500,
  name: 'OT_USER_MEDIA_ACCESS_DENIED',
  message: 'Permission denied by system',
  reason: undefined,
};

// This is a generally a network error. From opentok:
// WebRTC ICE workflow error. This is an error that occurs when trying to establish communication between clients in the session. Try publishing again or reconnecting to the session
export const publishIceWorkflowFailedError: OpenTokKnownError = {
  code: 1553,
  name: 'OT_ICE_WORKFLOW_FAILED',
  message: undefined,
  reason: undefined,
};

export const subscribeTimeoutError: OpenTokKnownError = {
  code: 1501,
  name: 'OT_UNABLE_TO_SUBSCRIBE',
  message: 'Unable to subscribe to stream in a reasonable amount of time',
  reason: undefined,
};

// Same as its fellow publisher
export const subscribeIceWorkflowFailedError: OpenTokKnownError = {
  code: 1554,
  name: 'OT_ICE_WORKFLOW_FAILED',
  message: undefined,
  reason: undefined,
};
