export const ACKNOWLEDGE_SUCCESS = 'ACKNOWLEDGE_SUCCESS' as const;
export const SET_CURRENT_CHATROOM_ID = 'SET_CURRENT_CHATROOM_ID' as const;
export const GET_FIRST_UNREAD_MESSAGE_SUCCESS = 'GET_FIRST_UNREAD_MESSAGE_SUCCESS' as const;
export const GET_LATEST_SUCCESS = 'GET_LATEST_SUCCESS' as const;
export const GET_UNREAD_COUNT_SUCCESS = 'GET_UNREAD_COUNT_SUCCESS' as const;
export const TOGGLE_DELETE = 'TOGGLE_DELETE' as const;
export const TOGGLE_EDIT = 'TOGGLE_EDIT' as const;
export const SAVE_EDIT_DRAFT = 'SAVE_EDIT' as const;
export const SET_IS_TOUCHING_BOTTOM = 'SET_IS_TOUCHING_BOTTOM' as const;
export const SET_IS_CHATROOM_VISIBLE = 'SET_IS_CHATROOM_VISIBLE' as const;
export const SET_CHATMESSAGE_REDIRECTED_FROM_THREAD = 'SET_CHATMESSAGE_REDIRECTED_FROM_THREAD' as const;
export const INCREMENT_REPLY_NUMBER = 'INCREMENT_REPLY_NUMBER' as const;
export const DECREMENT_REPLY_NUMBER = 'DECREMENT_REPLY_NUMBER' as const;
export const CLEAR_OLD_CHATROOM = 'CLEAR_OLD_CHATROOM' as const;
export const GET_RECEIVE_INVITATIONS_SUCCESS = 'GET_RECEIVE_INVITATIONS_SUCCESS' as const;
export const GET_SENT_INVITATIONS_SUCCESS = 'GET_SENT_INVITATIONS_SUCCESS' as const;
export const RECEIVED_NEW_INVITATION = 'RECEIVED_NEW_INVITATION' as const;
export const RESET_MESSAGES = 'RESET_MESSAGES' as const;
export const UPDATE_TELE_DATA_EXPERT = 'UPDATE_TELE_DATA_EXPERT' as const;
export const SET_CHAT_ROOM_INCOMING_CALL = 'SET_CHAT_ROOM_INCOMING_CALL' as const;
export const UNREAD_MESSAGE_SUCCESS = 'UNREAD_MESSAGE_SUCCESS' as const;
export const UPDATE_MARKETING_CAMPAIGN_VOTE = 'UPDATE_MARKETING_CAMPAIGN_VOTE' as const;
