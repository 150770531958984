/* eslint-disable import/no-cycle */
import { createSelector } from 'reselect';

import { generateInitialSelectors } from '@docavenue/core';
import { ChatRoomDTO } from '@maiia/model/generated/model/api-pro/api-pro';

import { ChatState } from '@/src/reducer';
import {
  GenericSelector,
  InitialGenericSelector,
} from '@/src/selector/selectorsType';
import { sortDateDesc } from '@/src/utils/chat/chat.utils';
import { ChatMessage } from '@/types/chat.types';

const initialSelectors: InitialGenericSelector<ChatRoomDTO> = generateInitialSelectors(
  'chatMessages',
);

export type ChatMessagesSelector = GenericSelector<ChatRoomDTO> & {
  getCurrentChatRoomId: (state: ChatState) => string | null;
  getQuery: (state: ChatState) => Record<string, any>;
  getMessagesFromItemsDictMapAndTotal: typeof getMessagesFromItemsDictMapAndTotal;
};

const getMessagesFromItemsDictMapAndTotal = createSelector(
  (state: ChatState) => state.chatMessages.itemsDictMap,
  (state: ChatState) => state.chatMessages.total,
  (chatMessagesByItemsDictMap, total) => {
    const messages: ChatMessage[] = [];
    Object.values(chatMessagesByItemsDictMap).forEach(({ itemsDict }) =>
      messages.push(...Object.values(itemsDict).flat()),
    );
    messages.sort((a, b) =>
      sortDateDesc(a.creationDate as string, b.creationDate as string),
    ); // todo types: add typeguard
    return { messages, total };
  },
);

const selectors: ChatMessagesSelector = {
  name: 'chatMessages',
  ...initialSelectors,
  getCurrentChatRoomId: state => state.chatMessages.currentChatRoomId,
  getQuery: state => state.chatMessages.query,
  getMessagesFromItemsDictMapAndTotal,
};

export default selectors;
