import React, { forwardRef } from 'react';
import {
  DialogContent as DialogContentMaterial,
  DialogContentProps,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme: any) => ({
  root: {
    margin: 0,
    padding: 0,
    overflowY: 'auto',
    '& > *': {
      '--padding-dialog-content': theme.spacing(4),
      padding: 'var(--padding-dialog-content)',
      '&:not(:only-child)': {
        '&:last-child:not(.addAnotherDrug)': { paddingTop: 0 },
        '&:not(:first-child):not(:last-child):': {
          paddingTop: 0,
          paddingBottom: 0,
        },
      },
    },
  },
}));

const DialogContent = (
  props: DialogContentProps,
  ref: React.Ref<HTMLDivElement>,
) => {
  const { classes } = useStyles();
  return <DialogContentMaterial ref={ref} classes={classes} {...props} />;
};

export default forwardRef(DialogContent);
