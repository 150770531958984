import React from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { makeStyles } from 'tss-react/mui';

import { stringify } from 'querystring';

import { useTranslation } from '../../../i18n';
import {
  useCenterId,
  useIsFreemium,
  usePractitionerId,
} from '../../../src/hooks/selectors';
import { useAnalyticsTracker } from '../../../src/hooks/utils';
import {
  AppointmentNotificationCenter,
  BillingNotificationCenter,
  ChatGroupInvitationNotificationCenter,
  ChatPractitionerInvitationNotificationCenter,
  DocumentNotificationCenter,
  NotificationsCenter,
  PatientInstructionNotificationCenter,
  ProductNotificationCenter,
  SecretaryInstructionNotificationCenter,
} from '../../../types/pro.types';
import ChatBadge from '../../atoms/ChatBadge';
import Paper from '../../atoms/Paper';
import Typography from '../../atoms/Typography';
import EmptyStateFallback from '../../molecules/EmptyStateFallback';
import { EmptyState } from '../../utils/components';
import NotificationAppointmentView from './NotificationAppointmentView';
import NotificationDocumentView from './NotificationDocumentView';
import NotificationFacturationView from './NotificationFacturationView';
import NotificationGroupInvitationView from './NotificationGroupInvitationView';
import NotificationPatientInstructionView from './NotificationPatientInstructionView';
import NotificationPractitionerInvitationView from './NotificationPractitionerInvitationView';
import NotificationProductView from './NotificationProductView';
import NotificationSecretaryInstructionView from './NotificationSecretaryInstructionView';

import {
  GAActions,
  GAActionsType,
  GACategories,
} from '@/src/constants/tracking';

type Props = {
  unreadNotificationsCount: number;
  notifications: NotificationsCenter[];
  closeModal: () => void;
};

const useStyles = makeStyles()((theme: any) => ({
  root: {
    minWidth: theme.spacing(13),
    backgroundColor: theme.palette.grey[100],
  },
  header: {
    padding: theme.spacing(2),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& .badge': {
      fontSize: theme.size.font.sm,
      width: theme.size.font['3xl'],
      height: theme.size.font['3xl'],
      marginRight: theme.spacing(1),
    },
  },
  notifList: {
    maxHeight: '580px',
    overflowY: 'auto',
    overflowX: 'hidden',
  },
  unreadNotification: {
    backgroundColor: theme.palette.accent.main,
    color: theme.palette.common.white,
  },
  footer: {
    padding: theme.spacing(2),
    textAlign: 'center',
    borderTop: `1px solid ${theme.palette.grey[200]}`,
    '& a': {
      fontSize: theme.size.font.sm,
      textDecoration: 'none',
      color: theme.palette.text.primary,
      fontWeight: 500,
    },
  },
  emptyBloc: {
    backgroundColor: theme.palette.common.white,
    paddingBottom: theme.spacing(6),
    '& img': {
      height: theme.spacing(11),
      width: theme.spacing(11),
    },
    '& span': {
      color: theme.palette.text.secondary,
      fontStyle: 'italic',
      fontSize: theme.size.font.md,
      marginTop: -20,
    },
  },
}));

const ListNotificationView = (props: Props) => {
  const { unreadNotificationsCount, notifications, closeModal } = props;
  const { query } = useRouter();
  const { classes } = useStyles();
  const { t } = useTranslation('common');
  const practitionerId = usePractitionerId();
  const centerId = useCenterId();
  const isFreemium = useIsFreemium();
  const triggerGAEvent = useAnalyticsTracker(GACategories.Header);

  const handleGAEvent = (action: GAActionsType) => triggerGAEvent(action);

  return (
    <Paper className={classes.root}>
      <header className={classes.header}>
        <Typography variant="title" color="main.main">
          {t('notifications_list_title')}
        </Typography>
        <ChatBadge count={unreadNotificationsCount} />
      </header>
      <EmptyState
        isEmpty={notifications.length === 0}
        fallback={
          <EmptyStateFallback
            image="/static/images/empty-params.svg"
            message={t('notifications_list_empty')}
            className={classes.emptyBloc}
          />
        }
      >
        <div className={classes.notifList}>
          {notifications.map(notification => {
            switch (notification.topic) {
              case 'APPOINTMENT':
                return (
                  <NotificationAppointmentView
                    notification={notification as AppointmentNotificationCenter}
                    closeModal={closeModal}
                    triggerGAEvent={triggerGAEvent}
                    key={notification.id}
                  />
                );
              case 'BILLING':
                return (
                  <NotificationFacturationView
                    notification={notification as BillingNotificationCenter}
                    closeModal={closeModal}
                    triggerGAEvent={triggerGAEvent}
                    key={notification.id}
                  />
                );
              case 'PRODUCT':
                return (
                  <NotificationProductView
                    notification={notification as ProductNotificationCenter}
                    closeModal={closeModal}
                    triggerGAEvent={triggerGAEvent}
                    key={notification.id}
                  />
                );
              case 'SHARED_DOCUMENT_BY_PATIENT':
                return (
                  <NotificationDocumentView
                    notification={notification as DocumentNotificationCenter}
                    closeModal={closeModal}
                    triggerGAEvent={triggerGAEvent}
                    key={notification.id}
                  />
                );
              case 'CHAT_INVITATION':
                return (
                  <NotificationPractitionerInvitationView
                    notification={
                      notification as ChatPractitionerInvitationNotificationCenter
                    }
                    closeModal={closeModal}
                    triggerGAEvent={triggerGAEvent}
                    key={notification.id}
                  />
                );
              case 'CHAT_JOIN_REQUEST':
                return (
                  <NotificationGroupInvitationView
                    notification={
                      notification as ChatGroupInvitationNotificationCenter
                    }
                    closeModal={closeModal}
                    triggerGAEvent={triggerGAEvent}
                    key={notification.id}
                  />
                );

              case 'PATIENT_INSTRUCTION':
                return (
                  <NotificationPatientInstructionView
                    notification={
                      notification as PatientInstructionNotificationCenter
                    }
                    closeModal={closeModal}
                    triggerGAEvent={triggerGAEvent}
                    key={notification.id}
                  />
                );

              case 'SECRETARY_INSTRUCTION':
                return (
                  <NotificationSecretaryInstructionView
                    notification={
                      notification as SecretaryInstructionNotificationCenter
                    }
                    closeModal={closeModal}
                    triggerGAEvent={triggerGAEvent}
                    key={notification.id}
                  />
                );

              default:
                return null;
            }
          })}
        </div>
      </EmptyState>
      {!isFreemium && (
        <div onClick={closeModal}>
          <div className={classes.footer}>
            <Typography variant="body">
              <Link
                href={`/administration?${stringify({
                  tab: 'activity',
                  practitionerId,
                  centerId,
                  agendas: query.agendas,
                })}`}
              >
                <a onClick={() => handleGAEvent(GAActions.ClickActionHistory)}>
                  {t('notifications_list_footer')}
                </a>
              </Link>
            </Typography>
          </div>
          <div className={classes.footer}>
            <Typography variant="body">
              <Link
                href={`/administration?${stringify({
                  tab: 'documents',
                  practitionerId,
                  centerId,
                })}`}
              >
                <a
                  onClick={() => handleGAEvent(GAActions.ClickDocumentHistory)}
                >
                  {t('notifications_list_footer2')}
                </a>
              </Link>
            </Typography>
          </div>
        </div>
      )}
    </Paper>
  );
};

export default ListNotificationView;
