import { differenceInMinutes } from 'date-fns';

import { VideoProMeetingDTO } from '@maiia/model/generated/model/api-pro/api-pro';

import { MAX_RESUMPTION_TIME } from './tlc.constants';

import * as openTokKnownError from '@/components/organisms/OpenTok/opentok.errors';
import { VideoSessionAggregate } from '@/types/pro.types';

type MergedType<K> = K extends keyof VideoSessionAggregate
  ? VideoSessionAggregate[K]
  : K extends keyof VideoProMeetingDTO
  ? VideoProMeetingDTO[K]
  : never;

/**
 * Easily retrieve a property from videoSession object without worry about the original source
 */
export const getVideoSessionValueOfX = <
  T extends VideoSessionAggregate | VideoProMeetingDTO,
  K extends keyof VideoSessionAggregate | keyof VideoProMeetingDTO
>(
  videoSession: T,
  property: K,
): MergedType<K> => {
  const isProMeeting =
    (videoSession as VideoProMeetingDTO).videoProMeetingStatus !== undefined;
  const meeting = videoSession as VideoSessionAggregate;
  const proMeeting = videoSession as VideoProMeetingDTO;
  switch (property) {
    case 'videoSessionStatus':
    case 'videoProMeetingStatus':
      return (isProMeeting
        ? proMeeting.videoProMeetingStatus
        : meeting.videoSessionStatus) as MergedType<K>;

    // Add here any relevant properties that might be different from one type to another

    default:
      // @ts-ignore
      return videoSession[property] as MergedType<K>;
  }
};

export function isVideoProMeeting(
  session: VideoProMeetingDTO | VideoSessionAggregate | null | undefined,
): session is VideoProMeetingDTO {
  return !!(session as VideoProMeetingDTO)?.videoProMeetingStatus;
}

/**
 * Whether a videoSession is finished or not
 */
export const isVideoSessionFinished = (
  videoSession: VideoSessionAggregate | VideoProMeetingDTO | null | undefined,
): boolean => {
  if (!videoSession) return false;

  const videoSessionStatus = getVideoSessionValueOfX(
    videoSession,
    'videoSessionStatus',
  );

  if (isVideoProMeeting(videoSession)) return videoSessionStatus === 'FINISHED';

  // Either the practitioner hung up or the delay for a recall elapsed
  return (
    !!videoSession.practitionerHasHungUp ||
    (typeof videoSession.openUntil === 'string' &&
      differenceInMinutes(new Date(), videoSession.openUntil) >
        MAX_RESUMPTION_TIME)
  );
};

/**
 * Whether the videoSession is still going on
 */
export const isVideoSessionOngoing = (
  videoSession: VideoSessionAggregate | VideoProMeetingDTO | null | undefined,
): boolean => {
  if (!videoSession) return false;

  const videoSessionStatus = getVideoSessionValueOfX(
    videoSession,
    'videoSessionStatus',
  );

  if (isVideoProMeeting(videoSession)) return videoSessionStatus === 'STARTED';

  if (videoSession.practitionerHasHungUp) return false;
  return (
    videoSessionStatus === 'STARTED' ||
    videoSessionStatus === 'PATIENT_FINISHED' ||
    videoSessionStatus === 'FINISHED'
  );
};

export const hasAuthorizedPatientToRejoin = (
  videoSession: VideoSessionAggregate | null | undefined,
): boolean => !!videoSession && typeof videoSession.openUntil === 'string';

export const hasScreenSharingPermissionError = (
  error: OT.OTError | Error,
  videoSource: OT.PublisherProperties['videoSource'],
) =>
  videoSource === 'screen' &&
  openTokKnownError.screenSharingPermissionError.name === error?.name &&
  openTokKnownError.screenSharingPermissionError.message ===
    // @ts-ignore the originalMessage property comes directly from the browser API
    error?.originalMessage;
