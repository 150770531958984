/* eslint-disable import/no-extraneous-dependencies */
import { createWrapper } from 'next-redux-wrapper';
import {
  AnyAction,
  applyMiddleware,
  createStore,
  Middleware,
  Store,
} from 'redux';
import createSagaMiddleware, { Task } from 'redux-saga';
import { all, fork } from 'redux-saga/effects';

import {
  anonymizeObject,
  authSaga,
  CREATED,
  crudSaga,
  defaultPropertiesToChange,
  GET_LIST,
  SET_ITEM,
  transformerByTimezone,
  UPDATED,
  websocketActions,
  wsSaga,
} from '@docavenue/core';
import { OnfidoVerification } from '@maiia/model/generated/model/api-pro/api-pro';

import {
  availabilitiesActions,
  chatInvitationsActions,
  dialogActions,
  usersActions,
  videoSessionsActions,
} from './actions';
import {
  BookedTimeSlotWebSocket,
  isBookedTimeSlotWebSocket,
  OverbookedTimeSlotWebSocket,
} from './actions/complexForm';
import { DialogType } from './actions/dialog';
import { SET_LOADING_GENERATION_ERROR } from './actions/exportStatisticsDocument';
import { login } from './auth';
import config from './config';
import { ERROR } from './constants';
import Routes from './constants/routes';
import rootReducer, {
  initialState,
  resourcesGetListWithoutConcurrency,
} from './reducer';
import { RootState } from './reducer/rootState';
import rest from './rest';
import { pingPatient, watchInJWTExpired, wsProSaga } from './saga';
import sentry from './sentry';
import { chatMessagesCustomAction, getUserDisplayName } from './utils';

import { Query } from '@/src/reducer/reducersTypes';

const handleTimeSlotOverbooking = ({
  payload,
  userName,
  currentPractitionerId,
  selectedAvailabilities,
  lastAvailabilitiesQuery,
}: {
  payload: OverbookedTimeSlotWebSocket | BookedTimeSlotWebSocket;
  userName: string;
  currentPractitionerId?: string;
  selectedAvailabilities: string[];
  lastAvailabilitiesQuery?: Query;
}) => {
  if (
    currentPractitionerId &&
    currentPractitionerId === payload.practitionerId
  ) {
    if (isBookedTimeSlotWebSocket(payload)) {
      if (lastAvailabilitiesQuery) {
        return availabilitiesActions.getList(lastAvailabilitiesQuery);
      }
      return;
    }

    const usesAvailability = selectedAvailabilities.includes(
      payload.availabilityId,
    );

    if (payload.action === 'ANNOUNCE' && usesAvailability) {
      const forbidMessage: OverbookedTimeSlotWebSocket = {
        availabilityId: payload.availabilityId,
        centerId: payload.centerId,
        practitionerId: payload.practitionerId,
        action: 'FORBID',
        userName,
      };

      return websocketActions.message(
        `timeSlotOverbooking_${payload.centerId}`,
        {
          resource: 'timeSlotOverbooking',
          payload: forbidMessage,
        },
      );
    }
    if (payload.action === 'FORBID' && usesAvailability) {
      return dialogActions.setDialogType({
        dialogType: DialogType.TIMESLOT_HANDLING,
        dialogProps: payload,
      });
    }
  }
};
export interface SagaStore extends Store {
  sagaTask: Task;
}

const { captureException } = sentry(
  config.get('SENTRY_DSN'),
  config.get('SENTRY_RELEASE'),
);

export const crudCallbackErrorSentry = async (action, error, state) => {
  // const permissions = await getPermissions();
  captureException(error, null, {
    action: anonymizeObject(action),
    currentAgenda: {
      center: state?.centers?.item,
      practitioner: state?.practitioners?.item,
      agendaSettings: state?.agendaSettings?.item,
    },
    error: { ...error, message: error.message },
    // permissions,
    user: state?.users?.item,
    authentication: state?.authentication?.item,
  });
};

// from https://github.com/topheman/react-es6-redux/blob/master/src/redux/middleware/logger.js
function loggerMiddleware() {
  return next => action => {
    // eslint-disable-next-line no-console
    console.group(action.type);
    // eslint-disable-next-line no-console
    console.log(action);
    const result = next(action);
    // eslint-disable-next-line no-console
    console.groupEnd();

    return result;
  };
}

const bindMiddleware = middleware => {
  if (process.env.NODE_ENV !== 'production') {
    // eslint-disable-next-line global-require
    const { composeWithDevTools } = require('redux-devtools-extension');
    const composeEnhancers = composeWithDevTools({
      trace: true,
    });
    return composeEnhancers(applyMiddleware(...middleware));
  }
  return applyMiddleware(...middleware);
};

const extendAction = (action, store) => ({
  ...action,
  userId: store.users?.item?.id,
});

/**
 * This variable contains the instance of the store so that `getToken` and `getTimezone` can access it via the closure.
 * DO NOT EXPOSE IT
 * It is only assigned when this code runs in the browser.
 * When this code runs in the server, it will remain `null` to ensure the store will never be shared in any way.
 */
let browserOnlyAvailableStoreInstanceViaClosure:
  | (Store<any, AnyAction> & { sagaTask: Task })
  | null = null;

function makeStore() {
  const sagaMiddleware = createSagaMiddleware();
  const websocketMiddleware = createSagaMiddleware();
  const middlewares: Middleware[] = [sagaMiddleware, websocketMiddleware];
  if (process.env.NEXT_PUBLIC_LOG_REDUX === 'true') {
    middlewares.push(loggerMiddleware);
  }
  const store = createStore(
    rootReducer,
    initialState,
    bindMiddleware(middlewares),
  );

  // @ts-ignore
  if (typeof window !== 'undefined' && window.Cypress) {
    // @ts-ignore
    window.store = store;
  }

  const saga = function* rootSaga() {
    yield all(
      [
        crudSaga(rest, {
          disabledConcurrencyResourcesGetList: resourcesGetListWithoutConcurrency,
          disabledConcurrencyResourcesGetOne: resourcesGetListWithoutConcurrency,
          transformerFn: transformerByTimezone(defaultPropertiesToChange),
          extendAction,
          // 25/02/2020
          // Sentry limit exceeded
          // TODO: active again, with a filter, ex: don't send a 401 if the user is not authenticate
          // callbackError: crudCallbackErrorSentry,
        }),
        authSaga(login, rest),
        watchInJWTExpired,
        pingPatient,
        wsProSaga,
      ].map(fork),
    );
  };
  const wsUri = config.get('WEBSOCKET_URI');
  const appointmentMapping = {
    resource: 'appointments',
    params: {
      aggregateWith: 'practitioner,patient,consultationReason',
    },
    options: {
      ...[CREATED, UPDATED].reduce(
        (result, key) => ({ ...result, [key]: { isWithFilter: true } }),
        {},
      ),
    },
  };

  const mappings = {
    agendaSettings: {
      resource: 'agendaSettingsDefault',
      chunkUrlResource: 'agendaSettings',
      params: {
        aggregateWith: 'teleconsultationUser',
      },
    },
    appointment: appointmentMapping,
    appointmentAggregate: appointmentMapping,
    chatUsersTyping: {
      resource: 'chatUsersTyping',
    },
    chatUsersConnected: {
      resource: 'chatUsersConnected',
    },
    searchInvitation: {
      resource: 'searchInvitation',
    },

    chatMessage: {
      resource: 'chatMessages',
      chunkUrlResource: 'chat-messages',
      createCustomAction: (messageParameter, actionToPut) =>
        chatMessagesCustomAction(store, messageParameter.payload, actionToPut),
    },
    chatReplyMessage: {
      resource: 'chatReplyMessages',
      chunkUrlResource: 'chat-messages',
    },
    chatRoom: {
      resource: 'chatRooms',
      chunkUrlResource: 'chat-rooms',
    },
    chatThread: {
      resource: 'chatThreads',
      chunkUrlResource: 'chat-messages/threads',
      getId: ({ parent }) => parent.id,
    },
    videoSession: {
      resource: 'videoSessions',
      chunkUrlResource: 'video-sessions',
      params: {
        aggregateWith:
          'patient,practitioner,center,consultationReason,teleconsultationRelay',
      },
    },
    teleconsultationReminder: {
      resource: 'teleconsultationReminders',
      chunkUrlResource: 'teleconsultation-reminders',
    },
    document: {
      resource: 'documents',
      chunkUrlResource: 'documents',
      params: {
        aggregateWith: 'patient,practitioner,center',
      },
    },
    exportStatisticsDocument: {
      resource: 'exportStatisticsDocument',
      chunkUrlResource: 'exportStatisticsDocument',
      createCustomAction: messageParameter => {
        if (messageParameter.action === ERROR) {
          return {
            type: SET_LOADING_GENERATION_ERROR,
            resource: 'exportStatisticsDocument',
          };
        }

        return {
          type: SET_ITEM,
          payload: { item: messageParameter?.payload },
          resource: 'exportStatisticsDocument',
        };
      },
    },
    user: {
      resource: 'users',
      chunkUrlResource: 'users',
      createCustomAction: messageParameter => ({
        type: SET_ITEM,
        payload: { item: messageParameter?.payload },
        resource: 'clearAccessRequest',
      }),
    },
    onfidoVerification: {
      createCustomAction: ({ payload }: { payload: OnfidoVerification }) =>
        usersActions.setOnfidoVerification(payload),
    },
    // UPDATE centerExternalSync in centers?.item object during auto onboarding LGC synchronization
    centerExternalSync: {
      resource: 'autoOnBoarding',
      chunkUrlResource: 'auto-on-boarding',
      createCustomAction: messageParameter => {
        const existingCenter = { ...store.getState().centers?.item };
        const existingCenterExternalSyncs =
          existingCenter?.centerExternalSyncList;
        const payload = messageParameter?.payload;
        const incomingCenterExternalSyncId = payload?.id;
        if (existingCenterExternalSyncs && incomingCenterExternalSyncId) {
          existingCenterExternalSyncs.forEach((sync, idx) => {
            if (sync.id === incomingCenterExternalSyncId) {
              existingCenter.centerExternalSyncList[idx] = payload;
            }
          });
        }
        return {
          type: SET_ITEM,
          payload: { item: existingCenter },
          resource: 'centers',
        };
      },
    },
    // GET_LIST user when substitute is activate/deactivate (to update chat UserListContainer)
    chatUserList: {
      resource: 'centers',
      chunkUrlResource: 'centers',
      createCustomAction: messageParameter => {
        if (window.location.pathname === Routes.CHAT)
          return {
            type: GET_LIST,
            payload: {
              centerId: messageParameter?.payload?.centerId,
              isChat: true,
            },
            resource: 'users',
            chunkUrlResource: 'users',
            updateStore: true,
            isReplace: true,
          };
      },
    },
    notificationCenter: {
      resource: 'notificationCenters',
      chunkUrlResource: 'notification-centers',
    },
    arrayList: {
      resource: 'unreadMessages',
      chunkUrlResource: 'chat-messages/unread-messages',
      createCustomAction: () => ({
        type: GET_LIST,
        resource: 'unreadMessages',
        chunkUrlResource: 'chat-messages/unread-messages',
        updateStore: true,
        isReplace: true,
      }),
    },
    timeSlotOverbooking: {
      resource: 'complexForm',
      createCustomAction: message => {
        const currentState: RootState = store.getState();
        const lastAvailabilitiesQuery = currentState.availabilities.queries[0];
        const selectedAvailabilities = currentState.pendingAppointments.items
          .map(item => item.availabilityId)
          .filter(Boolean) as string[];

        return handleTimeSlotOverbooking({
          payload: message.payload,
          userName: currentState.users.item
            ? getUserDisplayName(
                currentState.users.item,
                currentState.authentication.item,
              )
            : '',
          currentPractitionerId:
            currentState.complexForm.selectedPractitioner ||
            currentState.practitioners.item?.id,
          selectedAvailabilities,
          lastAvailabilitiesQuery,
        });
      },
    },
    selfOnboardingInvitation: {
      createCustomAction: () =>
        chatInvitationsActions.hasReceivedNewInvitation(true),
    },
    ping: {
      createCustomAction: (message: {
        subscriberId: string;
        videoSessionId: string;
        emitter: 'web' | 'mobile';
      }) => {
        return videoSessionsActions.updateSubscribersPing({
          videoSessionId: message.videoSessionId,
          data: {
            emitter: message.emitter ?? 'web',
            subscriberId: message.subscriberId,
            videoSessionId: message.videoSessionId,
            dateIsoString: new Date().toISOString(),
          },
        });
      },
    },
  };
  // @ts-ignore
  if (process.browser) {
    websocketMiddleware.run(
      wsSaga(wsUri, mappings, {
        extendAction,
      }),
    );
    if (process.env.NODE_ENV !== 'production') {
      // @ts-ignore
      window.__store__ = store;
    }
  }

  const storeInstance = {
    ...store,
    sagaTask: sagaMiddleware.run(saga),
  };

  if (typeof window !== 'undefined') {
    browserOnlyAvailableStoreInstanceViaClosure = storeInstance;
  }

  return storeInstance;
}

export const wrapper = createWrapper(makeStore, { debug: false });

// only used browser-side - token doesn't leak between server requests
export const getToken = (url?: string) => {
  return (
    typeof window !== 'undefined' &&
    typeof url === 'string' &&
    !url.startsWith('pro-public') && // don't pass token when targeting /pro-public
    browserOnlyAvailableStoreInstanceViaClosure?.getState()?.authentication
      ?.item?.token
  );
};

// only used browser-side - timezone doesn't leak between server requests
export const getTimezone = () =>
  typeof window !== 'undefined' &&
  browserOnlyAvailableStoreInstanceViaClosure?.getState()?.centers?.item
    ?.timeZone;
