import { createSelector } from 'reselect';

import { generateInitialSelectors } from '@docavenue/core';
import { ProAgendaSettingsDTO } from '@maiia/model/generated/model/api-pro/api-pro';

import { ChatState } from '@/src/reducer';
// eslint-disable-next-line import/no-cycle
import {
  GenericSelector,
  InitialGenericSelector,
} from '@/src/selector/selectorsType';

const initialSelectors: InitialGenericSelector<ProAgendaSettingsDTO> = generateInitialSelectors(
  'agendaSettings',
);

export const getAgendaSettingSelectorByPractitionerId = createSelector(
  [
    initialSelectors.getItems,
    (_: ChatState, practitionerId: string) => practitionerId,
  ],
  (agendaSettings, practitionerId) =>
    agendaSettings.find(agenda => agenda.practitionerId === practitionerId),
);

const selectors: GenericSelector<ProAgendaSettingsDTO> = {
  name: 'agendaSettings',
  ...initialSelectors,
};

export default selectors;
