/* eslint-disable import/no-cycle */
import React from 'react';
import { renderToString } from 'react-dom/server';
import Router from 'next/router';
import find from 'lodash/find';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import isObject from 'lodash/isObject';
import last from 'lodash/last';
import transform from 'lodash/transform';
import moment, { Moment } from 'moment';
import phe from 'print-html-element';

import { serialActions } from '@docavenue/core';
import {
  Address,
  AppointmentGetDTO,
  Document,
  PractitionerDestination,
  ProAgendaSettingsDTO,
  TeleconsultationReminder,
  Week,
  WeekTemplateCycle,
} from '@maiia/model/generated/model/api-pro/api-pro';

import {
  appointmentsTlcActions,
  teleconsultationRemindersActions,
  videoSessionsActions,
} from '../../src/actions';
import {
  AGENDA_APOINTMENT_ALLOWED_LENGTH,
  DEFAULT_AGENDA_APPOINTMENT_LENGTH,
  PATIENT,
  STARTED,
} from '../../src/constants';
import Routes from '../../src/constants/routes';
import { capitalize } from '../../src/utils';
import { VideoSessionAggregate } from '../../types/pro.types';

export const generateRandomPassword = () => {
  const chars =
    'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!';
  const charLength = chars.length;
  let result = '';
  for (let i = 0; i < 20; i++) {
    result += chars.charAt(Math.floor(Math.random() * charLength));
  }
  return result;
};

export const printHtml = (
  html: any,
  size: 'auto' | 'landscape' | 'portrait' = 'landscape',
  isHTMLElement = false,
) => {
  const options = {
    templateString: `<html><head><style>body{background-color:white !important;}@page { margin: 0.5cm; size: ${size} !important; bleed: 0; }</style></head>{{printBody}}</html>`,
    // templateString: `<html><head><style>body{background-color:skyblue !important;}@page { margin: 1cm 1cm 1cm 1cm; size: landscape !important; }</style></head>{{printBody}}</html>`,
  };
  if (isHTMLElement) {
    phe.printElement(html, options);
    return;
  }
  const toPrint = typeof html === 'string' ? html : renderToString(html);
  phe.printHtml(toPrint, options);
};

export const addressToString = (address?: Address) => {
  if (!address) return '';
  const { number, street, zipCode, city, fullAddress } = address;
  if (fullAddress) return fullAddress;
  return [number, street, zipCode, city].filter(Boolean).join(' ');
};

export const getRandomColor = () => {
  const letters = '0123456789ABCDEF';
  let color = '#';
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
};

export const findWeekTemplateCycleByWeek = (
  date: string | Date | Moment | undefined,
  weekTemplateCycles: WeekTemplateCycle[],
  weeks: (Week | undefined)[],
) => {
  const weekDate = moment(date)
    .startOf('isoWeek')
    .format('YYYY-MM-DD');

  const targetWeek = find(
    weeks,
    week =>
      moment(week?.startDate)
        .startOf('isoWeek')
        .format('YYYY-MM-DD') === weekDate,
  );
  if (!targetWeek) return null;

  const targetWeekTemplateCycle = find(weekTemplateCycles, {
    id: targetWeek.weekTemplateCycleId,
  });
  return targetWeekTemplateCycle;
};

// @READ https://regex101.com/r/NwfIgj/1
const ssnRegex = /^[1-478][0-9]{2}(0[1-9]|1[0-2]|62|63)(2[ABab]|[0-9]{2})(00[1-9]|0[1-9][0-9]|[1-8][0-9]{2}|9[0-8][0-9]|990)(00[1-9]|0[1-9][0-9]|[1-9][0-9]{2})(0[1-9]|[1-8][0-9]|9[0-7])$/;
export const isValidSocialSecurityNumber = (assuranceCode: string) => {
  if (ssnRegex.test(assuranceCode)) {
    const ssn = Number(
      assuranceCode
        .replace('2A', '19')
        .replace('2B', '18')
        .slice(0, assuranceCode.length - 2),
    );
    return 97 - (ssn % 97) === Number(assuranceCode.slice(-2));
  }
  return false;
};

export const PHONE_CODE_FRANCE = '+33';
export const PHONE_CODE_BELGIUM = '+32';
export const PHONE_CODE_SWITZERLAND = '+41';
export const PHONE_CODE_LUXEMBOURG = '+352';
export const PHONE_CODE_EGYPT = '+20';
export const PHONE_CODE_GUADELOUPE = '+590';
export const PHONE_CODE_GUYANE = '+594';
export const PHONE_CODE_MARTINIQUE = '+596';
export const PHONE_CODE_MAYOTTE = '+262';
export const PHONE_CODE_NOUVELLE_CALEDONIE = '+687';
export const PHONE_CODE_POLYNESIE_FRANCAISE = '+689';
export const PHONE_CODE_REUNION = '+262';
export const PHONE_CODE_ST_PIERRE_MIQUELON = '+508';
export const PHONE_CODE_WALLIS_FUTUNA = '+681';
export const PHONE_CODE_ESPAGNE = '+34';
export const PHONE_CODE_ALLEMAGNE = '+49';
export const PHONE_CODE_UK = '+44';
export const PHONE_CODE_ITALIE = '+39';
export const PHONE_CODE_IRLANDE = '+353';
export const PHONE_CODE_PAYS_BAS = '+31';
export const PHONE_CODE_MAROC = '+212';
export const PHONE_CODE_ALGERIE = '+213';
export const PHONE_CODE_TUNISIE = '+216';

const ALLOW_DIAL_CODES = [
  PHONE_CODE_FRANCE,
  PHONE_CODE_BELGIUM,
  PHONE_CODE_SWITZERLAND,
  PHONE_CODE_LUXEMBOURG,
  PHONE_CODE_EGYPT,
  PHONE_CODE_GUYANE,
  PHONE_CODE_POLYNESIE_FRANCAISE,
  PHONE_CODE_GUADELOUPE,
  PHONE_CODE_LUXEMBOURG,
  PHONE_CODE_MARTINIQUE,
  PHONE_CODE_REUNION,
  PHONE_CODE_NOUVELLE_CALEDONIE,
  PHONE_CODE_ST_PIERRE_MIQUELON,
  PHONE_CODE_WALLIS_FUTUNA,
  PHONE_CODE_ESPAGNE,
  PHONE_CODE_ALLEMAGNE,
  PHONE_CODE_UK,
  PHONE_CODE_ITALIE,
  PHONE_CODE_IRLANDE,
  PHONE_CODE_PAYS_BAS,
  PHONE_CODE_MAROC,
  PHONE_CODE_ALGERIE,
  PHONE_CODE_TUNISIE,
];

const isAllowPhoneDialCode = (value: string) =>
  ALLOW_DIAL_CODES.some(code => value.startsWith(code));

export const isValidNumberType = (
  value: string,
  numberType: string,
  intlTelInputUtils: any,
) => {
  const numberTypeOfValue = intlTelInputUtils.getNumberType(value);
  if (numberType === 'MOBILE') {
    return numberTypeOfValue === intlTelInputUtils.numberType.MOBILE;
  }
  if (value.startsWith(PHONE_CODE_FRANCE)) {
    return (
      numberTypeOfValue === intlTelInputUtils.numberType.FIXED_LINE ||
      numberTypeOfValue === intlTelInputUtils.numberType.VOIP
    );
  }
  return numberTypeOfValue === intlTelInputUtils.numberType.FIXED_LINE;
};

export const isValidPhoneNumber = (value: string, numberType: string) => {
  if (!value) return false;
  if (typeof window === 'undefined') {
    console.error('Not supported SSR yet!');
    return false;
  }
  const { intlTelInputUtils } = window as any;
  if (!intlTelInputUtils) {
    console.error('intlTelInputUtils not ready yet!');
    return false;
  }
  return (
    isAllowPhoneDialCode(value) &&
    intlTelInputUtils.isValidNumber(value) &&
    isValidNumberType(value, numberType, intlTelInputUtils)
  );
};

export const splitPhoneNumber = (phoneNumber: string) => {
  const splittedPhoneNumber = phoneNumber.match(/.{1,2}/g);
  return (splittedPhoneNumber || []).join(' ');
};

export const replaceFranceCallingCode = (value: string) =>
  splitPhoneNumber(value?.replace(/\s*/g, '').replace(/\+33\s*/g, '0'));

export const formatAutoCompleteInput = (value: string) => {
  const phoneNumberReg = /^(\+33)?[\d\s]*$/g;
  if (phoneNumberReg.test(value)) {
    return replaceFranceCallingCode(value);
  }

  return value;
};

export const boldString = (str: string, target: string) => {
  if (!target) return str;
  const re = new RegExp(target, 'g');
  return (
    <div
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{
        __html: str.replace(re, `<b>${target}</b>`),
      }}
    />
  );
};

/**
 * Deep diff between two object, using lodash
 * use this in react memo to debug why props are changing !!
 * e.g React.memo(Component, function areEqual(prevProps, nextProps) {
 *   console.log('differences', deepDifference(prevProps, nextProps));
 *   return false; // force rerendering
 * }
 * @param  {Object} object Object compared
 * @param  {Object} base   Object to compare with
 * @return {Object}        Return a new object who represent the diff
 */
export const deepDifference = (object: Object, base: Object) => {
  function changes(obj, b) {
    return transform(obj, function tr(result: any, value, key) {
      if (!isEqual(value, b[key])) {
        // eslint-disable-next-line no-param-reassign
        result[key] =
          isObject(value) && isObject(b[key]) ? changes(value, b[key]) : value;
      }
    });
  }
  return changes(object, base);
};

export const shallowCompare = (obj1: Object, obj2: Object) =>
  Object.keys(obj1).length === Object.keys(obj2).length &&
  Object.keys(obj1).every(
    // eslint-disable-next-line no-prototype-builtins
    key => obj2.hasOwnProperty(key) && obj1[key] === obj2[key],
  );

export const areEqualDeep = (
  prevObj: Object,
  nextObj: Object,
  pathsToCheck: Array<string>,
) =>
  pathsToCheck.reduce(
    (acc, curr) =>
      acc === false ? false : isEqual(get(prevObj, curr), get(nextObj, curr)),
    true,
  );

export const startTlc = (
  dispatch: Function,
  videoSession: VideoSessionAggregate,
  isTlcCenterOrAmcAndHasPractitionerId: boolean,
  practitionerId: string | null,
  tlcReminder?: TeleconsultationReminder,
  // isReadyVisio: boolean = false,
) => {
  // TODO: USE NEXTACTION FOR GETONE // maybe not need after the update
  dispatch(
    serialActions.serial([
      () =>
        tlcReminder
          ? teleconsultationRemindersActions.updateOne({
              ...tlcReminder,
              isPopup: false,
            })
          : serialActions.continue(),
      () =>
        videoSessionsActions.updateOne({
          ...videoSession,
          ...(isTlcCenterOrAmcAndHasPractitionerId && { practitionerId }),
          videoSessionStatus: STARTED,
          practitionerHasHungUp: false,
          // videoSessionStatus: isReadyVisio ? STARTED : WAITING,
        }),
      () =>
        Router.push({
          pathname: Routes.TLC,
          query: {
            ...Router.query,
            centerId: videoSession.centerId,
            patientId: videoSession.patient?.id,
            practitionerId,
            videoSessionId: videoSession.id,
          },
        }),
      () =>
        videoSessionsActions.getOne(videoSession.id, {
          params: {
            aggregateWith: 'patient,center,practitioner,teleconsultationRelay',
          },
        }),
      state =>
        state.videoSessions.item?.appointmentId &&
        appointmentsTlcActions.getOne(state.videoSessions.item?.appointmentId),
    ]),
  );
};

export const getTlcCenterOrAmcAndHasCenterId = (state, centerId) => {
  const isTlcCenter = !!state.centers.item?.proFunctionalities?.TLC_CENTER;
  const isTlcAmc = !!state.centers.item?.proFunctionalities?.TLC_AMC;
  const isTlcCenterOrAmc = isTlcCenter || isTlcAmc;
  const hasCenterId = !!centerId;
  return isTlcCenterOrAmc && hasCenterId;
};

export const preventAndStop = (
  e: React.MouseEvent<HTMLDivElement, MouseEvent>,
) => {
  e.preventDefault();
  e.stopPropagation();
};

// export const getAutoCompletePatientLabel = item => {
//   if (!item) return null;
//   const {
//     firstName = '',
//     lastName = '',
//     birthName = '',
//     birthDate = '',
//     mobilePhoneNumber = '',
//     phoneNumber = '',
//   } = item;
//   const name = `${firstName} ${lastName}`;
//   if (!birthName && !birthDate && !mobilePhoneNumber && !phoneNumber)
//     return name;

//   const birthInfo = `(${birthName ? `${birthName} ` : ''}${
//     birthDate ? moment(birthDate).format('DD/MM/YYYY') : ''
//   }) ${mobilePhoneNumber || phoneNumber}`;

//   return `${name} ${birthInfo}`.trim();
// };

// export const getPractitionerSpecialityAndCenter = item =>
//   `${get(item, 'speciality.name', 'Aucune spécialité')} - ${get(
//     item,
//     'center.name',
//     'Aucun centre',
//   )}`;

export const getPractitionerSpecialityAndCenterByAgendaSettings = (
  item: ProAgendaSettingsDTO,
) =>
  `${get(item, 'practitioner.speciality.name', 'Aucune spécialité')} - ${get(
    item,
    'center.name',
    'Aucun centre',
  )}`;

/**
 * get the closest slot start time from a given date based on settings slot duration.
 * Eg: an user want to set appointments by slices of 15 minutes, and clicks on 15:20
 * The closest slot start date is 15:15.
 */
export const getClosestSlotStartFromDateUsingDate = (
  date: Date | string,
  timeSlice: number = DEFAULT_AGENDA_APPOINTMENT_LENGTH,
) => {
  // Temporary solution to clean date of type string
  let newDate =
    typeof date === 'string' ? new Date(date.replace('hours-', '')) : date;
  const initialMinutes = newDate.getMinutes() + 1;
  const slotPerHour = 60 / timeSlice;
  if (AGENDA_APOINTMENT_ALLOWED_LENGTH.indexOf(timeSlice) === -1)
    throw new Error(
      `Unsupported setting: timeSlice should be 5, 10, 15, 20, 30, 40 or 60 but it is '${timeSlice}'`,
    );

  let i = 0;
  newDate.setMinutes(0);
  while (i < slotPerHour && newDate.getMinutes() + timeSlice < initialMinutes) {
    newDate = new Date(newDate.getTime() + timeSlice * 60000);
    i++;
  }

  return newDate;
};

/**
 * get the closest slot start time from a given date based on settings slot duration.
 * Eg: an user want to set appointments by slices of 15 minutes, and clicks on 15:20
 * The closest slot start date is 15:15.
 */
export const getClosestSlotStartFromDate = (
  date: Date | Moment,
  timeSlice: number = DEFAULT_AGENDA_APPOINTMENT_LENGTH,
) => {
  const initialMinutes = moment(date).minutes() + 1;
  const slotPerHour = 60 / timeSlice;
  if (AGENDA_APOINTMENT_ALLOWED_LENGTH.indexOf(timeSlice) === -1)
    throw new Error(
      `Unsupported setting: timeSlice should be 5, 10, 15, 20, 30, 40 or 60 but it is '${timeSlice}'`,
    );

  let i = 0;
  const newDate = moment(date);
  newDate.minutes(0);
  while (i < slotPerHour && newDate.minutes() + timeSlice < initialMinutes) {
    newDate.add(timeSlice, 'minutes');
    i++;
  }

  return newDate;
};

export const getClosestSlotEndFromDate = (
  date: Date | Moment,
  timeSlice: number = DEFAULT_AGENDA_APPOINTMENT_LENGTH,
) =>
  getClosestSlotStartFromDate(
    moment(date).add(timeSlice - 1, 'minutes'),
    timeSlice,
  );

export const padNumber = (number: number) => {
  if (number < 10) {
    return `0${number}`;
  }
  return number;
};

/**
 * When getting timeslots from the backend it returns strings such as: 2019-12-13T08:00:00Z
 * However javascript's toISOString for the same dates would return :  2019-12-13T08:00:00.000Z
 * As we're doing string comparison for performance optimizations in TimeSlotWrapper,
 * we need to strings to be the exact same shape.
 * @param date
 * @returns {string}
 */
// export const toISOStringJavaPolyfill = date =>
//   `${date.getUTCFullYear()}-${padNumber(date.getUTCMonth() + 1)}-${padNumber(
//     date.getUTCDate(),
//   )}T${padNumber(date.getUTCHours())}:${padNumber(
//     date.getUTCMinutes(),
//   )}:${padNumber(date.getUTCSeconds())}Z`;

// https://stackoverflow.com/questions/105034/create-guid-uuid-in-javascript
export const uuidv4 = () =>
  'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function toStr(c) {
    // eslint-disable-next-line no-bitwise
    const r = (Math.random() * 16) | 0;
    // eslint-disable-next-line no-bitwise
    const v = c === 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });

export const isSentByPatient = (document: Document) =>
  (document?.sharedInformation?.sourceUserType as any)?.name === PATIENT;

export const isDocumentSharedWithLGC = (document: Document) =>
  isSentByPatient(document) &&
  document?.isToSendToSoftware &&
  document?.syncData?.some(syncData => !!syncData.externalId);

export const isLoadingSharedWithLGC = (document: Document) =>
  isSentByPatient(document) &&
  document?.isToSendToSoftware &&
  (!document?.syncData ||
    document?.syncData?.every(syncData => !syncData.externalId));

export const isPractitionerInAgendaSettings = (
  practitionerId: string | undefined,
  agendaSettings: Array<ProAgendaSettingsDTO>,
) => {
  if (!practitionerId) return false;
  return (
    agendaSettings.findIndex(
      agenda => agenda.practitionerId === practitionerId,
    ) !== -1
  );
};

export const isDocumentSharedWithAgendaSettings = (
  document: Document,
  agendaSettings: Array<ProAgendaSettingsDTO>,
) => {
  if (isPractitionerInAgendaSettings(document.practitionerId, agendaSettings))
    return true;
  const destinationSet =
    document?.sharedInformation?.practitionerDestinationSet ?? [];
  return (
    destinationSet.filter((dest: PractitionerDestination) =>
      isPractitionerInAgendaSettings(dest.practitionerId, agendaSettings),
    ).length > 0
  );
};

export const setDocumentsSeenByPrat = (documents: Document[]) => (params: {
  practitionerId: string | null;
  patientId: string | null;
}) =>
  documents
    .filter(doc => doc.patientId === params.patientId)
    .map(doc =>
      doc.practitionerId === params.practitionerId
        ? { ...doc, isSeen: true }
        : doc,
    );

export const setDocumentUrlFilter = (url: string) => {
  let urlFilter;
  const format = /[`[\]]/;
  const testString = format.test(url);
  if (testString) {
    urlFilter = url.replace(/[[\]']+/g, '');
    return urlFilter;
  }
  return url;
};

export const isAppointmentPastToday = (appointment: AppointmentGetDTO) =>
  moment(moment().startOf('day')).isAfter(
    moment(appointment.startDate).startOf('day'),
  );

export const hasAppointmentTimePassed = (appointment: AppointmentGetDTO) =>
  moment().isAfter(appointment.endDate);

export const patientInputToObject = (inputValue: string) => {
  // Extract alphabetic words (internal hyphens are allowed).
  const inputValueSplitted = (inputValue ?? '')
    .trim()
    .replace(/\s\s+/g, ' ')
    .split(' ')
    .filter(value => /^\p{Letter}(?:-?\p{Letter})*$/u.test(value));
  const firstName =
    inputValueSplitted.length > 1
      ? capitalize(inputValueSplitted.pop() as string)
      : undefined;
  const lastName = inputValueSplitted.join(' ').toUpperCase() || undefined;
  return { firstName, lastName };
};

export const substituteInputToObject = (inputValue: string) => {
  const inputValueSplitted = (inputValue ?? '')
    .trim()
    .replace(/\s\s+/g, ' ')
    .split(' ');
  const lastName =
    inputValueSplitted.length > 0
      ? (inputValueSplitted.splice(0, 1)[0] || '').toUpperCase()
      : '';
  const firstName =
    inputValueSplitted.length > 0 ? inputValueSplitted.join(' ') : '';
  return { userProInformation: { firstName, lastName } };
};

// Will be deleted when the new phone component will used in all project
export const defaultAcceptedPhoneCodes = [
  PHONE_CODE_FRANCE.substring(1),
  PHONE_CODE_BELGIUM.substring(1),
  PHONE_CODE_SWITZERLAND.substring(1),
  PHONE_CODE_LUXEMBOURG.substring(1),
  PHONE_CODE_EGYPT.substring(1),
  PHONE_CODE_GUADELOUPE.substring(1),
  PHONE_CODE_GUYANE.substring(1),
  PHONE_CODE_MARTINIQUE.substring(1),
  PHONE_CODE_MAYOTTE.substring(1),
  PHONE_CODE_NOUVELLE_CALEDONIE.substring(1),
  PHONE_CODE_POLYNESIE_FRANCAISE.substring(1),
  PHONE_CODE_REUNION.substring(1),
  PHONE_CODE_ST_PIERRE_MIQUELON.substring(1),
  PHONE_CODE_WALLIS_FUTUNA.substring(1),
];

export const getPhoneRegExp = (
  acceptedPhoneCodes = defaultAcceptedPhoneCodes,
  isMobile = true,
) => {
  //  use '1-9' for first number accepted is a temporary solution to take into account all countries : FRANCE,BELGIUM,SWITZERLAND
  //  SWITZERLAND and EGYPT
  const firstNumberMobileAccepted = '1-9';
  const firstNumberFixAccepted = '1-9';
  const firstNumberAccepted = isMobile
    ? firstNumberMobileAccepted
    : firstNumberFixAccepted;

  return new RegExp(
    `^(?:((?:\\+|00)(${acceptedPhoneCodes.join(
      '|',
    )}))|0)\\s*[${firstNumberAccepted}]((?:[\\s.-]*\\d{1}){5}|(?:[\\s.-]*\\d{2}){4}|(?:[\\s.-]*\\d{3}){3})$`,
  );
};

// eslint-disable-next-line no-useless-escape
export const emailRegExp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const emailMssanteRegExp = /(\.mssante\.)/;
export const noAccentsRegExp = /([à-ü]|[À-Ü])/g;

const msSanteDomains = ['mssante'];
export const excludeMsSanteDomain = (value: string = '') => {
  const regex = new RegExp(emailRegExp);
  const domain = last(regex.exec(value || ''));
  return !value || (!!domain && !msSanteDomains.includes(domain.slice(0, -1)));
};

export const copytoClipboard = (valueToBeCopied: string | number) => {
  navigator.clipboard.writeText(valueToBeCopied as string);
};
