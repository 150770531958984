import { actionsGeneratorV2 } from '@docavenue/core';
import {
  AppointmentGetDTO,
  ConsultationReason,
  Patient,
} from '@maiia/model/generated/model/api-pro/api-pro';

export const SET_ITEM_COMPLEX = 'SET_ITEM_COMPLEX';
export const SET_MULTI = 'SET_MULTI';
export const SET_SELECTED_PRACTITIONER = 'SET_SELECTED_PRACTITIONER';
export const SET_PREVIOUS_PATH = 'SET_PREVIOUS_PATH';
export const COMPLEX_FORM_RESOURCE = 'complexForm';
export const SET_TIMESLOT_OVERBOOKED = 'SET_TIMESLOT_OVERBOOKED';
export const SET_WAITING_LIST = 'SET_WAITING_LIST';

type Path = {
  pathname: string;
  query?: Record<string, any>;
};

export type PreviousPathState = Path | null;

export type OverbookedTimeSlot = {
  practitionerId: string;
  centerId: string;
  availabilityId: string;
  userName: string;
};

export type OverbookedTimeSlotWebSocket = OverbookedTimeSlot & {
  action: 'ANNOUNCE' | 'FORBID';
};

export type BookedTimeSlot = {
  practitionerId: string;
  centerId: string;
};

export type BookedTimeSlotWebSocket = BookedTimeSlot & {
  action: 'BOOKED';
};

export function isBookedTimeSlotWebSocket(
  data: OverbookedTimeSlotWebSocket | BookedTimeSlotWebSocket,
): data is BookedTimeSlotWebSocket {
  return data.action === 'BOOKED';
}

// overriding Appointment because of the use of `complexFormActions.setItem` in the code
type Item =
  | (Partial<
      Omit<
        AppointmentGetDTO,
        | 'id'
        | 'startDate'
        | 'endDate'
        | 'consultationReason'
        | 'patient'
        | 'type'
      >
    > & {
      id?: string | null;
      startDate?: Date | string | null;
      endDate?: Date | string | null;
      consultationReason?: ConsultationReason | null;
      patient?: Partial<Patient> | null;
      type?: string;
    })
  | null;

const complexFormCrud = actionsGeneratorV2({
  resource: COMPLEX_FORM_RESOURCE,
  chunkUrlResource: 'appointments',
  disableConcurrency: {
    isGetListDisableConcurrency: true,
    isGetOneDisableConcurrency: true,
  },
});

const setItem = (item: Item) => ({
  resource: COMPLEX_FORM_RESOURCE,
  type: SET_ITEM_COMPLEX,
  payload: item,
});

const setSelectedPractitioner = (item: string | null) => ({
  resource: COMPLEX_FORM_RESOURCE,
  type: SET_SELECTED_PRACTITIONER,
  payload: item,
});

const setMulti = (isMulti: boolean) => ({
  resource: COMPLEX_FORM_RESOURCE,
  type: SET_MULTI,
  payload: { isMulti },
});

const setPreviousPath = (path: PreviousPathState) => ({
  resource: COMPLEX_FORM_RESOURCE,
  type: SET_PREVIOUS_PATH,
  payload: path,
});

const setWaitingList = (isWaitingList: boolean) => ({
  resource: COMPLEX_FORM_RESOURCE,
  type: SET_WAITING_LIST,
  payload: { isWaitingList },
});

const complexFormActions = {
  ...complexFormCrud,
  setItem,
  setSelectedPractitioner,
  setMulti,
  setPreviousPath,
  setWaitingList,
};

export default complexFormActions;
